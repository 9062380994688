import styled from 'styled-components';

const Frame5 = styled((props) => (
  <svg width={540} height={621} viewBox="0 0 540 621" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.12}
      d="M251.87 541.461L66.7999 435.151C56.1699 429.051 49.6102 417.741 49.5802 405.481L49.11 192.051C49.08 179.791 55.6003 168.461 66.2003 162.311L250.8 55.1912C261.4 49.0412 274.48 49.0112 285.1 55.1212L470.17 161.431C480.8 167.531 487.36 178.841 487.39 191.101L487.86 404.531C487.89 416.791 481.37 428.121 470.77 434.271L286.17 541.391C275.57 547.541 262.49 547.571 251.87 541.461Z"
      fill="#AD8EBC"
    />
    <path
      d="M285.57 587.449L510.67 456.829C521.27 450.679 527.78 439.339 527.76 427.089L527.19 166.839C527.16 154.579 520.6 143.279 509.97 137.169L284.3 7.53928C273.67 1.43928 260.6 1.45928 250 7.60928L24.8999 138.229C14.2999 144.379 7.7901 155.719 7.8101 167.969L8.37993 428.219C8.40993 440.479 14.9701 451.779 25.6001 457.889L251.27 587.519C261.9 593.619 274.97 593.599 285.57 587.449ZM251.17 540.709L66.1001 434.399C55.4701 428.299 48.9099 416.989 48.8799 404.729L48.4102 191.299C48.3802 179.039 54.9 167.709 65.5 161.559L250.1 54.4393C260.7 48.2893 273.78 48.2593 284.4 54.3693L469.47 160.679C480.1 166.779 486.66 178.089 486.69 190.349L487.16 403.779C487.19 416.039 480.67 427.369 470.07 433.519L285.47 540.639C274.87 546.789 261.79 546.819 251.17 540.709Z"
      fill="#986EAB"
    />
    <path
      d="M268.35 595.07C261.93 595.07 255.51 593.42 249.77 590.13L24.1002 460.5C12.5802 453.88 5.40998 441.52 5.37998 428.24L4.81015 167.99C4.78015 154.7 11.9002 142.31 23.3902 135.65L248.5 5.01993C259.99 -1.65007 274.28 -1.68007 285.8 4.93993L511.47 134.57C522.99 141.19 530.16 153.55 530.19 166.83L530.76 427.08C530.79 440.36 523.67 452.76 512.18 459.43L287.08 590.05C281.31 593.4 274.83 595.07 268.36 595.07H268.35ZM267.22 5.99993C261.78 5.99993 256.35 7.39993 251.51 10.2099L26.4103 140.83C16.7703 146.42 10.7899 156.82 10.8199 167.97L11.3902 428.22C11.4102 439.37 17.4302 449.74 27.1002 455.29L252.77 584.92C262.44 590.47 274.43 590.45 284.07 584.85L509.17 454.23C518.81 448.64 524.79 438.23 524.76 427.09L524.19 166.84C524.17 155.69 518.15 145.32 508.48 139.76L282.81 10.1299C278 7.36993 272.61 5.97993 267.22 5.97993V5.99993ZM268.25 548.25C261.83 548.25 255.41 546.6 249.67 543.31L64.6002 437C53.0802 430.38 45.91 418.02 45.88 404.74L45.42 191.31C45.39 178.03 52.5101 165.63 64.0001 158.96L248.6 51.8399C260.09 45.1699 274.38 45.1399 285.9 51.7599L470.97 158.07C482.49 164.69 489.66 177.05 489.69 190.33L490.15 403.76C490.18 417.04 483.06 429.44 471.57 436.11L286.97 543.23C281.2 546.58 274.72 548.25 268.25 548.25ZM252.66 538.11C262.33 543.66 274.32 543.64 283.96 538.04L468.56 430.92C478.2 425.33 484.18 414.92 484.15 403.78L483.69 190.35C483.67 179.2 477.65 168.83 467.98 163.28L282.91 56.9699C273.24 51.4199 261.25 51.4399 251.61 57.0399L67.0099 164.16C57.3699 169.75 51.39 180.16 51.42 191.3L51.88 404.73C51.9 415.88 57.9199 426.25 67.5899 431.81L252.66 538.12V538.11Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.35}
    >
      <path
        d="M513.43 142.241C439.09 105.681 364.75 69.1208 290.41 32.5708C279.78 27.3408 267.26 27.6608 256.9 33.4208C186.11 72.8008 115.31 112.191 44.52 151.571C34.08 157.381 27.2998 168.071 26.5098 179.991C20.7198 266.611 14.9399 353.231 9.1499 439.851L7.81982 167.991C8.53982 164.101 10.0401 158.491 13.3901 152.421C17.1701 145.581 21.7402 140.981 24.9102 138.251C99.9402 94.7108 174.98 51.1708 250.01 7.63078C252.7 6.09078 258.85 3.05078 267.23 3.05078C275.52 3.05078 281.61 6.02078 284.31 7.55078C360.69 52.4508 437.06 97.3508 513.44 142.251L513.43 142.241Z"
        fill="#986EAB"
      />
    </g>
    <path
      d="M489.202 455.606C492.713 453.579 493.916 449.09 491.889 445.579C489.862 442.068 485.373 440.866 481.862 442.893L342.969 523.083C339.458 525.11 338.255 529.598 340.282 533.109C342.309 536.62 346.798 537.823 350.309 535.796L489.202 455.606Z"
      fill="white"
    />
    <path
      d="M329.592 548.219C333.103 546.192 334.306 541.704 332.279 538.193C330.252 534.682 325.763 533.479 322.252 535.506L274.205 563.246C270.695 565.273 269.492 569.762 271.519 573.273C273.546 576.784 278.035 577.986 281.545 575.959L329.592 548.219Z"
      fill="white"
    />
    <path
      d="M185.98 395.79C185.98 413.67 171.48 428.17 153.6 428.17C135.72 428.17 121.22 413.67 121.22 395.79C121.22 377.91 135.72 363.41 153.6 363.41C171.48 363.41 185.98 377.91 185.98 395.79Z"
      fill="#FBFF54"
    />
    <path
      d="M153.6 430.67C134.37 430.67 118.72 415.02 118.72 395.79C118.72 376.56 134.37 360.91 153.6 360.91C172.83 360.91 188.48 376.56 188.48 395.79C188.48 415.02 172.83 430.67 153.6 430.67ZM153.6 365.92C137.12 365.92 123.72 379.32 123.72 395.8C123.72 412.28 137.12 425.68 153.6 425.68C170.08 425.68 183.48 412.28 183.48 395.8C183.48 379.32 170.08 365.92 153.6 365.92Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M153.6 363.422C152.39 363.422 151.21 363.492 150.03 363.622C166.23 365.402 178.84 379.122 178.84 395.802C178.84 412.482 166.23 426.202 150.03 427.982C151.2 428.112 152.39 428.182 153.6 428.182C171.48 428.182 185.98 413.682 185.98 395.802C185.98 377.922 171.48 363.422 153.6 363.422Z"
        fill="black"
      />
    </g>
    <path
      d="M117.76 402.013C117.76 419.893 103.26 434.393 85.3799 434.393C67.4999 434.393 53 419.893 53 402.013C53 384.133 67.4999 369.633 85.3799 369.633C103.26 369.633 117.76 384.133 117.76 402.013Z"
      fill="#FBFF54"
    />
    <path
      d="M85.3901 436.881C66.1601 436.881 50.5098 421.231 50.5098 402.001C50.5098 382.771 66.1601 367.121 85.3901 367.121C104.62 367.121 120.27 382.771 120.27 402.001C120.27 421.231 104.62 436.881 85.3901 436.881ZM85.3901 372.131C68.9201 372.131 55.5098 385.531 55.5098 402.011C55.5098 418.491 68.9101 431.891 85.3901 431.891C101.87 431.891 115.27 418.491 115.27 402.011C115.27 385.531 101.87 372.131 85.3901 372.131Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M85.3901 369.633C84.1801 369.633 82.9998 369.703 81.8198 369.833C98.0198 371.613 110.63 385.333 110.63 402.013C110.63 418.693 98.0198 432.413 81.8198 434.193C82.9898 434.323 84.1801 434.393 85.3901 434.393C103.27 434.393 117.77 419.893 117.77 402.013C117.77 384.133 103.27 369.633 85.3901 369.633Z"
        fill="black"
      />
    </g>
    <path
      d="M402.54 324.083C402.54 341.963 388.04 356.463 370.16 356.463C352.28 356.463 337.78 341.963 337.78 324.083C337.78 306.203 352.28 291.703 370.16 291.703C388.04 291.703 402.54 306.203 402.54 324.083Z"
      fill="#FBFF54"
    />
    <path
      d="M370.16 358.951C350.93 358.951 335.28 343.301 335.28 324.071C335.28 304.841 350.93 289.191 370.16 289.191C389.39 289.191 405.04 304.841 405.04 324.071C405.04 343.301 389.39 358.951 370.16 358.951ZM370.16 294.201C353.68 294.201 340.28 307.601 340.28 324.081C340.28 340.561 353.68 353.961 370.16 353.961C386.64 353.961 400.04 340.561 400.04 324.081C400.04 307.601 386.64 294.201 370.16 294.201Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M370.16 291.703C368.95 291.703 367.77 291.773 366.59 291.903C382.79 293.683 395.4 307.403 395.4 324.083C395.4 340.763 382.79 354.483 366.59 356.263C367.76 356.393 368.95 356.463 370.16 356.463C388.04 356.463 402.54 341.963 402.54 324.083C402.54 306.203 388.04 291.703 370.16 291.703Z"
        fill="black"
      />
    </g>
    <path
      d="M341.95 189.11C341.95 206.99 327.45 221.49 309.57 221.49C291.69 221.49 277.19 206.99 277.19 189.11C277.19 171.23 291.69 156.73 309.57 156.73C327.45 156.73 341.95 171.23 341.95 189.11Z"
      fill="#FBFF54"
    />
    <path
      d="M309.57 223.99C290.34 223.99 274.69 208.34 274.69 189.11C274.69 169.88 290.34 154.23 309.57 154.23C328.8 154.23 344.45 169.88 344.45 189.11C344.45 208.34 328.8 223.99 309.57 223.99ZM309.57 159.24C293.1 159.24 279.69 172.64 279.69 189.12C279.69 205.6 293.09 219 309.57 219C326.05 219 339.45 205.6 339.45 189.12C339.45 172.64 326.05 159.24 309.57 159.24Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M309.57 156.73C308.36 156.73 307.18 156.8 306 156.93C322.2 158.71 334.81 172.43 334.81 189.11C334.81 205.79 322.2 219.51 306 221.29C307.17 221.42 308.36 221.49 309.57 221.49C327.45 221.49 341.95 206.99 341.95 189.11C341.95 171.23 327.45 156.73 309.57 156.73Z"
        fill="black"
      />
    </g>
    <path
      d="M209.15 244.45C209.15 262.33 194.65 276.83 176.77 276.83C158.89 276.83 144.39 262.33 144.39 244.45C144.39 226.57 158.89 212.07 176.77 212.07C194.65 212.07 209.15 226.57 209.15 244.45Z"
      fill="#E9D647"
    />
    <path
      d="M176.78 279.33C157.55 279.33 141.9 263.68 141.9 244.45C141.9 225.22 157.55 209.57 176.78 209.57C196.01 209.57 211.66 225.22 211.66 244.45C211.66 263.68 196.01 279.33 176.78 279.33ZM176.78 214.58C160.31 214.58 146.9 227.98 146.9 244.46C146.9 260.94 160.3 274.34 176.78 274.34C193.26 274.34 206.66 260.94 206.66 244.46C206.66 227.98 193.26 214.58 176.78 214.58Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M176.78 212.07C175.57 212.07 174.39 212.14 173.21 212.27C189.41 214.05 202.02 227.77 202.02 244.45C202.02 261.13 189.41 274.85 173.21 276.63C174.38 276.76 175.57 276.83 176.78 276.83C194.66 276.83 209.16 262.33 209.16 244.45C209.16 226.57 194.66 212.07 176.78 212.07Z"
        fill="black"
      />
    </g>
    <path
      d="M241.04 472.591C241.04 490.471 226.54 504.971 208.66 504.971C190.78 504.971 176.28 490.471 176.28 472.591C176.28 454.711 190.78 440.211 208.66 440.211C226.54 440.211 241.04 454.711 241.04 472.591Z"
      fill="#986EAB"
    />
    <path
      d="M208.66 507.471C189.43 507.471 173.78 491.821 173.78 472.591C173.78 453.361 189.43 437.711 208.66 437.711C227.89 437.711 243.54 453.361 243.54 472.591C243.54 491.821 227.89 507.471 208.66 507.471ZM208.66 442.721C192.18 442.721 178.78 456.121 178.78 472.601C178.78 489.081 192.18 502.481 208.66 502.481C225.14 502.481 238.54 489.081 238.54 472.601C238.54 456.121 225.14 442.721 208.66 442.721Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M208.66 440.223C207.45 440.223 206.27 440.293 205.09 440.423C221.29 442.203 233.9 455.923 233.9 472.603C233.9 489.283 221.29 503.003 205.09 504.783C206.26 504.913 207.45 504.983 208.66 504.983C226.54 504.983 241.04 490.483 241.04 472.603C241.04 454.723 226.54 440.223 208.66 440.223Z"
        fill="black"
      />
    </g>
    <path
      d="M482.19 367.181C482.19 385.061 467.69 399.561 449.81 399.561C431.93 399.561 417.43 385.061 417.43 367.181C417.43 349.301 431.93 334.801 449.81 334.801C467.69 334.801 482.19 349.301 482.19 367.181Z"
      fill="#986EAB"
    />
    <path
      d="M449.81 402.061C430.58 402.061 414.93 386.411 414.93 367.181C414.93 347.951 430.58 332.301 449.81 332.301C469.04 332.301 484.69 347.951 484.69 367.181C484.69 386.411 469.04 402.061 449.81 402.061ZM449.81 337.301C433.34 337.301 419.93 350.701 419.93 367.181C419.93 383.661 433.33 397.061 449.81 397.061C466.29 397.061 479.69 383.661 479.69 367.181C479.69 350.701 466.29 337.301 449.81 337.301Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M449.81 334.801C448.6 334.801 447.42 334.871 446.24 335.001C462.44 336.781 475.05 350.501 475.05 367.181C475.05 383.861 462.44 397.581 446.24 399.361C447.41 399.491 448.6 399.561 449.81 399.561C467.69 399.561 482.19 385.061 482.19 367.181C482.19 349.301 467.69 334.801 449.81 334.801Z"
        fill="black"
      />
    </g>
    <path
      d="M143.62 316.45C143.62 334.33 129.12 348.83 111.24 348.83C93.3602 348.83 78.8599 334.33 78.8599 316.45C78.8599 298.57 93.3602 284.07 111.24 284.07C129.12 284.07 143.62 298.57 143.62 316.45Z"
      fill="#AD8EBC"
    />
    <path
      d="M111.25 351.33C92.02 351.33 76.3701 335.68 76.3701 316.45C76.3701 297.22 92.02 281.57 111.25 281.57C130.48 281.57 146.13 297.22 146.13 316.45C146.13 335.68 130.48 351.33 111.25 351.33ZM111.25 286.57C94.78 286.57 81.3701 299.97 81.3701 316.45C81.3701 332.93 94.77 346.33 111.25 346.33C127.73 346.33 141.13 332.93 141.13 316.45C141.13 299.97 127.73 286.57 111.25 286.57Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M111.25 284.07C110.04 284.07 108.86 284.14 107.68 284.27C123.88 286.05 136.49 299.77 136.49 316.45C136.49 333.13 123.88 346.85 107.68 348.63C108.85 348.76 110.04 348.83 111.25 348.83C129.13 348.83 143.63 334.33 143.63 316.45C143.63 298.57 129.13 284.07 111.25 284.07Z"
        fill="black"
      />
    </g>
    <path
      d="M298.92 263.368C298.92 281.248 284.42 295.748 266.54 295.748C248.66 295.748 234.16 281.248 234.16 263.368C234.16 245.488 248.66 230.988 266.54 230.988C284.42 230.988 298.92 245.488 298.92 263.368Z"
      fill="#AD8EBC"
    />
    <path
      d="M266.54 298.248C247.31 298.248 231.66 282.598 231.66 263.368C231.66 244.138 247.31 228.488 266.54 228.488C285.77 228.488 301.42 244.138 301.42 263.368C301.42 282.598 285.77 298.248 266.54 298.248ZM266.54 233.488C250.07 233.488 236.66 246.888 236.66 263.368C236.66 279.848 250.06 293.248 266.54 293.248C283.02 293.248 296.42 279.848 296.42 263.368C296.42 246.888 283.02 233.488 266.54 233.488Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M266.54 230.988C265.33 230.988 264.15 231.058 262.97 231.188C279.17 232.968 291.78 246.688 291.78 263.368C291.78 280.048 279.17 293.768 262.97 295.548C264.14 295.678 265.33 295.748 266.54 295.748C284.42 295.748 298.92 281.248 298.92 263.368C298.92 245.488 284.42 230.988 266.54 230.988Z"
        fill="black"
      />
    </g>
    <path
      d="M426.09 434.38C426.09 452.26 411.59 466.76 393.71 466.76C375.83 466.76 361.33 452.26 361.33 434.38C361.33 416.5 375.83 402 393.71 402C411.59 402 426.09 416.5 426.09 434.38Z"
      fill="#AD8EBC"
    />
    <path
      d="M393.71 469.26C374.48 469.26 358.83 453.61 358.83 434.38C358.83 415.15 374.48 399.5 393.71 399.5C412.94 399.5 428.59 415.15 428.59 434.38C428.59 453.61 412.94 469.26 393.71 469.26ZM393.71 404.5C377.23 404.5 363.83 417.9 363.83 434.38C363.83 450.86 377.23 464.26 393.71 464.26C410.19 464.26 423.59 450.86 423.59 434.38C423.59 417.9 410.19 404.5 393.71 404.5Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M393.71 402.012C392.5 402.012 391.32 402.082 390.14 402.212C406.34 403.992 418.95 417.712 418.95 434.392C418.95 451.072 406.34 464.792 390.14 466.572C391.31 466.702 392.5 466.772 393.71 466.772C411.59 466.772 426.09 452.272 426.09 434.392C426.09 416.512 411.59 402.012 393.71 402.012Z"
        fill="black"
      />
    </g>
    <path d="M279.625 305.446L258.944 291.328L59.5852 583.359L80.2658 597.477L279.625 305.446Z" fill="#FBFF54" />
    <path
      d="M80.0696 600.183C79.5696 600.183 79.0799 600.033 78.6599 599.743L57.9798 585.623C56.8398 584.843 56.5499 583.293 57.3299 582.153L256.71 290.133C257.49 288.993 259.05 288.703 260.18 289.473L280.86 303.593C281.41 303.963 281.78 304.543 281.91 305.193C282.03 305.843 281.89 306.523 281.52 307.063L82.1399 599.083C81.7699 599.633 81.1898 600.003 80.5398 600.133C80.3898 600.163 80.2299 600.173 80.0799 600.173L80.0696 600.183ZM62.8699 582.913L79.4197 594.213L275.98 306.323L259.43 295.023L62.8699 582.913Z"
      fill="black"
    />
    <path d="M457.354 597.434L478.034 583.316L278.675 291.286L257.994 305.404L457.354 597.434Z" fill="#FBFF54" />
    <path
      d="M457.51 600.18C457.36 600.18 457.2 600.17 457.05 600.14C456.4 600.02 455.82 599.64 455.45 599.09L256.07 307.07C255.7 306.52 255.56 305.85 255.68 305.2C255.8 304.55 256.18 303.97 256.73 303.6L277.41 289.48C278.55 288.7 280.1 288.99 280.88 290.14L480.26 582.16C481.04 583.3 480.75 584.86 479.61 585.63L458.93 599.75C458.51 600.04 458.02 600.19 457.52 600.19L457.51 600.18ZM261.61 306.31L458.17 594.2L474.72 582.9L278.16 295.01L261.61 306.31Z"
      fill="black"
    />
    <path
      d="M269.1 315.55C278.842 315.55 286.74 307.652 286.74 297.91C286.74 288.167 278.842 280.27 269.1 280.27C259.358 280.27 251.46 288.167 251.46 297.91C251.46 307.652 259.358 315.55 269.1 315.55Z"
      fill="#AD8EBC"
    />
    <path
      d="M269.1 318.061C257.99 318.061 248.96 309.021 248.96 297.921C248.96 286.821 258 277.781 269.1 277.781C280.2 277.781 289.24 286.821 289.24 297.921C289.24 309.021 280.2 318.061 269.1 318.061ZM269.1 282.771C260.75 282.771 253.96 289.561 253.96 297.911C253.96 306.261 260.75 313.051 269.1 313.051C277.45 313.051 284.24 306.261 284.24 297.911C284.24 289.561 277.45 282.771 269.1 282.771Z"
      fill="black"
    />
    <path
      d="M80.8901 583.84H11.7002C6.63574 583.84 2.52979 587.945 2.52979 593.01V609.12C2.52979 614.184 6.63574 618.29 11.7002 618.29H80.8901C85.9546 618.29 90.0601 614.184 90.0601 609.12V593.01C90.0601 587.945 85.9546 583.84 80.8901 583.84Z"
      fill="#E9D647"
    />
    <path
      d="M80.8901 620.79H11.7002C5.2702 620.79 0.0297852 615.55 0.0297852 609.12V593.01C0.0297852 586.58 5.2702 581.34 11.7002 581.34H80.8901C87.3201 581.34 92.5601 586.58 92.5601 593.01V609.12C92.5601 615.55 87.3201 620.79 80.8901 620.79ZM11.7002 586.34C8.0202 586.34 5.02979 589.33 5.02979 593.01V609.12C5.02979 612.8 8.0202 615.79 11.7002 615.79H80.8901C84.5701 615.79 87.5601 612.8 87.5601 609.12V593.01C87.5601 589.33 84.5701 586.34 80.8901 586.34H11.7002Z"
      fill="black"
    />
    <path
      d="M528.09 583.84H458.9C453.835 583.84 449.73 587.945 449.73 593.01V609.12C449.73 614.184 453.835 618.29 458.9 618.29H528.09C533.154 618.29 537.26 614.184 537.26 609.12V593.01C537.26 587.945 533.154 583.84 528.09 583.84Z"
      fill="#E9D647"
    />
    <path
      d="M528.08 620.79H458.89C452.46 620.79 447.22 615.55 447.22 609.12V593.01C447.22 586.58 452.46 581.34 458.89 581.34H528.08C534.51 581.34 539.75 586.58 539.75 593.01V609.12C539.75 615.55 534.51 620.79 528.08 620.79ZM458.89 586.34C455.21 586.34 452.22 589.33 452.22 593.01V609.12C452.22 612.8 455.21 615.79 458.89 615.79H528.08C531.76 615.79 534.75 612.8 534.75 609.12V593.01C534.75 589.33 531.76 586.34 528.08 586.34H458.89Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M257.62 313.91L75.2202 582.71L59.3999 583.56L250.28 303.18L257.62 313.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M279.64 313.91L463.17 582.15L452.44 587.23L266.94 317.58L279.64 313.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M2.37012 601.059H89.6201V611.509L80.8901 618.289H10L1.81006 611.229L2.37012 601.059Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M449.06 601.059H536.3V611.509L527.57 618.289H456.68L448.49 611.229L449.06 601.059Z" fill="#E9D647" />
    </g>
  </svg>
))`
  left: calc((621px - 540px) / 2 + 2px);
`;

export default Frame5;
