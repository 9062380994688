import React from 'react';
import styled from 'styled-components';
import { IFlock } from '../../../../lib/types/flock';

export const UrlIcon = styled.div<{ large?: boolean; url: null | string }>`
    background: ${({ url }) => `url('${url}'`});
  background-size: cover;
  border-radius: 50% 50%;
  flex-shrink: 0;
    margin-right: ${({ large }) => (large ? '' : '26px')};
    width: ${({ large }) => (large ? '350px' : '79px')};
    height: ${({ large }) => (large ? '350px' : '79px')};
`;

const getFlockIcon = (flock: IFlock) => {
  if (flock.coverUrl) return ({ large }: { large?: boolean }) => <UrlIcon large={large} url={flock.coverUrl} />;

  return ({ large }: { large?: boolean }) => (
    <UrlIcon large={large} url="https://cdn.timesoul.com/tma/task-subsribe-our-tg-channel.jpg" />
  );
};

export default getFlockIcon;
