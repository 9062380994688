import styled from 'styled-components';

const IconContainer = styled.div.attrs({
  className: 'icon-container',
})<{ active: boolean }>`
  /* background-color: ${({ active }) => (active ? 'black' : 'none')}; */
  filter: ${({ active }) => (active ? `grayscale(100)` : 'grayscale(0)')};
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default IconContainer;
