import styled from 'styled-components';

import PageContainer from '../components/Wrappers';
import { useApp } from '../contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { postTickets } from '../lib/api';
import { NavLink } from 'react-router-dom';
import IconLotteries from '../components/Icons/IconLotteries';
import { IconCoin } from '../components/Icons/IconCoin';
import { IconTon } from '../components/Icons/IconTon';
import Row from '../components/Row';
import { IconFireworksStarA } from '../components/Icons/IconFireworksStarA';
import { IconFireworksStarB } from '../components/Icons/IconFireworksStarB';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import NextDrawTime from '../components/pages/Lotteries/NextDrawTime';
import LotteryMachine from '../components/pages/Lotteries/LotteryMachine';
import { motion } from 'framer-motion';
import { compactAmount, doHapticFeedback, shortenWallet } from '../lib/utils';
import RefreshButton from '../components/RefreshButton';

const Container = styled.div.attrs({
  'data-tip': 'lottery-container',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: 0;
  margin: -200px 0 0 0;

  a {
    color: #c4b8da;
    text-decoration: underline;
  }

  h1 {
    font-family: Chicago, sans-serif;
    font-size: 140px;
    letter-spacing: 0.05em;
    color: #faff00;
  }

  button,
  button div div {
    font-family: Chicago, sans-serif;
    font-size: 40px;
    /* letter-spacing: 0.05em; */
    color: #faff00;
  }
`;

const FireworksContainer = styled.div.attrs({
  'data-tip': 'lottery-fireworks-container',
})`
  position: relative;

  @keyframes pulseFireworksStar {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.1;
    }
  }

  img {
    width: 394px;
  }

  .fireworksStarA,
  .fireworksStarB {
    position: absolute;
    width: 250px;
    right: -250px;
    top: -100px;
    height: auto;

    animation: pulseFireworksStar 9s infinite;

    &:first-of-type {
      width: 250px;
      left: -250px;
      top: 80px;
      animation: pulseFireworksStar 7s infinite;
    }
  }
`;

const Header = styled.div.attrs({
  'data-tip': 'lottery-header',
})`
  font-family: Chicago, sans-serif;
  font-size: 72px;
  font-weight: 400;
  line-height: 75.11px;
  text-align: center;
  text-transform: uppercase;
  color: #faff00;
`;

const Double = styled.div.attrs({
  'data-tip': 'lottery-double',
})`
  font-size: 46px;
  font-family: var(--font), sans-serif;
  margin-bottom: 10px;
`;

const LargeNote = styled.p.attrs({
  'data-tip': 'lottery-large-note',
})`
  font-family: Roboto, sans-serif;
  font-size: 42px;
  text-align: center;
  color: rgb(196, 184, 218);
  span {
    color: #faff00;
  }
`;

const Note = styled.p.attrs({
  'data-tip': 'lottery-note',
})`
  font-family: Roboto, sans-serif;
  font-size: 36px;
  text-align: center;
  width: 800px;
  /* color: #faff00; */
  color: rgb(232, 232, 232);
`;

const Pitch = styled.div.attrs({
  'data-tip': 'lottery-pitch',
})`
  color: #d7d7d7b3;
`;

const IAmInButton = styled.button.attrs({
  'data-tip': 'lottery-iamin-button',
})<{ disabled?: boolean }>`
  height: 130px;
  width: 800px;
  margin: 0 auto 0;

  background: ${({ disabled }) => (disabled ? '#000' : '#00000094')};
  color: ${({ disabled }) => (disabled ? '#555' : '#efff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? 'gray' : '#efff00')};

  font-family: var(--font), sans-serif;
  text-transform: uppercase;
  border-radius: 84px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 32px;
  font-weight: 500;
`;

const YourWallet = () => {
  const { app } = useApp();
  const { wallet } = app.state;
  const { t } = useTranslation();

  return (
    <div className="your-wallet">
      {!wallet ? t('system.wallet.yourWallet') + ' ' : ''}
      <span>{wallet ? shortenWallet(wallet) : `<${t('system.wallet.notConnected')}>`}</span>
    </div>
  );
};

const McToTon = () => {
  return (
    <LargeNote style={{ marginTop: '-16px', filter: 'grayscale() sepia(100%) hue-rotate(220deg) saturate(200%) brightness(90%)' }}>
    <Row gap="8px">
      <IconCoin />
      →
      <IconTon style={{ marginLeft: '7px', marginTop: '4px' }} />
    </Row>
  </LargeNote>
  )
}

const Lottery = () => {
  const { app, taskFactor, updateApp } = useApp();
  const { wallet } = app.state;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const lotteries = app.lotteries.all;
  const lottery = app.lotteries.active[0];
  const ticketPrice = taskFactor * app.config.lotteries.basePrice;
  const gotTicket = (app.lotteries.active?.[0]?.ticketsCount || 0) > 0;
  const bonusTickets = app.nfts.length;

  const buyTicket = async () => {
    setLoading(true);
    await postTickets(lottery.uuid, wallet);
    await updateApp();
    setLoading(false);
  };

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer vcenter>
      <Container>
        <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
          <h1>{t('lottery.heading')}</h1>
          {/*  <McToTon /> */}
        </motion.div>
        <Pitch>
          <motion.div
            initial={{ x: -64 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}
          >
            <LargeNote>⚡️{t('lottery.pitch1')}</LargeNote>
            <LargeNote>{t('lottery.pitch2')}⚡️</LargeNote>
          </motion.div>
          <motion.div
            initial={{ x: 64 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}
          >
            <NextDrawTime lotteries={lotteries} />
          </motion.div>
          <LargeNote>{wallet && <YourWallet />}</LargeNote>
        </Pitch>
        <Row margin="40px 0 80px">
          <LotteryMachine fast={!!wallet && gotTicket} />
        </Row>
        {!wallet && (
          <>
            <Row gap="40px">
              <LargeNote>
                <YourWallet />
              </LargeNote>
              <div style={{ marginBottom: 4 }}>
                <RefreshButton coolDownSeconds={10} />
              </div>
            </Row>
            <Row margin="25px 0 0">
              <LargeNote style={{ color: 'yellow' }}>
                {t('lottery.toParticipateItIsNecessary')}
                <br />
                <NavLink to="/airdrop" style={{ color: 'yellow' }}>
                  {t('lottery.toConfirmWallet')}
                  <br />
                </NavLink>
              </LargeNote>
            </Row>
            <Row margin="36px 0 0">
              <LargeNote>
                <NavLink to="/lotteries">
                  {t('lottery.pastLotteryResultsHere')} <IconLotteries />
                </NavLink>
              </LargeNote>
            </Row>
          </>
        )}
        {wallet && gotTicket && (
          <>
            <FireworksContainer>
              <Header>
                {t('friends.spirit.congratulations')}
                <br />
                {t('lottery.youHaveEntered')}
                <br />
                {t('lottery.theLottery')}
              </Header>
              <Row margin="36px 0 0">
                <LargeNote>
                  <NavLink to="/lotteries">
                    {t('lottery.pastLotteryResultsHere')} <IconLotteries />
                  </NavLink>
                </LargeNote>
              </Row>
              <IconFireworksStarA />
              <IconFireworksStarB />
            </FireworksContainer>
          </>
        )}
        {wallet && !gotTicket && (
          <>
            <IAmInButton onClick={buyTicket} disabled={loading || !lottery}>
              <Double>{t('lottery.button.iAmIn')}</Double>
              {t('lottery.button.tickets', { price: compactAmount(ticketPrice), bonusTickets })}
            </IAmInButton>
            <Row margin="36px 0 0">
              <Note>{t('lottery.forEachNFT')}</Note>
            </Row>

            <Row margin="36px 0 0">
              <LargeNote>
                <NavLink to="/lotteries">
                  {t('lottery.pastLotteryResultsHere')} <IconLotteries />
                </NavLink>
              </LargeNote>
            </Row>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default Lottery;
