import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Frame1 from './Frame1';
import Frame2 from './Frame2';
import Frame3 from './Frame3';
import Frame4 from './Frame4';
import Frame5 from './Frame5';

const Container = styled.div`
  position: relative;
  width: 621px;
  height: 621px;
  svg {
    position: absolute;
    bottom: 0;
  }
  transform: scale(0.9);
  transform-origin: center 0;
  margin-bottom: -62px;
`;

const LotteryMachine = ({ fast }: { fast: boolean }) => {
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setFrame((prevFrame) => (prevFrame + 1) % 5);
      },
      fast ? 100 : 1000
    );
    return () => clearInterval(interval);
  }, [fast]);

  return (
    <Container>
      {frame === 0 && <Frame1 />}
      {frame === 1 && <Frame2 />}
      {frame === 2 && <Frame3 />}
      {frame === 3 && <Frame4 />}
      {frame === 4 && <Frame5 />}
    </Container>
  );
};

export default LotteryMachine;
