import styled from 'styled-components';
import { useApp } from '../contexts/AppContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Row from '../components/Row';
import { IconCoin } from '../components/Icons/IconCoin';
import { amountWithSpaces } from '../lib/utils';
import getIcon from '../components/pages/Tasks/TaskIcons';
import { ITask } from '../lib/types';
import WebApp from '@twa-dev/sdk';
import { patchUserTasks } from '../lib/api';
import { notificationEmit } from '../controllers/NotificationsController';
import Loading from '../components/Loading';
import PageContainer from '../components/Wrappers';
import { env } from '../lib/env';
import { IconAttention } from '../components/Icons/IconAttention';

const Header = styled.div.attrs({
  className: "task-header"
})`
  font-family: var(--font), sans-serif;
  color: #efff00;
  font-size: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
`;

const Bonus = styled.div.attrs({
  className: "task-bonus"
})`
  color: white;
  font-size: 35px;
  font-weight: 500;
  margin: 70px 0 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  scale: 1.5;
`;

const ExtraDescription = styled.div.attrs({
  className: "task-extra-description"
})`
  width: 850px;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: -0.01em;
  text-align: center;
  margin: 0 0 40px;

  svg {
    position: absolute;
    margin-left: -63px;
    margin-top: 1px;
  }
`;

const Button = styled.button.attrs({
  className: "task-button"
})<{ disabled?: boolean }>`
  min-width: 12ch;
  height: 100px;
  margin: 0 auto 0;

  background: ${({ disabled }) => (disabled ? 'gray' : '#00000094')};
  color: ${({ disabled }) => (disabled ? 'darkkhaki' : '#efff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? 'gray' : '#efff00')};

  font-family: var(--font), sans-serif;
  text-transform: uppercase;
  border-radius: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 42px;
  font-weight: 500;
  padding: 0 100px;
`;

const Task = () => {
  const { tasks, taskFactor, updateApp, setEvent } = useApp();
  const navigate = useNavigate();
  const { id } = useParams();
  const [confirming, setConfirming] = useState(false);
  const [joinClicked, setJoinClicked] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(3);
  const task = tasks.find((task) => task.id === id);
  const [checkDisabled, setCheckDisabled] = useState(!!task?.url);
  if (!task) throw new Error('Should not happen');
  const Icon = getIcon(task);
  const { t } = useTranslation();
  const isJoinMysteryCaveTask = task.id === env.joinMysteryCaveTask;

  const handleJoin = async (task: ITask) => {
    setJoinClicked(true);
    const isTelegram = task.url.startsWith('https://t.me');

    if (!isTelegram) WebApp.openLink(task.url);
    else WebApp.openTelegramLink(task.url);
  };

  const handleCheck = async (task: ITask) => {
    await patchUserTasks(task.id);
    const start = await updateApp();
    const updatedTask = start.tasks.find((userTask: ITask) => userTask.id === task.id);
    if (!updatedTask) throw new Error('Should not happen');
    if (!updatedTask?.isCompleted)
      notificationEmit({
        title: t('popups.checkTask.taskIsNotCompleted'),
      });
    return !updatedTask?.isCompleted;
  };

  const handleConfirm = async () => {
    let abortClose: void | boolean = false;
    setConfirming(true);
    abortClose = await handleCheck(task);
    setConfirming(false);
    if (!abortClose) handleClose();
  };

  const handleClose = () => {
    navigate(`/tasks`);
    setEvent({ type: 'taskComplete', task });
  };

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (joinClicked && secondsLeft > 0) {
      timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setCheckDisabled(false);
    }

    return () => clearInterval(timer);
  }, [joinClicked, secondsLeft]);

  return (
    <PageContainer vcenter dark>
      <Header>{task.title}</Header>
      <Row margin="20px 0 40px">
        <Icon large={true} />
      </Row>
      {isJoinMysteryCaveTask && (
        <ExtraDescription>
          <IconAttention /> {t('popups.checkTask.joinMysteryCaveTask')}
        </ExtraDescription>
      )}
      {!!task?.url && <Button onClick={() => handleJoin(task)}>{t('popups.checkTask.doIt')}</Button>}
      {!task.isCompleted && (
        <Bonus>
          <IconCoin />+{amountWithSpaces(task.reward * taskFactor)}
        </Bonus>
      )}
      {!task.isCompleted && (
        <Button disabled={checkDisabled} onClick={handleConfirm}>
          {confirming ? <Loading /> : t('popups.checkTask.check') || t('system.close')}
        </Button>
      )}
    </PageContainer>
  );
};

export default Task;
