import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { useTranslation } from 'react-i18next';

export const Icon = () => (
  <svg width="96" height="106" viewBox="0 0 86 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1825 104.293C11.8358 104.293 11.4892 104.094 11.2416 103.797C11.0435 103.5 10.9445 103.104 11.093 102.757L21.8887 70.1227C22.0373 69.6275 22.483 69.3303 22.9782 69.3303H35.6062C35.9528 69.3303 36.2995 69.5284 36.5471 69.8255C36.7452 70.1227 36.8442 70.5188 36.6957 70.8655L25.9 103.5C25.7514 103.995 25.3057 104.293 24.8105 104.293H12.1825Z"
      fill="#B48EC0"
    />
    <path
      d="M35.606 70.5187L24.8103 103.153H12.1823L22.978 70.5187H35.606ZM35.606 68.2407H22.978C21.9876 68.2407 21.0962 68.8845 20.7991 69.8254L10.0034 102.46C9.75577 103.153 9.90433 103.946 10.3005 104.54C10.7462 105.134 11.4395 105.481 12.1823 105.481H24.8103C25.8008 105.481 26.6921 104.837 26.9893 103.896L37.785 71.2615C38.0326 70.5682 37.884 69.7759 37.4878 69.1816C37.0421 68.5874 36.3488 68.2407 35.606 68.2407Z"
      fill="#303030"
    />
    <path
      d="M30.6537 70.5188L20.9474 99.8356H13.2716L12.1821 103.154H24.8101L35.6058 70.5188H30.6537Z"
      fill="#A579B2"
    />
    <path
      d="M61.2579 104.293C60.7627 104.293 60.317 103.995 60.1685 103.5L49.3728 70.8655C49.2737 70.5188 49.3232 70.1227 49.5213 69.8255C49.7194 69.5284 50.0661 69.3303 50.4622 69.3303H63.0902C63.5855 69.3303 64.0311 69.6275 64.1797 70.1227L74.9754 102.757C75.0745 103.104 75.0249 103.5 74.8268 103.797C74.6288 104.094 74.2821 104.293 73.8859 104.293H61.2579Z"
      fill="#B48EC0"
    />
    <path
      d="M63.0905 70.5187L73.8862 103.153H61.2582L50.4625 70.5187H63.0905ZM63.0905 68.2407H50.4625C49.7197 68.2407 49.0264 68.5874 48.5807 69.1816C48.135 69.7759 48.0359 70.5682 48.2835 71.2615L59.0792 103.896C59.3764 104.837 60.2677 105.481 61.2582 105.481H73.8862C74.629 105.481 75.3223 105.134 75.768 104.54C76.2137 103.946 76.3127 103.153 76.0651 102.46L65.2694 69.8254C64.9723 68.8845 64.0809 68.2407 63.0905 68.2407Z"
      fill="#303030"
    />
    <path
      d="M63.0905 70.5188H58.9307L68.6369 99.8356H60.1687L61.2582 103.154H73.8862L63.0905 70.5188Z"
      fill="#A579B2"
    />
    <path
      d="M43.0344 88.3464C66.1725 88.3464 84.9296 69.5892 84.9296 46.4511C84.9296 23.313 66.1725 4.55591 43.0344 4.55591C19.8963 4.55591 1.13916 23.313 1.13916 46.4511C1.13916 69.5892 19.8963 88.3464 43.0344 88.3464Z"
      fill="#FAFF00"
    />
    <path
      d="M43.0342 5.69499C65.517 5.69499 83.7905 23.9189 83.7905 46.4512C83.7905 68.9835 65.5665 87.2075 43.0342 87.2075C20.5019 87.2075 2.27799 68.934 2.27799 46.4512C2.27799 23.9684 20.5019 5.69499 43.0342 5.69499ZM43.0342 3.41699C19.3134 3.41699 0 22.7304 0 46.4512C0 70.172 19.3134 89.4855 43.0342 89.4855C66.7551 89.4855 86.0685 70.172 86.0685 46.4512C86.0685 22.7304 66.7551 3.41699 43.0342 3.41699Z"
      fill="#303030"
    />
    <path
      d="M43.0342 8.86426C28.1777 8.86426 15.3021 17.5305 9.21094 30.0595"
      stroke="white"
      strokeWidth="2.38464"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.1208 12.2319C72.6481 19.6106 77.3526 29.9111 77.3526 41.3011C77.3526 63.7839 59.1287 82.0573 36.5964 82.0573C28.4748 82.0573 20.898 79.6803 14.5098 75.5205C21.839 82.7506 31.9414 87.2076 43.0342 87.2076C65.517 87.2076 83.7904 68.9836 83.7904 46.4513C83.7904 32.0901 76.3622 19.5116 65.1208 12.2319Z"
      fill="#EDD500"
    />
    <path
      d="M43.0345 77.4022C60.1282 77.4022 73.9854 63.545 73.9854 46.4512C73.9854 29.3575 60.1282 15.5002 43.0345 15.5002C25.9407 15.5002 12.0835 29.3575 12.0835 46.4512C12.0835 63.545 25.9407 77.4022 43.0345 77.4022Z"
      fill="#B48EC0"
    />
    <path
      d="M43.0343 16.6393C59.4755 16.6393 72.8463 29.9606 72.8463 46.4513C72.8463 62.942 59.4755 76.2633 43.0343 76.2633C26.5932 76.2633 13.2223 62.942 13.2223 46.4513C13.2223 29.9606 26.5436 16.6393 43.0343 16.6393ZM43.0343 14.3613C25.3056 14.3613 10.9443 28.7721 10.9443 46.4513C10.9443 64.1305 25.3551 78.5413 43.0343 78.5413C60.7135 78.5413 75.1243 64.1305 75.1243 46.4513C75.1243 28.7721 60.7135 14.3613 43.0343 14.3613Z"
      fill="#303030"
    />
    <path
      d="M43.0343 66.1606C53.9196 66.1606 62.7439 57.3363 62.7439 46.451C62.7439 35.5657 53.9196 26.7415 43.0343 26.7415C32.149 26.7415 23.3247 35.5657 23.3247 46.451C23.3247 57.3363 32.149 66.1606 43.0343 66.1606Z"
      fill="#FAFF00"
    />
    <path
      d="M43.0343 27.8805C53.2853 27.8805 61.6049 36.2001 61.6049 46.4511C61.6049 56.7021 53.2853 65.0217 43.0343 65.0217C32.7834 65.0217 24.4637 56.7021 24.4637 46.4511C24.4637 36.2001 32.7834 27.8805 43.0343 27.8805ZM43.0343 25.6025C31.4958 25.6025 22.1362 34.9621 22.1362 46.5006C22.1362 58.0392 31.4958 67.3987 43.0343 67.3987C54.5729 67.3987 63.9324 58.0392 63.9324 46.5006C63.9324 34.9621 54.5729 25.6025 43.0343 25.6025Z"
      fill="#303030"
    />
    <path
      d="M52.4931 30.4558C55.2168 33.6747 56.851 37.8345 56.851 42.3905C56.851 52.6414 48.5314 60.961 38.2804 60.961C34.8139 60.961 31.595 60.0201 28.8218 58.3364C32.2388 62.3972 37.3395 64.9723 43.084 64.9723C53.335 64.9723 61.6546 56.6527 61.6546 46.4017C61.6546 39.6173 57.99 33.6747 52.5426 30.4062L52.4931 30.4558Z"
      fill="#EDD500"
    />
    <path
      d="M43.0341 16.6392C42.638 16.6392 42.2913 16.6392 41.8951 16.6887C56.5535 17.2829 68.2901 29.3662 68.2901 44.1731C68.2901 58.9801 55.9593 71.7071 40.7562 71.7071C25.553 71.7071 13.8659 59.9705 13.2717 45.3121C13.2717 45.7083 13.2222 46.055 13.2222 46.4511C13.2222 62.8923 26.5435 76.2631 43.0341 76.2631C59.5248 76.2631 72.8461 62.9418 72.8461 46.4511C72.8461 29.9604 59.4753 16.6392 43.0341 16.6392Z"
      fill="#A579B2"
    />
    <path
      d="M43.0344 55.1175C47.8207 55.1175 51.7007 51.2374 51.7007 46.4512C51.7007 41.6649 47.8207 37.7849 43.0344 37.7849C38.2482 37.7849 34.3682 41.6649 34.3682 46.4512C34.3682 51.2374 38.2482 55.1175 43.0344 55.1175Z"
      fill="#B48EC0"
    />
    <path
      d="M43.0345 38.924C47.1943 38.924 50.5618 42.2915 50.5618 46.4513C50.5618 50.6111 47.1943 53.9785 43.0345 53.9785C38.8747 53.9785 35.5072 50.6111 35.5072 46.4513C35.5072 42.2915 38.8747 38.924 43.0345 38.924ZM43.0345 36.646C37.5871 36.646 33.1797 41.0534 33.1797 46.5008C33.1797 51.9482 37.5871 56.3556 43.0345 56.3556C48.4819 56.3556 52.8893 51.9482 52.8893 46.5008C52.8893 41.0534 48.4819 36.646 43.0345 36.646Z"
      fill="#303030"
    />
    <path
      d="M45.1636 39.2705C46.154 40.5581 46.7978 42.1428 46.7978 43.876C46.7978 48.0358 43.4304 51.4033 39.2705 51.4033C38.5277 51.4033 37.8344 51.2547 37.1411 51.0566C38.5277 52.8394 40.6571 53.9784 43.0837 53.9784C47.2435 53.9784 50.611 50.6109 50.611 46.4511C50.611 43.0341 48.333 40.2114 45.2131 39.2705H45.1636Z"
      fill="#A579B2"
    />
    <path
      d="M43.0342 46.4512L80.7696 13.668"
      stroke="#5E1F56"
      strokeWidth="2.38464"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.8307 16.6394C84.8307 16.6394 84.6326 16.6394 84.5831 16.6394L79.4824 15.4013C78.9872 15.3023 78.6405 14.8566 78.591 14.3614L78.1948 9.16161C78.1948 8.81496 78.2939 8.41879 78.591 8.17118L86.5144 1.38673C86.5144 1.38673 87.0097 1.0896 87.2573 1.0896C87.4058 1.0896 87.5049 1.0896 87.6534 1.13912C88.0496 1.28769 88.3467 1.58482 88.3963 1.98099L89.2381 6.09128L93.1503 7.57692C93.5465 7.72549 93.7941 8.07214 93.8932 8.46831C93.9427 8.86448 93.7941 9.26065 93.497 9.55778L85.5735 16.3422C85.5735 16.3422 85.0783 16.6394 84.8307 16.6394Z"
      fill="#B48EC0"
    />
    <path
      d="M87.2571 2.27799L88.2475 7.03205L92.754 8.71579L84.8305 15.5002L79.7298 14.2622L79.3336 9.06245L87.2571 2.27799ZM87.2571 0C86.7123 0 86.1676 0.19808 85.7714 0.544731L77.848 7.32919C77.3032 7.8244 77.0061 8.5177 77.0556 9.26053L77.4518 14.4603C77.5509 15.4507 78.2442 16.2926 79.1851 16.4907L84.2858 17.7287C84.2858 17.7287 84.6324 17.7782 84.8305 17.7782C85.3753 17.7782 85.92 17.5802 86.3162 17.2335L94.2396 10.449C94.8339 9.90431 95.131 9.11196 94.9824 8.31961C94.8339 7.52727 94.2891 6.83397 93.5463 6.58637L90.2284 5.34833L89.4855 1.83229C89.337 1.03995 88.7427 0.396166 87.9999 0.148559C87.7523 0.0495155 87.5047 0 87.2571 0Z"
      fill="#303030"
    />
  </svg>
);

const Missions = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer width={120}>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
      <ButtonLabel active={active}>{t('menu.missions')}</ButtonLabel>
    </ButtonContainer>
  );
};

export default Missions;
