import React from 'react';
import styled from 'styled-components';
import Row from '../../Row';
import { useTranslation } from 'react-i18next';
import { filterSpecialCharacters, shortenWallet } from '../../../lib/utils';
import { Winner } from '../../../lib/types/lottery';

const Container = styled.div`
  height: 130px;
  width: 770px;

  border-radius: 105px;
  border: 1.5px solid rgba(235, 235, 235, 0.61);
  background: rgba(50, 54, 60, 0.64);

  padding: 0 60px;
  display: flex;
  margin: 10px auto;
  align-items: center;
`;

const Name = styled.div`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 32px;
`;

const Wallet = styled.div`
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 40px;
`;

const Reward = styled.div`
  font-size: 40px;
  color: white;
`;

const WinnerCard = ({ winner }: { winner: Winner }) => {
  const { t } = useTranslation();
  const name = winner.displayAs || `${t('system.user')} ${winner.id}`;

  return (
    <Container>
      <Row spread={true} style={{ width: '100%' }}>
        <div>
          <Name>{filterSpecialCharacters(name)}</Name>
          <Wallet>{shortenWallet(winner.wallet)}</Wallet>
        </div>
        <Row>{!!winner.rewardMicroton && <Reward>{winner.rewardMicroton / 1000000} TON</Reward>}</Row>
      </Row>
    </Container>
  );
};

export default WinnerCard;
