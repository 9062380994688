import React from 'react';
import styled from 'styled-components';
import Row from '../../Row';
import { amountWithSpaces, compactAmount, filterSpecialCharacters } from '../../../lib/utils';
import { IconCoin } from '../../Icons/IconCoin';
import { IFlock } from '../../../lib/types/flock';
import { useNavigate } from 'react-router-dom';
import { DoneIcon, NextIcon } from '../Tasks/TaskCard';
import { useApp } from '../../../contexts/AppContext';
import getFlockIcon from './FlockIcons';

const Container = styled.div<{ isMember: boolean }>`
  color: white;
  height: 130px;
  width: 770px;

  border-radius: 105px;
  border: 2px solid ${({ isMember }) => (isMember ? '#FAFF00' : 'rgba(235, 235, 235, 0.61)')};
  background: rgba(50, 54, 60, 0.64);

  padding: 0 40px 0 30px;
  display: flex;
  margin: 10px auto;
  align-items: center;
`;

const Name = styled.div`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 32px;

  & a {
    color: black;
  }
`;

const Balance = styled.div`
  margin-left: 5px;
  font-size: 40px;
`;

const Reward = styled.div`
  color: #faff00;
  border: 1.5px solid #faff00;
  border-radius: 20px;
  position: absolute;
  right: 150px;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 42.35px;
  text-align: left;
  background: #00000069;
  padding: 5px 0 4px 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    height: 40px;
    position: relative;
    top: -2px;
  }
`;

const FlockCard = ({
  flock,
  tab,
  place,
  length = 100,
}: {
  flock: IFlock;
  tab: 'new' | 'all-time';
  place?: number;
  length?: number;
}) => {
  const { app } = useApp();
  const navigate = useNavigate();
  const Icon = getFlockIcon(flock);
  const owned = app.flocks.flockOwn?.id === flock.id;

  const handleOnClick = () => {
    if (!owned) navigate(`/flock/${tab}/${flock.id}`);
  };

  return (
    <Container isMember={!flock.joinedAt} onClick={handleOnClick}>
      <Row spread={true} style={{ width: '100%', position: 'relative' }}>
        <Row gap="48px">
          <Icon />
          <div>
            <Name>{!!flock.title && filterSpecialCharacters(flock.title)}</Name>
            <Row style={{ justifyContent: 'left' }} gap="20px">
              <Balance>{compactAmount(flock.balance)}</Balance>
              <IconCoin />
              {!flock.joinedAt && !owned && (
                <Reward>
                  + {amountWithSpaces(flock.reward)}
                  <IconCoin />
                </Reward>
              )}
            </Row>
          </div>
        </Row>
        {!!flock.joinedAt && <DoneIcon />}
        {!flock.joinedAt && !owned && <NextIcon />}
      </Row>
    </Container>
  );
};

export default FlockCard;
