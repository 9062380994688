import styled from 'styled-components';

export const WebIcon = styled(({ large, ...props }: { large?: boolean }) => (
  <svg width={79} height={79} viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1482_387)">
      <path
        d="M39.5 79C61.3153 79 79 61.3153 79 39.5C79 17.6848 61.3153 0 39.5 0C17.6848 0 0 17.6848 0 39.5C0 61.3153 17.6848 79 39.5 79Z"
        fill="black"
      />
      <g clipPath="url(#clip1_1482_387)">
        <path
          d="M15 38.4368C15.1289 37.4889 15.2305 36.5358 15.3932 35.5931C15.9583 32.334 17.1497 29.3145 18.9336 26.5306C18.9753 26.4668 19.0273 26.4095 19.1055 26.3105C19.2201 26.4928 19.3151 26.6361 19.4023 26.7858C22.9544 32.9368 26.5143 39.0814 30.0521 45.2402C31.7812 48.2507 34.2747 50.2207 37.6797 50.9798C39.556 51.3978 41.4232 51.2936 43.3411 50.7233C43.2669 50.89 43.2292 51.002 43.1719 51.1009C40.6536 55.4655 38.1315 59.8262 35.6224 64.196C35.4557 64.4876 35.2969 64.5645 34.9714 64.5007C25.2878 62.6152 17.2995 54.5853 15.4844 44.8822C15.2799 43.7845 15.1602 42.6686 15 41.5618C15 40.5202 15 39.4785 15 38.4368Z"
          fill="white"
        />
        <path
          d="M38.6328 65.0006C38.7097 64.8092 38.7656 64.6061 38.8672 64.429C42.4141 58.2767 45.9584 52.123 49.5209 45.9798C50.6055 44.1113 51.2474 42.1283 51.224 39.9603C51.1927 36.929 50.1055 34.3197 48 32.1374C47.8893 32.0215 47.7461 31.9368 47.6784 31.7402H63.5938C64.112 33.1634 64.4753 34.6556 64.7018 36.1803C64.8177 36.9642 64.9011 37.752 64.9987 38.5384V41.5658C64.9714 41.6921 64.9349 41.8158 64.9167 41.9434C64.7474 43.0996 64.6393 44.2702 64.4037 45.4121C62.4011 55.1035 54.112 63.0345 44.3542 64.6178C43.4271 64.7689 42.4922 64.8756 41.5612 65.0033H38.6315L38.6328 65.0006Z"
          fill="white"
        />
        <path
          d="M41.5625 15C42.4948 15.1276 43.4336 15.2122 44.3568 15.388C52.3959 16.9206 58.3073 21.3581 62.2149 28.5156C62.2448 28.5703 62.263 28.6315 62.319 28.7695H61.724C54.6302 28.7695 47.5352 28.8346 40.4427 28.7487C34.7565 28.6797 30.2891 32.4727 29.1094 37.2617C29.0912 37.3359 29.0612 37.4089 29.0104 37.5625C26.3294 32.9193 23.6836 28.3359 21.0261 23.7344C22.28 22.2669 23.6654 20.9714 25.2071 19.8438C29.0404 17.0417 33.3229 15.4466 38.0612 15.0794C38.1875 15.069 38.3125 15.0273 38.4375 15C39.4792 15 40.5209 15 41.5625 15Z"
          fill="white"
        />
        <path
          d="M40 31.6992C44.569 31.6979 48.2917 35.4102 48.3008 39.9753C48.3086 44.5703 44.5898 48.2995 40 48.2995C35.4115 48.2995 31.6914 44.5677 31.7005 39.974C31.7096 35.4076 35.4271 31.7005 40 31.6992Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1482_387">
        <rect width={79} height={79} fill="white" />
      </clipPath>
      <clipPath id="clip1_1482_387">
        <rect width={50} height={50} fill="white" transform="translate(15 15)" />
      </clipPath>
    </defs>
  </svg>
))<{ large?: boolean }>`
  flex-shrink: 0;
  margin-right: ${({ large }) => (large ? '' : '26px')};
  width: ${({ large }) => (large ? '350px' : '79px')};
  height: ${({ large }) => (large ? '350px' : '79px')};
`;
