import PageContainer from '../components/Wrappers';
import Row from '../components/Row';
import FriendsList from '../components/pages/Friends/FriendsList';
import ClaimReward from '../components/pages/Friends/ClaimReward';
import RefreshButton from '../components/RefreshButton';
import { useTranslation } from 'react-i18next';
import { useApp } from '../contexts/AppContext';
import styled from 'styled-components';
import React from 'react';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../lib/utils';
import MakeSpiritFriendsButton from '../components/pages/Friends/Spirit/MakeSpiritFriendsButton';

const Header = styled.div`
  color: #fffefd;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.8px;
  text-transform: uppercase;
  margin: 0;
`;

const Friends = () => {
  const { friendsCount, app } = useApp();
  const { t } = useTranslation();
  const friendsWoVirtualCount = friendsCount - app.state.friendsVirtualCount;

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer vcenter>
      <MakeSpiritFriendsButton />
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Row margin="30px 0 37px" gap="40px">
          <Header>{t('friends.bestFriends')}</Header>
          <RefreshButton />
        </Row>
      </motion.div>
      {!!friendsWoVirtualCount && <ClaimReward />}
      <FriendsList />
    </PageContainer>
  );
};

export default Friends;
