import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
  width: 150px;
  height: 150px;
  width: 80px;
  height: 80px;
  margin: 0 auto 0;

  color: #efff00;
  background: ${({ disabled }) => (disabled ? 'gray' : '#00000094')};
  background: ${({ disabled }) => (disabled ? 'gray' : '#32363CA3')};

  border: 2px solid #efff00;
  border: 1px solid #efff00;
  border-radius: 62px;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const DirectionIcon = styled(({ next = false, ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="26" height="47" viewBox="0 0 26 47" fill="none">
    <path
      d="M2.18239 47C1.62246 47 1.07167 46.79 0.640246 46.37C-0.213415 45.5209 -0.213415 44.1514 0.640246 43.3114L20.7426 23.3163L1.01662 3.69546C0.162961 2.84635 0.162961 1.47681 1.01662 0.636832C1.8611 -0.212277 3.24713 -0.212277 4.09162 0.636832L25.3598 21.7915C26.2134 22.6406 26.2134 24.0102 25.3598 24.8501L3.72448 46.37C3.30224 46.79 2.74232 47 2.18239 47Z"
      fill="white"
    />
  </svg>
))`
  transform: ${({ next }) => (next ? 'none' : 'scaleX(-1)')};
`;

export const PrevButton = ({ disabled, onClick }: { disabled: boolean; onClick: () => Promise<void> }) => {
  return (
    <Container disabled={disabled} onClick={onClick}>
      <DirectionIcon />
    </Container>
  );
};

export const NextButton = ({ disabled, onClick }: { disabled?: boolean; onClick: () => void }) => {
  return (
    <Container disabled={disabled} onClick={onClick}>
      <DirectionIcon next={true} />
    </Container>
  );
};
