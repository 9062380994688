import styled from 'styled-components';
import PageContainer from '../components/Wrappers';
import { useApp } from '../contexts/AppContext';
import { doHapticFeedback } from '../lib/utils';
import { useEffectOnce } from 'react-use';
import { useEffect } from 'react';
import CaveCoins from '../components/pages/Cave/CaveCoins';

const Container = styled.div.attrs({
  'data-tip': 'page-cave',
})`
  background: black;
  margin: -320px -40px;
  width: 1080px;
  height: calc(100vh / var(--scale));
  overflow: scroll;
`;

const CaveBackground = styled.div<{ img: string }>`
  background: url(${({ img }) => img}) center -20px no-repeat;
  background-size: cover;
  width: 960px;
  height: 1660px;
  padding: 40px 0;
  margin: 0 auto;
  position: relative;
  top: calc(100vh / var(--scale) / 2 - 960px);

  .trophy {
    position: absolute;
    bottom: 829px;
    left: 371px;
  }

  .gameMachine {
    position: absolute;
    bottom: 963px;
    left: 325px;
    height: 25;
  }

  &.basic .gameMachine {
    bottom: 954px;
  }

  .fireplace {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .cave-fg {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
  }
`;

export const Cave = () => {
  const { app } = useApp();

  const { nfts } = app;

  const basicCaveImage = 'https://cdn2.timesoul.com/nft/cave/img/basic_cave_small.webp';
  const caveImage = nfts.find((item) => item.nftCollection === 'cave' && !!item.imageUrl)?.imageUrl || basicCaveImage;
  const isBasicImage = basicCaveImage === caveImage;
  const caveFg = `${caveImage.replace('.webp', '')}-fg.webp`;

  const trophyImage = nfts.find((item) => item.nftCollection === 'trophy' && !!item.imageUrl)?.imageUrl;
  const fireplaceImage = nfts.find((item) => item.nftCollection === 'fireplace' && !!item.imageUrl)?.imageUrl;
  const gameMachineImage = nfts.find((item) => item.nftCollection === 'game_machine' && !!item.imageUrl)?.imageUrl;

  useEffect(() => {
    console.debug({ nfts });
  }, [nfts]);

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer>
      <Container>
        <CaveBackground className={isBasicImage ? 'basic' : ''} img={caveImage}>
          {trophyImage && <img className="trophy" alt="trophy" src={trophyImage} />}
          {fireplaceImage && <img className="fireplace" alt="fireplace" src={fireplaceImage} />}
          {gameMachineImage && <img className="gameMachine" alt="gameMachine" src={gameMachineImage} />}
          <img className="cave-fg" alt="cave-fg" src={caveFg} />
          <CaveCoins />
        </CaveBackground>
      </Container>
    </PageContainer>
  );
};
