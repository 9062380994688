import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

const ButtonLabelInner = styled.div<{ small: boolean; active: boolean; children: React.ReactNode }>`
  color: white;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  font-size: ${({ small }) => (small ? '28px' : '32px')};
  margin-top: 10px;
  font-family: var(--font), sans-serif;
  text-transform: uppercase;
`;

const ButtonLabel = ({ active = false, children }: { active: boolean; children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  return (
    <ButtonLabelInner active={active} small={i18n.language === 'en'}>
      {children}
    </ButtonLabelInner>
  );
};

export default ButtonLabel;
