import styled from 'styled-components';

const Frame1 = styled((props) => (
  <svg width={596} height={587} viewBox="0 0 596 587" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.12}
      d="M541.46 279.449L435.15 464.519C429.05 475.149 417.74 481.709 405.48 481.739L192.05 482.209C179.79 482.239 168.46 475.719 162.31 465.119L55.1899 280.509C49.0399 269.909 49.0099 256.829 55.1199 246.209L161.43 61.1393C167.53 50.5093 178.84 43.9493 191.1 43.9193L404.53 43.4493C416.79 43.4193 428.12 49.9393 434.27 60.5393L541.39 245.139C547.54 255.739 547.57 268.819 541.46 279.439V279.449Z"
      fill="#AD8EBC"
    />
    <path
      d="M587.45 245.749L456.84 20.6486C450.69 10.0486 439.35 3.53864 427.1 3.55864L166.85 4.12864C154.59 4.15864 143.29 10.7186 137.18 21.3486L7.54001 247.019C1.44001 257.649 1.46001 270.719 7.61001 281.319L138.23 506.419C144.38 517.019 155.72 523.529 167.97 523.509L428.22 522.939C440.48 522.909 451.78 516.349 457.89 505.719L587.52 280.049C593.62 269.419 593.6 256.349 587.45 245.749ZM540.71 280.149L434.4 465.219C428.3 475.849 416.99 482.409 404.73 482.439L191.3 482.909C179.04 482.939 167.71 476.419 161.56 465.819L54.44 281.219C48.29 270.619 48.26 257.539 54.37 246.919L160.67 61.8486C166.77 51.2186 178.08 44.6586 190.34 44.6286L403.77 44.1586C416.03 44.1286 427.36 50.6486 433.51 61.2486L540.63 245.849C546.78 256.449 546.81 269.529 540.7 280.149H540.71Z"
      fill="#986EAB"
    />
    <path
      d="M167.9 526.5C154.65 526.5 142.29 519.39 135.64 507.92L5.01993 282.82C-1.65007 271.33 -1.68007 257.04 4.93993 245.52L134.57 19.8603C141.19 8.34031 153.55 1.17031 166.83 1.14031L427.08 0.570312H427.16C440.41 0.570312 452.77 7.68031 459.43 19.1503L590.05 244.25C596.72 255.74 596.75 270.03 590.13 281.55L460.5 507.22C453.88 518.74 441.52 525.91 428.24 525.94L167.99 526.51H167.91L167.9 526.5ZM427.17 6.57031H427.1L166.85 7.14031C155.7 7.16031 145.33 13.1803 139.78 22.8503L10.1399 248.51C4.58993 258.18 4.60993 270.17 10.2099 279.81L140.83 504.91C146.41 514.53 156.78 520.5 167.9 520.5H167.97L428.22 519.93C439.37 519.91 449.74 513.89 455.3 504.22L584.93 278.55C590.48 268.88 590.46 256.89 584.86 247.25L454.24 22.1603C448.66 12.5403 438.29 6.57031 427.16 6.57031H427.17ZM191.23 485.91C177.98 485.91 165.62 478.8 158.96 467.33L51.8399 282.72C45.1699 271.23 45.1399 256.94 51.7599 245.42L158.07 60.3503C164.69 48.8303 177.05 41.6603 190.33 41.6303L403.76 41.1703H403.84C417.09 41.1703 429.45 48.2803 436.11 59.7503L543.23 244.35C549.9 255.84 549.93 270.13 543.31 281.65L437 466.72C430.38 478.24 418.02 485.41 404.74 485.44L191.31 485.91H191.23ZM403.85 47.1603H403.78L190.35 47.6203C179.2 47.6403 168.83 53.6603 163.28 63.3303L56.9599 248.41C51.4099 258.08 51.4299 270.07 57.0299 279.71L164.15 464.31C169.73 473.93 180.1 479.9 191.23 479.9H191.3L404.73 479.43C415.88 479.41 426.25 473.39 431.8 463.72L538.11 278.65C543.66 268.98 543.64 256.99 538.04 247.35L430.92 62.7503C425.34 53.1303 414.97 47.1603 403.84 47.1603H403.85Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.35}
    >
      <path
        d="M142.24 17.8906C105.68 92.2306 69.12 166.571 32.57 240.911C27.34 251.541 27.66 264.061 33.42 274.421C72.8 345.211 112.19 416.011 151.57 486.801C157.38 497.241 168.07 504.021 179.99 504.811C266.61 510.601 353.23 516.381 439.85 522.171L167.99 523.501C164.1 522.781 158.49 521.281 152.42 517.931C145.58 514.151 140.98 509.581 138.25 506.411C94.69 431.381 51.16 356.351 7.62004 281.321C6.08004 278.631 3.04004 272.481 3.04004 264.101C3.04004 255.811 6.01004 249.721 7.54004 247.021C52.44 170.641 97.34 94.2606 142.24 17.8906Z"
        fill="#986EAB"
      />
    </g>
    <path
      d="M362.67 448.759C362.67 466.639 348.17 481.139 330.29 481.139C312.41 481.139 297.91 466.639 297.91 448.759C297.91 430.879 312.41 416.379 330.29 416.379C348.17 416.379 362.67 430.879 362.67 448.759Z"
      fill="#FBFF54"
    />
    <path
      d="M330.29 483.639C311.06 483.639 295.41 467.989 295.41 448.759C295.41 429.529 311.06 413.879 330.29 413.879C349.52 413.879 365.17 429.529 365.17 448.759C365.17 467.989 349.52 483.639 330.29 483.639ZM330.29 418.889C313.81 418.889 300.41 432.289 300.41 448.769C300.41 465.249 313.81 478.649 330.29 478.649C346.77 478.649 360.17 465.249 360.17 448.769C360.17 432.289 346.77 418.889 330.29 418.889Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M330.29 416.379C329.08 416.379 327.9 416.449 326.72 416.579C342.92 418.359 355.53 432.079 355.53 448.759C355.53 465.439 342.92 479.159 326.72 480.939C327.89 481.069 329.08 481.139 330.29 481.139C348.17 481.139 362.67 466.639 362.67 448.759C362.67 430.879 348.17 416.379 330.29 416.379Z"
        fill="black"
      />
    </g>
    <path
      d="M363.46 347.31C363.46 365.19 348.96 379.69 331.08 379.69C313.2 379.69 298.7 365.19 298.7 347.31C298.7 329.43 313.2 314.93 331.08 314.93C348.96 314.93 363.46 329.43 363.46 347.31Z"
      fill="#FBFF54"
    />
    <path
      d="M331.08 382.19C311.85 382.19 296.2 366.54 296.2 347.31C296.2 328.08 311.85 312.43 331.08 312.43C350.31 312.43 365.96 328.08 365.96 347.31C365.96 366.54 350.31 382.19 331.08 382.19ZM331.08 317.44C314.61 317.44 301.2 330.84 301.2 347.32C301.2 363.8 314.6 377.2 331.08 377.2C347.56 377.2 360.96 363.8 360.96 347.32C360.96 330.84 347.56 317.44 331.08 317.44Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M331.08 314.941C329.87 314.941 328.69 315.011 327.51 315.141C343.71 316.921 356.32 330.641 356.32 347.321C356.32 364.001 343.71 377.721 327.51 379.501C328.68 379.631 329.87 379.701 331.08 379.701C348.96 379.701 363.46 365.201 363.46 347.321C363.46 329.441 348.96 314.941 331.08 314.941Z"
        fill="black"
      />
    </g>
    <path
      d="M299.08 164.349C299.08 182.229 284.58 196.729 266.7 196.729C248.82 196.729 234.32 182.229 234.32 164.349C234.32 146.469 248.82 131.969 266.7 131.969C284.58 131.969 299.08 146.469 299.08 164.349Z"
      fill="#FBFF54"
    />
    <path
      d="M266.7 199.229C247.47 199.229 231.82 183.579 231.82 164.349C231.82 145.119 247.47 129.469 266.7 129.469C285.93 129.469 301.58 145.119 301.58 164.349C301.58 183.579 285.93 199.229 266.7 199.229ZM266.7 134.469C250.22 134.469 236.82 147.869 236.82 164.349C236.82 180.829 250.22 194.229 266.7 194.229C283.18 194.229 296.58 180.829 296.58 164.349C296.58 147.869 283.18 134.469 266.7 134.469Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M266.7 131.969C265.49 131.969 264.31 132.039 263.13 132.169C279.33 133.949 291.94 147.669 291.94 164.349C291.94 181.029 279.33 194.749 263.13 196.529C264.3 196.659 265.49 196.729 266.7 196.729C284.58 196.729 299.08 182.229 299.08 164.349C299.08 146.469 284.58 131.969 266.7 131.969Z"
        fill="black"
      />
    </g>
    <path
      d="M470.19 216.872C470.19 234.752 455.69 249.252 437.81 249.252C419.93 249.252 405.43 234.752 405.43 216.872C405.43 198.992 419.93 184.492 437.81 184.492C455.69 184.492 470.19 198.992 470.19 216.872Z"
      fill="#FBFF54"
    />
    <path
      d="M437.81 251.74C418.58 251.74 402.93 236.09 402.93 216.86C402.93 197.63 418.58 181.98 437.81 181.98C457.04 181.98 472.69 197.63 472.69 216.86C472.69 236.09 457.04 251.74 437.81 251.74ZM437.81 186.98C421.34 186.98 407.93 200.38 407.93 216.86C407.93 233.34 421.33 246.74 437.81 246.74C454.29 246.74 467.69 233.34 467.69 216.86C467.69 200.38 454.29 186.98 437.81 186.98Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M437.81 184.492C436.6 184.492 435.42 184.562 434.24 184.692C450.44 186.472 463.05 200.192 463.05 216.872C463.05 233.552 450.44 247.272 434.24 249.052C435.41 249.182 436.6 249.252 437.81 249.252C455.69 249.252 470.19 234.752 470.19 216.872C470.19 198.992 455.69 184.492 437.81 184.492Z"
        fill="black"
      />
    </g>
    <path
      d="M431.58 446.501C431.58 464.381 417.08 478.881 399.2 478.881C381.32 478.881 366.82 464.381 366.82 446.501C366.82 428.621 381.32 414.121 399.2 414.121C417.08 414.121 431.58 428.621 431.58 446.501Z"
      fill="#E9D647"
    />
    <path
      d="M399.2 481.381C379.97 481.381 364.32 465.731 364.32 446.501C364.32 427.271 379.97 411.621 399.2 411.621C418.43 411.621 434.08 427.271 434.08 446.501C434.08 465.731 418.43 481.381 399.2 481.381ZM399.2 416.621C382.73 416.621 369.32 430.021 369.32 446.501C369.32 462.981 382.72 476.381 399.2 476.381C415.68 476.381 429.08 462.981 429.08 446.501C429.08 430.021 415.68 416.621 399.2 416.621Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M399.2 414.121C397.99 414.121 396.81 414.191 395.63 414.321C411.83 416.101 424.44 429.821 424.44 446.501C424.44 463.181 411.83 476.901 395.63 478.681C396.8 478.811 397.99 478.881 399.2 478.881C417.08 478.881 431.58 464.381 431.58 446.501C431.58 428.621 417.08 414.121 399.2 414.121Z"
        fill="black"
      />
    </g>
    <path
      d="M294.69 449.22C294.69 467.1 280.19 481.6 262.31 481.6C244.43 481.6 229.93 467.1 229.93 449.22C229.93 431.34 244.43 416.84 262.31 416.84C280.19 416.84 294.69 431.34 294.69 449.22Z"
      fill="#986EAB"
    />
    <path
      d="M262.31 484.1C243.08 484.1 227.43 468.45 227.43 449.22C227.43 429.99 243.08 414.34 262.31 414.34C281.54 414.34 297.19 429.99 297.19 449.22C297.19 468.45 281.54 484.1 262.31 484.1ZM262.31 419.34C245.83 419.34 232.43 432.74 232.43 449.22C232.43 465.7 245.83 479.1 262.31 479.1C278.79 479.1 292.19 465.7 292.19 449.22C292.19 432.74 278.79 419.34 262.31 419.34Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M262.31 416.84C261.1 416.84 259.92 416.91 258.74 417.04C274.94 418.82 287.55 432.54 287.55 449.22C287.55 465.9 274.94 479.62 258.74 481.4C259.91 481.53 261.1 481.6 262.31 481.6C280.19 481.6 294.69 467.1 294.69 449.22C294.69 431.34 280.19 416.84 262.31 416.84Z"
        fill="black"
      />
    </g>
    <path
      d="M479.1 318.591C479.1 336.471 464.6 350.971 446.72 350.971C428.84 350.971 414.34 336.471 414.34 318.591C414.34 300.711 428.84 286.211 446.72 286.211C464.6 286.211 479.1 300.711 479.1 318.591Z"
      fill="#986EAB"
    />
    <path
      d="M446.72 353.471C427.49 353.471 411.84 337.821 411.84 318.591C411.84 299.361 427.49 283.711 446.72 283.711C465.95 283.711 481.6 299.361 481.6 318.591C481.6 337.821 465.95 353.471 446.72 353.471ZM446.72 288.711C430.25 288.711 416.84 302.111 416.84 318.591C416.84 335.071 430.24 348.471 446.72 348.471C463.2 348.471 476.6 335.071 476.6 318.591C476.6 302.111 463.2 288.711 446.72 288.711Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M446.72 286.211C445.51 286.211 444.33 286.281 443.15 286.411C459.35 288.191 471.96 301.911 471.96 318.591C471.96 335.271 459.35 348.991 443.15 350.771C444.32 350.901 445.51 350.971 446.72 350.971C464.6 350.971 479.1 336.471 479.1 318.591C479.1 300.711 464.6 286.211 446.72 286.211Z"
        fill="black"
      />
    </g>
    <path
      d="M225.55 449.22C225.55 467.1 211.05 481.6 193.17 481.6C175.29 481.6 160.79 467.1 160.79 449.22C160.79 431.34 175.29 416.84 193.17 416.84C211.05 416.84 225.55 431.34 225.55 449.22Z"
      fill="#AD8EBC"
    />
    <path
      d="M193.17 484.1C173.94 484.1 158.29 468.45 158.29 449.22C158.29 429.99 173.94 414.34 193.17 414.34C212.4 414.34 228.05 429.99 228.05 449.22C228.05 468.45 212.4 484.1 193.17 484.1ZM193.17 419.34C176.69 419.34 163.29 432.74 163.29 449.22C163.29 465.7 176.69 479.1 193.17 479.1C209.65 479.1 223.05 465.7 223.05 449.22C223.05 432.74 209.65 419.34 193.17 419.34Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M193.17 416.84C191.96 416.84 190.78 416.91 189.6 417.04C205.8 418.82 218.41 432.54 218.41 449.22C218.41 465.9 205.8 479.62 189.6 481.4C190.77 481.53 191.96 481.6 193.17 481.6C211.05 481.6 225.55 467.1 225.55 449.22C225.55 431.34 211.05 416.84 193.17 416.84Z"
        fill="black"
      />
    </g>
    <path
      d="M401.3 249.032C401.3 266.912 386.8 281.412 368.92 281.412C351.04 281.412 336.54 266.912 336.54 249.032C336.54 231.152 351.04 216.652 368.92 216.652C386.8 216.652 401.3 231.152 401.3 249.032Z"
      fill="#AD8EBC"
    />
    <path
      d="M368.92 283.901C349.69 283.901 334.04 268.251 334.04 249.021C334.04 229.791 349.69 214.141 368.92 214.141C388.15 214.141 403.8 229.791 403.8 249.021C403.8 268.251 388.15 283.901 368.92 283.901ZM368.92 219.151C352.45 219.151 339.04 232.551 339.04 249.031C339.04 265.511 352.44 278.911 368.92 278.911C385.4 278.911 398.8 265.511 398.8 249.031C398.8 232.551 385.4 219.151 368.92 219.151Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M368.92 216.652C367.71 216.652 366.53 216.722 365.35 216.852C381.55 218.632 394.16 232.352 394.16 249.032C394.16 265.712 381.55 279.432 365.35 281.212C366.52 281.342 367.71 281.412 368.92 281.412C386.8 281.412 401.3 266.912 401.3 249.032C401.3 231.152 386.8 216.652 368.92 216.652Z"
        fill="black"
      />
    </g>
    <path
      d="M236.74 278.22C236.74 296.1 222.24 310.6 204.36 310.6C186.48 310.6 171.98 296.1 171.98 278.22C171.98 260.34 186.48 245.84 204.36 245.84C222.24 245.84 236.74 260.34 236.74 278.22Z"
      fill="#AD8EBC"
    />
    <path
      d="M204.36 313.088C185.13 313.088 169.48 297.438 169.48 278.208C169.48 258.978 185.13 243.328 204.36 243.328C223.59 243.328 239.24 258.978 239.24 278.208C239.24 297.438 223.59 313.088 204.36 313.088ZM204.36 248.338C187.88 248.338 174.48 261.738 174.48 278.218C174.48 294.698 187.88 308.098 204.36 308.098C220.84 308.098 234.24 294.698 234.24 278.218C234.24 261.738 220.84 248.338 204.36 248.338Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M204.36 245.84C203.15 245.84 201.97 245.91 200.79 246.04C216.99 247.82 229.6 261.54 229.6 278.22C229.6 294.9 216.99 308.62 200.79 310.4C201.96 310.53 203.15 310.6 204.36 310.6C222.24 310.6 236.74 296.1 236.74 278.22C236.74 260.34 222.24 245.84 204.36 245.84Z"
        fill="black"
      />
    </g>
    <path d="M309.228 271.657L288.548 257.539L89.1887 549.57L109.869 563.688L309.228 271.657Z" fill="#FBFF54" />
    <path
      d="M109.82 566.183C109.32 566.183 108.83 566.033 108.41 565.743L87.7301 551.623C86.5901 550.843 86.3001 549.293 87.0801 548.153L286.46 256.133C287.24 254.993 288.8 254.703 289.93 255.473L310.61 269.593C311.16 269.963 311.53 270.543 311.66 271.193C311.78 271.843 311.64 272.523 311.27 273.063L111.89 565.093C111.52 565.643 110.94 566.013 110.29 566.143C110.14 566.173 109.98 566.183 109.83 566.183H109.82ZM92.6201 548.913L109.17 560.213L305.73 272.323L289.18 261.023L92.6201 548.903V548.913Z"
      fill="black"
    />
    <path d="M487.25 563.653L507.931 549.535L308.572 257.505L287.891 271.623L487.25 563.653Z" fill="#FBFF54" />
    <path
      d="M487.26 566.181C487.11 566.181 486.95 566.171 486.8 566.141C486.15 566.021 485.57 565.641 485.2 565.091L285.82 273.071C285.45 272.521 285.31 271.851 285.43 271.201C285.55 270.551 285.93 269.971 286.48 269.601L307.16 255.481C308.3 254.701 309.85 254.991 310.63 256.131L510.01 548.151C510.79 549.291 510.5 550.851 509.36 551.621L488.68 565.741C488.26 566.031 487.77 566.181 487.27 566.181H487.26ZM291.36 272.311L487.92 560.201L504.47 548.901L307.91 261.011L291.36 272.311Z"
      fill="black"
    />
    <path
      d="M298.85 281.55C308.592 281.55 316.49 273.652 316.49 263.91C316.49 254.167 308.592 246.27 298.85 246.27C289.108 246.27 281.21 254.167 281.21 263.91C281.21 273.652 289.108 281.55 298.85 281.55Z"
      fill="#AD8EBC"
    />
    <path
      d="M298.85 284.061C287.74 284.061 278.71 275.021 278.71 263.921C278.71 252.821 287.75 243.781 298.85 243.781C309.95 243.781 318.99 252.821 318.99 263.921C318.99 275.021 309.95 284.061 298.85 284.061ZM298.85 248.771C290.5 248.771 283.71 255.561 283.71 263.911C283.71 272.261 290.5 279.051 298.85 279.051C307.2 279.051 313.99 272.261 313.99 263.911C313.99 255.561 307.2 248.771 298.85 248.771Z"
      fill="black"
    />
    <path
      d="M110.64 549.84H41.45C36.3856 549.84 32.28 553.945 32.28 559.01V575.12C32.28 580.184 36.3856 584.29 41.45 584.29H110.64C115.704 584.29 119.81 580.184 119.81 575.12V559.01C119.81 553.945 115.704 549.84 110.64 549.84Z"
      fill="#E9D647"
    />
    <path
      d="M110.64 586.79H41.45C35.01 586.79 29.78 581.55 29.78 575.12V559.01C29.78 552.58 35.01 547.34 41.45 547.34H110.64C117.08 547.34 122.31 552.58 122.31 559.01V575.12C122.31 581.55 117.08 586.79 110.64 586.79ZM41.45 552.34C37.77 552.34 34.78 555.33 34.78 559.01V575.12C34.78 578.8 37.77 581.79 41.45 581.79H110.64C114.32 581.79 117.31 578.8 117.31 575.12V559.01C117.31 555.33 114.32 552.34 110.64 552.34H41.45Z"
      fill="black"
    />
    <path
      d="M557.84 549.84H488.65C483.586 549.84 479.48 553.945 479.48 559.01V575.12C479.48 580.184 483.586 584.29 488.65 584.29H557.84C562.904 584.29 567.01 580.184 567.01 575.12V559.01C567.01 553.945 562.904 549.84 557.84 549.84Z"
      fill="#E9D647"
    />
    <path
      d="M557.83 586.79H488.64C482.21 586.79 476.97 581.55 476.97 575.12V559.01C476.97 552.58 482.21 547.34 488.64 547.34H557.83C564.26 547.34 569.5 552.58 569.5 559.01V575.12C569.5 581.55 564.26 586.79 557.83 586.79ZM488.64 552.34C484.96 552.34 481.97 555.33 481.97 559.01V575.12C481.97 578.8 484.96 581.79 488.64 581.79H557.83C561.51 581.79 564.5 578.8 564.5 575.12V559.01C564.5 555.33 561.51 552.34 557.83 552.34H488.64Z"
      fill="black"
    />
    <path
      d="M455.616 42.1143C453.589 38.6036 449.1 37.4008 445.59 39.4277C442.079 41.4545 440.876 45.9436 442.903 49.4543L523.093 188.347C525.12 191.858 529.609 193.061 533.12 191.034C536.63 189.007 537.833 184.518 535.806 181.007L455.616 42.1143Z"
      fill="white"
    />
    <path
      d="M548.218 201.739C546.191 198.229 541.702 197.026 538.191 199.053C534.681 201.08 533.478 205.569 535.505 209.079L563.245 257.126C565.272 260.637 569.761 261.84 573.271 259.813C576.782 257.786 577.985 253.297 575.958 249.786L548.218 201.739Z"
      fill="white"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M287.37 279.91L104.97 548.71L89.1499 549.56L280.03 269.18L287.37 279.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M309.39 279.91L492.92 548.15L482.19 553.23L296.69 283.58L309.39 279.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path
        d="M32.1198 567.059H119.37V577.509L110.64 584.289H39.7498L31.5598 577.229L32.1198 567.059Z"
        fill="#E9D647"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M478.8 567.059H566.05V577.509L557.32 584.289H486.43L478.24 577.229L478.8 567.059Z" fill="#E9D647" />
    </g>
  </svg>
))`
  left: calc((621px - 596px) / 2);
`;

export default Frame1;
