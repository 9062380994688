import { useTranslation } from 'react-i18next';

import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { IconLottery } from '../../Icons/IconLottery';

const Lottery = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer width={112}>
      <IconContainer active={active}>
        <IconLottery />
      </IconContainer>
      <ButtonLabel active={active}>{t('menu.lottery')}</ButtonLabel>
    </ButtonContainer>
  );
};

export default Lottery;
