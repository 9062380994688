import styled from 'styled-components';

import MenuMain from './MenuMain';
import MenuTop from './MenuTop';
import { useLocation } from 'react-router-dom';
import { MenuWorld } from './MenuWorld';

const FixedContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Menu = () => {
  const location = useLocation();
  const isWorld = location.pathname.indexOf('world') !== -1;
  const isLottery = location.pathname === '/lottery';

  console.log(location);

  if (location.pathname === '/welcome') {
    return false;
  }
  return (
    <FixedContainer>
      {!isWorld && !isLottery ? <MenuTop /> : <></>}
      {isWorld ? <MenuWorld /> : <></>}
      <MenuMain />
    </FixedContainer>
  );
};

export default Menu;
