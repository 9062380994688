import ButtonContainer from './ButtonContainer';
import IconContainer from './IconContainer';
import { ImgOverlay } from './ImgOverlay';
import { IconReserveClosed } from '../../Icons/IconReserveClosed';

export const Icon = () => (
  <img alt="book-nft-fireplace" src="/img/nft/nfts-fireplace-thumb.webp" style={{ width: '120px' }} />
);

export const MenuBookFireplace = ({ active = false }) => {
  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <Icon />
        <ImgOverlay>
            <IconReserveClosed />
          </ImgOverlay>
      </IconContainer>
    </ButtonContainer>
  );
};
