const {
  VITE_API_BACK_URL: backUrl,
  VITE_TG_CHANNEL_NAME: channelName,
  VITE_TG_CHANNEL_TASK: channelTask,
  VITE_TASK_JOIN_MYSTER_CAVE: joinMysteryCaveTask,
  VITE_TG_BOT_URL: botUrl,
  VITE_USE_MOCKS: _useMocks,
  VITE_SENTRY_DSN: sentryDsn,
  VITE_DEVELOPERS: developersString,
  VITE_WALLET: wallet,
  VITE_BUILD: build,
  VITE_VERCEL_ENV: vercelEnv,
  PROD: isProd,
} = import.meta.env;

if (!backUrl) throw new Error('missing VITE_API_BACK_URL');
if (!channelName) throw new Error('missing VITE_TG_CHANNEL_NAME');
if (!joinMysteryCaveTask) throw new Error('missing VITE_TASK_JOIN_MYSTER_CAVE');
if (!botUrl) throw new Error('missing VITE_TG_BOT_URL');
if (!sentryDsn) throw new Error('missing VITE_SENTRY_DSN');
if (!developersString) throw new Error('missing VITE_DEVELOPERS');
if (!wallet) throw new Error('missing VITE_WALLET');
if (!build) throw new Error('missing VITE_BUILD');
const developers = developersString.split(',');
const isVercel = !!vercelEnv;
const useMocks = _useMocks === 'true';

export const env = {
  isVercel,
  isProd,
  backUrl,
  channelName,
  channelTask,
  joinMysteryCaveTask,
  botUrl,
  useMocks,
  sentryDsn,
  developers,
  wallet,
  build,
};
