import { IBoostLog, INFT } from '../types';
 
interface BoostSpeedLevel {
  level: number;
  speed: number; // 22 token/sec
}

interface BoostTurboLevel {
  level: number;
  duration: number; // sec
  multiplier: number; // 2
}

class ActionPoint {
  at: number; // timestamp
  balance: number = 0; // calculated at the moment
  turbo_mul: number = 1;
  speed: number = 0;

  constructor(at: number, balance: number) {
    this.at = at;
    this.balance = balance;
  }
}

class Calculator {
  public debug: boolean = false;

  public round_balance: number = 0;
  public round_boost_logs: IBoostLog[] = []; // !

  public round_started_at: number = 0; // timestamp
  public round_finish_at: number = 0; // timestamp
  public last_action_point: ActionPoint = new ActionPoint(0, 0); // !

  public turbo_boosts_available: number = 3;
  public turbo_boosts_activated: IBoostLog[] = [];
  public active_turbo_till: number = 0; // unix timestamp (sec)

  public StateManager = {
    boostLogs: <IBoostLog[]>[],
    user_state: {
      turboLevel: 0,
      speedLevel: 0,
    },
    game_config: {
      speed_levels: <BoostSpeedLevel[]>[],
      turbo_levels: <BoostTurboLevel[]>[],
    },
    nfts: <INFT[]>[]
  };

  public init() {
    this.round_boost_logs = [...this.StateManager.boostLogs];
  }

  // вызывается по таймеру или на каждом кадре
  public upd_round_balance = (time_at: number) => {
    // bugs
    // console.log({ time_at }, this.active_turbo_till);

    const isTurbo = this.active_turbo_till > 0 ? this.active_turbo_till > time_at : false;

    if (isTurbo) {
      this.round_balance =
        this.last_action_point.balance +
        this.last_action_point.speed * this.last_action_point.turbo_mul * (time_at - this.last_action_point.at);
    } else {
      this.round_balance =
        this.last_action_point.balance + this.last_action_point.speed * (time_at - this.last_action_point.at);
    }
  };

  // вызывается при старте или при тапе или при открытии экрана
  // или по событию деактивации турбо
  public upd_last_action_point(time_at: number) {
    this.__upd_turbo_boosts_activated();

    let speedByNFTs: number = 0;
    this.StateManager.nfts.forEach((n: INFT) => {
      speedByNFTs += n.extra.speed;
    })
    console.log({ speedByNFTs });

    let round_turbo_lvl = this.StateManager.user_state.turboLevel;
    let round_speed_lvl = this.StateManager.user_state.speedLevel;

    this.last_action_point = new ActionPoint(this.round_started_at, 0);
    this.last_action_point.at = this.round_started_at;

    let activated_turbo_at = -1;
    let activated_turbo_ends_at = -1;

    // bugs
    // console.log('user_state', this.StateManager.user_state);
    // console.log('turboLevel', this.StateManager.user_state.turboLevel);
    // console.log('speedLevel', this.StateManager.user_state.speedLevel);
    // console.log('round_speed_lvl', round_speed_lvl);

    for (const boostLog of this.StateManager.boostLogs) {
      // const boostLog = b;
      if (boostLog.action === 'u') {
        if (boostLog.boost === 't') {
          round_turbo_lvl -= 1;
        } else if (boostLog.boost === 's') {
          round_speed_lvl -= 1;
        }
      }
    }

    // bugs
    // console.log('round_speed_lvl', round_speed_lvl);

    let speed_lvl = this.__filter_by_level(
      this.StateManager.game_config.speed_levels,
      round_speed_lvl
    ) as BoostSpeedLevel;

    if (!speed_lvl) {
      return;
    }

    this.last_action_point.speed = speed_lvl.speed + speedByNFTs;

    let turbo_lvl = this.__filter_by_level(
      this.StateManager.game_config.turbo_levels,
      round_turbo_lvl
    ) as BoostTurboLevel;

    if (!turbo_lvl) {
      return;
    }

    let i = -1;

    while (true) {
      i += 1;

      if (i >= this.round_boost_logs.length) {
        break;
      }

      const boostLog = this.round_boost_logs[i];
      const at = boostLog.createdAt;

      // console.log('Activate turbo >>>>> ', at, time_at);

      if (at > time_at) {
        break;
      }

      this.last_action_point.balance +=
        this.last_action_point.speed *
        this.last_action_point.turbo_mul *
        (boostLog.createdAt - this.last_action_point.at);
      this.last_action_point.at = boostLog.createdAt;

      // Upgrades
      const isUpgrade = boostLog.action === 'u';
      const isActivate = boostLog.action === 'a';
      const isDeactivate = boostLog.action === 'd';

      // Upgrade turbo | speed
      if (isUpgrade) {
        if (boostLog.boost === 't') {
          round_turbo_lvl += 1;
          this.log(`TURBO UPG: ROUND TURBO LVL ${round_turbo_lvl}, ${turbo_lvl.duration}`);
          turbo_lvl = this.__filter_by_level(
            this.StateManager.game_config.turbo_levels,
            round_turbo_lvl
          ) as BoostTurboLevel;

          if (activated_turbo_ends_at > boostLog.createdAt) {
            activated_turbo_ends_at = activated_turbo_at + turbo_lvl.duration;

            // extra
            this.log(`EXTRA TURBO END AT: ${activated_turbo_ends_at}`);

            const bl_turbo_end: IBoostLog = {
              boost: 't',
              action: 'd',
              atLevel: -1,
              createdAt: activated_turbo_ends_at,
            };

            if (this.active_turbo_till < activated_turbo_ends_at) {
              this.active_turbo_till = activated_turbo_ends_at;
            }

            this.round_boost_logs = this.__insert_boost_log_respect_at(this.round_boost_logs, bl_turbo_end);
          }
        } else if (boostLog.boost === 's') {
          round_speed_lvl += 1;
          speed_lvl = this.__filter_by_level(
            this.StateManager.game_config.speed_levels,
            round_speed_lvl
          ) as BoostSpeedLevel;

          // todo: possible bugs
          // console.log(this.StateManager.game_config.speed_levels);
          // console.log({ speed_lvl, round_speed_lvl });

          console.log(this.last_action_point);

          this.last_action_point.speed = speed_lvl.speed + speedByNFTs;
        }
      }

      // Activate turbo
      else if (isActivate && boostLog.boost === 't') {
        this.last_action_point.turbo_mul = turbo_lvl.multiplier;

        const turbo_ends_at = boostLog.createdAt + turbo_lvl.duration;

        // console.log('Activate turbo >>>>> ', { turbo_ends_at, boostLog, turbo_lvl });

        if (turbo_ends_at >= this.round_finish_at) {
          this.log('BOOST IN THE END OF ROUND');
          this.active_turbo_till = this.round_finish_at;

          continue;
        }

        activated_turbo_at = boostLog.createdAt;
        activated_turbo_ends_at = turbo_ends_at;

        if (activated_turbo_ends_at >= this.active_turbo_till) {
          this.active_turbo_till = activated_turbo_ends_at;
        }

        const bl_turbo_end: IBoostLog = {
          boost: 't',
          action: 'd',
          atLevel: -1,
          createdAt: activated_turbo_ends_at,
        };
        this.round_boost_logs = this.__insert_boost_log_respect_at(this.round_boost_logs, bl_turbo_end);

        this.log(`CALC: INSERTED TURBO DEACTIVATON: ${bl_turbo_end.createdAt}`);
      }

      // Deactivate turbo
      else if (isDeactivate && boostLog.boost === 't') {
        if (boostLog.createdAt < activated_turbo_ends_at) {
          continue;
        }

        this.log(`CALC: DEACTIVATED TURBO: ${boostLog.createdAt}`);
        this.last_action_point.turbo_mul = 1;
        activated_turbo_ends_at = -1;
        activated_turbo_at = -1;
      }
    }

    if (this.active_turbo_till <= time_at) {
      this.active_turbo_till = 0;
    }

    this.log(`RUNNING UNTILL: ${this.active_turbo_till})`);
    this.log(`ROUND UNTILL: ${this.round_finish_at})`);
  }

  // вставляет буст лог сохраняя упорядоченность по времени
  private __insert_boost_log_respect_at(boostLogs: IBoostLog[], boost_log_needed: IBoostLog): IBoostLog[] {
    let is_inserted = false;

    for (let i = 0; i < boostLogs.length; i++) {
      const boostLog = boostLogs[i];

      if (boostLog.createdAt > boost_log_needed.createdAt) {
        boostLogs.splice(i, 0, boost_log_needed);

        is_inserted = true;
        break;
      }
    }

    if (!is_inserted) {
      boostLogs.push(boost_log_needed);
    }

    this.log('DEBUG boostLogs:', boostLogs);

    return boostLogs;
  }

  // возвращаем буст конкретного уровня или null
  private __filter_by_level(
    items: BoostTurboLevel[] | BoostSpeedLevel[],
    lvl: number
  ): BoostTurboLevel | BoostSpeedLevel | undefined {
    // for (const item of items) {
    //   if (item.level === lvl) {
    //     return item;
    //   }
    // }

    return items.find((item) => item.level === lvl);
    // return level ? level : null;
  }
  // обновляет this.turbo_boosts_activated
  private __upd_turbo_boosts_activated() {
    // let boostLogs = this.StateManager.boostLogs;
    // let turbo_boosts_activated = [] as IBoostLog[]; // DESC

    // for (const b of this.StateManager.boostLogs) {
    //   // только turbo может быть activated
    //   if (b.action === 'a') {
    //     this.turbo_boosts_activated.push(b);
    //   }
    // }

    this.turbo_boosts_activated = this.StateManager.boostLogs.filter((b) => b.action === 'a');

    console.log({
      boostLogs: this.StateManager.boostLogs,
      turbo_boosts_activated: this.turbo_boosts_activated,
    });

    // this.turbo_boosts_activated = turbo_boosts_activated;
    this.turbo_boosts_available = Math.max(0, 3 - this.turbo_boosts_activated.length);
  }

  private log(...message: any) {
    if (this.debug) console.log(message);
  }
}

export default Calculator;
