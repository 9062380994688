import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { useTranslation } from 'react-i18next';

export const Icon = () => (
  <svg width="85" height="111" viewBox="0 0 85 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.0347 2H46.1562C46.8496 2 47.3943 2.54474 47.3943 3.23804V109.759H36.8462V3.23804C36.8462 2.54474 37.3909 2 38.0842 2H38.0347Z"
      fill="#B48EC0"
      stroke="#303030"
      strokeWidth="2.3328"
      strokeLinejoin="round"
    />
    <path d="M38.1338 42.0628L46.5524 32.6042V29.1377H38.1338V42.0628Z" fill="#A579B2" />
    <path
      d="M59.6755 37.3584L60.3688 33.7433L4.31043 27.355C2.87431 27.2065 1.83435 26.0675 2.08196 24.8294L4.65708 9.97297C4.85517 8.73493 6.24177 7.89307 7.6779 8.04163L63.7858 14.4299L64.4791 10.8644C64.6277 10.0225 65.8657 9.72536 66.559 10.3196L82.4059 24.6314C83.4954 25.6218 83.2478 27.256 81.8117 27.9493L61.5079 38.2498C60.6165 38.6955 59.5765 38.1012 59.7251 37.2593L59.6755 37.3584Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="2.592"
      strokeLinejoin="round"
    />
    <path d="M38.1338 78.7584L46.5524 69.2998V65.8333H38.1338V78.7584Z" fill="#A579B2" />
    <path
      d="M8.42077 9.72547C7.18273 9.57691 5.9942 10.3197 5.79611 11.3597L3.56764 24.1858C3.36955 25.2257 4.26095 26.2161 5.49899 26.3647L37.9356 30.0788L38.0347 13.0434L8.37123 9.72547H8.42077Z"
      fill="#EDD500"
    />
    <path
      d="M25.4066 74.8462L24.7133 71.2311L80.7717 64.8428C82.2078 64.6943 83.2478 63.5553 83.0002 62.3172L80.4251 47.4608C80.227 46.2227 78.8404 45.3809 77.4042 45.5294L21.2963 51.9177L20.603 48.3522C20.4544 47.5103 19.2164 47.2132 18.5231 47.8074L2.6762 62.1191C1.58672 63.1096 1.83432 64.7438 3.27045 65.4371L23.5743 75.7376C24.4657 76.1833 25.5056 75.589 25.3571 74.7471L25.4066 74.8462Z"
      fill="#FAFF00"
      stroke="#303030"
      strokeWidth="2.592"
      strokeLinejoin="round"
    />
    <path
      d="M3.61733 64.2981C3.22115 64.1 3.12212 63.5553 3.46877 63.2086L19.4147 48.7979L20.4051 53.4529L38.7281 51.2739V68.3093L23.0793 70.1912L24.1192 74.549L3.61733 64.2486V64.2981Z"
      fill="#EDD500"
    />
  </svg>
);

const Way = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer width={80}>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
      <ButtonLabel active={active}>{t('menu.way')}</ButtonLabel>
    </ButtonContainer>
  );
};

export default Way;
