import React from 'react';
import styled from 'styled-components';
import { ILottery } from '../../../lib/types/lottery';
import CountDown from './CountDown';

const Container = styled.div`
  display: flex;
  margin: 20px auto;
  align-items: center;

  font-family: Krungthep, sans-serif;
  font-size: 64px;
  justify-content: center;
  color: rgb(232,232,232);
`;

const getNextDrawTime = (lotteries: ILottery[]) => {
  const activeLottery = getActiveLottery(lotteries);
  if (activeLottery) return new Date(activeLottery.drawDate);
  const nextLottery = getNextLottery(lotteries);
  return nextLottery ? new Date(nextLottery.drawDate) : null;
};

const isFinished = (lottery: ILottery) => new Date(lottery.drawDate).getTime() <= Date.now();

const getActiveLottery = (lotteries: ILottery[]) =>
  lotteries.find((lottery) => lottery.isActive && !isFinished(lottery));

const getNextLottery = (lotteries: ILottery[]) =>
  lotteries
    .sort((a, b) => new Date(a.drawDate).getTime() - new Date(b.drawDate).getTime())
    .find((lottery) => !isFinished(lottery));

const NextDrawTime = ({ lotteries }: { lotteries: ILottery[] }) => {
  const time = getNextDrawTime(lotteries);
  if (!time) return null;

  return (
    <Container>
      <CountDown eventTime={time} />
    </Container>
  );
};

export default NextDrawTime;
