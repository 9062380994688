import React from 'react';
import styled from 'styled-components';
import Row from '../../Row';
import { ILottery } from '../../../lib/types/lottery';
import { shortenWallet } from '../../../lib/utils';

const Container = styled.div`
  height: 130px;
  width: 770px;

  border-radius: 105px;
  border: 1.5px solid rgba(235, 235, 235, 0.61);
  background: rgba(50, 54, 60, 0.64);

  padding: 0 60px;
  display: flex;
  margin: 10px auto;
  align-items: center;
`;

const Name = styled.div`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 32px;
`;

const Reward = styled.div`
  font-size: 40px;
  color: white;
`;

const Wallet = styled.div`
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 40px;
`;

const WinnerCard = ({ lottery }: { lottery: ILottery }) => {
  return (
    <Container>
      <Row spread={true} style={{ width: '100%' }}>
        <div>
          <Name>{lottery.drawDate.replace(/-/g, '.')}</Name>
          <Wallet>{!!lottery.wallet && shortenWallet(lottery.wallet)}</Wallet>
        </div>
        <Row>{!!lottery.rewardMicroton && <Reward>{lottery.rewardMicroton / 1000000} TON</Reward>}</Row>
      </Row>
    </Container>
  );
};

export default WinnerCard;
