import styled from 'styled-components';

const ButtonContainer = styled.div<{ width?: number }>`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 100px;
  width: ${({ width }) => (width ? `${width}px` : '')};
`;

export default ButtonContainer;
