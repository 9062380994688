import { useTranslation } from 'react-i18next';

import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { IconSurpriseNotNew } from '../../Icons/IconSurpriseNotNew';

const Airdrop = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <IconSurpriseNotNew />
      </IconContainer>
      <ButtonLabel active={active}>{t('menu.airdrop')}</ButtonLabel>
    </ButtonContainer>
  );
};

export default Airdrop;
