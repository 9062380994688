import React, { useEffect, useState } from 'react';
import Row from '../components/Row';
import styled from 'styled-components';
import { doHapticFeedback } from '../lib/utils';
import { NextButton, PrevButton } from '../components/DirectionButtons';
import { useTranslation } from 'react-i18next';
import { getUserWonLotteries, useLotteryWinners } from '../lib/api';
import Loading from '../components/Loading';
import PageContainer from '../components/Wrappers';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { LeaderboardTypeButton } from '../components/pages/Flocks/Buttons';
import { ILottery, Winner } from '../lib/types/lottery';
import WinnerCard from '../components/pages/Lotteries/WinnerCard';
import WonCard from '../components/pages/Lotteries/WonCard';
import { useApp } from '../contexts/AppContext';

const LotteryDate = styled.div`
  color: #faff00;
  font-family: Krungthep, sans-serif;
  font-size: 56px;
  font-weight: 300;
  line-height: 64px;
  letter-spacing: 0.05em;
  text-align: center;
`;

const Lotteries: React.FC = () => {
  const { app } = useApp();
  const [observedLottery, setObservedLottery] = useState(0);
  const [section, setSection] = useState<'mine' | 'other'>('other');
  const [lotteries, setLotteries] = useState<ILottery[]>([]);
  const { t } = useTranslation();
  const { wallet } = app.state;

  const uuid = section === 'mine' ? null : lotteries.length ? lotteries[observedLottery].uuid : null;
  const { data, isLoading } = useLotteryWinners(uuid);

  const handlePrev = async () => {
    if (observedLottery === 0) return;
    setObservedLottery((prevObservedLottery) => prevObservedLottery - 1);
  };

  const handleNext = async () => {
    if (observedLottery + 1 === lotteries.length) return;
    setObservedLottery((prevObservedLottery) => prevObservedLottery + 1);
  };

  const prevBlocked = observedLottery === 0;
  const nextBlocked = observedLottery === lotteries.length - 1;

  useEffectOnce(() => {
    doHapticFeedback();
  });

  useEffect(() => {
    (async () => {
      const { lotteries } = section === 'mine' ? await getUserWonLotteries() : { lotteries: app.lotteries.all };
      const lotteriesToShow = section === 'mine' ? lotteries : lotteries.filter((lottery) => !lottery.isActive);
      setLotteries(lotteriesToShow.reverse());
      setObservedLottery(lotteriesToShow.length - 1);
    })();
  }, [section]);

  return (
    <PageContainer hcenter={false}>
      <Row margin={'-20px 0 20px'} gap="20px">
        <LeaderboardTypeButton
          onClick={() => {
            doHapticFeedback();
            setLotteries([]);
            setSection('mine');
          }}
          disabled={section === 'mine' || !wallet}
        >
          {t('lottery.button.myWins')}
        </LeaderboardTypeButton>
        <LeaderboardTypeButton
          onClick={() => {
            doHapticFeedback();
            setLotteries([]);
            setSection('other');
          }}
          disabled={section === 'other'}
        >
          {t('lottery.button.other')}
        </LeaderboardTypeButton>
      </Row>
      {!!lotteries.length && section === 'other' && (
        <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
          <Row margin={'120px 40px 90px'}>
            <PrevButton onClick={handlePrev} disabled={prevBlocked} />
            <LotteryDate>{lotteries[observedLottery].drawDate.replace(/-/g, '.')}</LotteryDate>
            <NextButton onClick={handleNext} disabled={isLoading || nextBlocked} />
          </Row>
        </motion.div>
      )}
      {isLoading && <Loading fontSize="100px" />}
      {!isLoading && section === 'other' && (
        <div style={{ maxHeight: '718px', overflow: 'scroll' }}>
          {data.map((winner: Winner) => (
            <WinnerCard key={winner.id} winner={winner} />
          ))}
        </div>
      )}
      {!!lotteries.length && section === 'mine' && (
        <div style={{ maxHeight: '718px', overflow: 'scroll' }}>
          {lotteries.map((lottery: ILottery) => (
            <WonCard key={lottery.uuid} lottery={lottery} />
          ))}
        </div>
      )}
    </PageContainer>
  );
};

export default Lotteries;
