import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Popup from '../PopupOverlay';
import { isPurchasedEvent } from '../../../lib/events';
import React from 'react';
import { useApp } from '../../../contexts/AppContext';
import Row from '../../Row';
import { IconFireworksStarA } from '../../Icons/IconFireworksStarA';
import { IconFireworksStarC } from '../../Icons/IconFireworksStarC';
import { IconFireworksStarB } from '../../Icons/IconFireworksStarB';

const Container = styled.div`
  @keyframes pulseFireworksStar {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.1;
    }
  }

  .fireworksStarA,
  .fireworksStarB,
  .fireworksStarC {
    position: absolute;
    right: -80px;
    top: -300px;
    width: 400px;
    height: auto;

    animation: pulseFireworksStar 5s infinite;

    &:first-of-type {
      width: 250px;
      left: -100px;
      top: 180px;
      animation: pulseFireworksStar 7s infinite;
    }

    &:nth-of-type(3) {
      width: 350px;
      right: -150px;
      top: 150px;
      animation: pulseFireworksStar 9s infinite;
    }
  }
`;

const Header = styled.div`
  font-family: var(--font), sans-serif;
  font-size: 59px;
  font-weight: 400;
  line-height: 75.11px;
  text-align: center;
  text-transform: uppercase;
`;

const Text = styled.div`
  width: 630px;

  font-family: Roboto, sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 58.59px;
  text-align: center;
`;

const Purchased = () => {
  const { event } = useApp();
  const { t } = useTranslation();

  if (!isPurchasedEvent(event)) throw new Error('Should not happen');

  return (
    <Popup hideClose={true}>
      <Container>
        <Header>{t('friends.spirit.congratulations')}</Header>
        <Row margin="0 40px 40px">
          <img alt="spirit" src={`/img/friends/spirit_x${event.count}.png`} />
        </Row>
        <IconFireworksStarA />
        <IconFireworksStarC />
        <IconFireworksStarB />
        <Text>{t('friends.spirit.youBought', { count: event.count })}</Text>
      </Container>
    </Popup>
  );
};

export default Purchased;
