import styled from 'styled-components';

const Frame3 = styled((props) => (
  <svg width={578} height={613} viewBox="0 0 578 613" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.12}
      d="M127.46 105.798L333.5 50.1078C345.33 46.9078 357.97 50.2678 366.65 58.9178L517.9 209.508C526.58 218.158 530 230.778 526.85 242.618L472.06 448.898C468.91 460.738 459.69 470.008 447.86 473.208L241.82 528.898C229.99 532.098 217.35 528.738 208.67 520.088L57.42 369.498C48.74 360.848 45.32 348.228 48.47 336.388L103.26 130.108C106.41 118.268 115.63 108.998 127.46 105.798Z"
      fill="#AD8EBC"
    />
    <path
      d="M71.1102 97.1087L4.30012 348.639C1.15012 360.479 4.57007 373.109 13.2501 381.749L197.67 565.369C206.35 574.019 218.99 577.369 230.82 574.179L482.05 506.269C493.88 503.069 503.11 493.809 506.25 481.959L573.06 230.429C576.21 218.589 572.79 205.959 564.11 197.319L379.69 13.6988C371.01 5.04875 358.37 1.69875 346.54 4.88875L95.3101 72.7887C83.4801 75.9887 74.2502 85.2487 71.1102 97.0987V97.1087ZM128.49 105.839L334.53 50.1488C346.36 46.9487 359 50.3087 367.68 58.9587L518.93 209.549C527.61 218.199 531.03 230.819 527.88 242.659L473.09 448.939C469.94 460.779 460.72 470.049 448.89 473.249L242.85 528.939C231.02 532.139 218.38 528.779 209.7 520.129L58.45 369.539C49.77 360.889 46.3501 348.269 49.5001 336.429L104.29 130.149C107.44 118.309 116.66 109.039 128.49 105.839Z"
      fill="#986EAB"
    />
    <path
      d="M221.92 578.358C212.17 578.358 202.62 574.538 195.55 567.498L11.1298 383.878C1.71978 374.508 -2.0102 360.708 1.3998 347.868L68.2099 96.3384C71.6199 83.4984 81.6999 73.3685 94.5299 69.9085L345.76 2.00845C358.59 -1.45155 372.4 2.20846 381.81 11.5885L566.23 195.208C575.64 204.578 579.37 218.378 575.96 231.218L509.15 482.748C505.74 495.588 495.66 505.718 482.83 509.178L231.6 577.078C228.41 577.938 225.15 578.358 221.91 578.358H221.92ZM355.45 6.70845C352.74 6.70845 350 7.05845 347.32 7.78845L96.09 75.6885C85.33 78.5985 76.8699 87.0984 73.9999 97.8685L7.18984 349.398C4.32984 360.168 7.46001 371.748 15.36 379.618L199.78 563.238C207.68 571.108 219.27 574.188 230.03 571.279L481.26 503.378C492.02 500.468 500.48 491.968 503.35 481.198L570.16 229.668C573.02 218.898 569.89 207.318 561.99 199.448L377.57 15.8285C371.64 9.91846 363.63 6.71845 355.45 6.71845V6.70845ZM233.94 533.098C224.19 533.098 214.64 529.278 207.57 522.238L56.32 371.648C46.91 362.278 43.18 348.478 46.59 335.638L101.38 129.358C104.79 116.518 114.88 106.388 127.7 102.928L333.74 47.2385C346.57 43.7785 360.38 47.4385 369.79 56.8185L521.04 207.408C530.46 216.778 534.18 230.578 530.77 243.418L475.98 449.698C472.57 462.538 462.48 472.668 449.66 476.128L243.62 531.818C240.43 532.678 237.17 533.098 233.94 533.098ZM343.43 51.9585C340.72 51.9585 337.98 52.3085 335.3 53.0385L129.26 108.728C118.5 111.638 110.04 120.138 107.17 130.908L52.3798 337.188C49.5198 347.958 52.6499 359.538 60.5499 367.408L211.8 517.998C219.7 525.868 231.29 528.938 242.05 526.038L448.09 470.348C458.85 467.438 467.31 458.938 470.18 448.168L524.97 241.888C527.83 231.118 524.7 219.538 516.8 211.668L365.55 61.0785C359.62 55.1685 351.6 51.9685 343.42 51.9685L343.43 51.9585Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.35}
    >
      <path
        d="M224.79 573.037C303.21 546.317 381.63 519.607 460.04 492.887C471.26 489.067 479.89 479.987 483.13 468.597C505.34 390.687 527.55 312.787 549.76 234.877C553.03 223.397 550.26 211.037 542.4 202.047C485.24 136.707 428.09 71.3673 370.93 6.02734L564.11 197.317C566.35 200.577 569.26 205.607 571.18 212.267C573.35 219.767 573.37 226.257 573.06 230.437C550.79 314.277 528.52 398.127 506.25 481.967C505.43 484.957 503.23 491.457 497.31 497.377C491.45 503.237 485.04 505.447 482.05 506.277C396.3 528.537 310.54 550.787 224.79 573.047V573.037Z"
        fill="#986EAB"
      />
    </g>
    <path
      d="M20.3416 334.333C19.2924 338.249 21.6162 342.274 25.5318 343.323C29.4475 344.372 33.4721 342.048 34.5213 338.133L76.0307 183.218C77.0799 179.302 74.7563 175.277 70.8407 174.228C66.9251 173.179 62.9002 175.503 61.851 179.418L20.3416 334.333Z"
      fill="white"
    />
    <path
      d="M67.7203 155.966C66.6711 159.882 68.9949 163.907 72.9105 164.956C76.8261 166.005 80.851 163.681 81.9002 159.766L96.2595 106.176C97.3087 102.26 94.9849 98.2355 91.0693 97.1864C87.1536 96.1372 83.1287 98.4609 82.0795 102.376L67.7203 155.966Z"
      fill="white"
    />
    <path
      d="M484.71 192.411C484.71 210.291 470.21 224.791 452.33 224.791C434.45 224.791 419.95 210.291 419.95 192.411C419.95 174.531 434.45 160.031 452.33 160.031C470.21 160.031 484.71 174.531 484.71 192.411Z"
      fill="#FBFF54"
    />
    <path
      d="M452.33 227.28C433.1 227.28 417.45 211.63 417.45 192.4C417.45 173.17 433.1 157.52 452.33 157.52C471.56 157.52 487.21 173.17 487.21 192.4C487.21 211.63 471.56 227.28 452.33 227.28ZM452.33 162.52C435.86 162.52 422.45 175.92 422.45 192.4C422.45 208.88 435.85 222.28 452.33 222.28C468.81 222.28 482.21 208.88 482.21 192.4C482.21 175.92 468.81 162.52 452.33 162.52Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M452.33 160.031C451.12 160.031 449.94 160.101 448.76 160.231C464.96 162.011 477.57 175.731 477.57 192.411C477.57 209.091 464.96 222.811 448.76 224.591C449.93 224.721 451.12 224.791 452.33 224.791C470.21 224.791 484.71 210.291 484.71 192.411C484.71 174.531 470.21 160.031 452.33 160.031Z"
        fill="black"
      />
    </g>
    <path
      d="M351.22 92.0909C351.22 109.971 336.72 124.471 318.84 124.471C300.96 124.471 286.46 109.971 286.46 92.0909C286.46 74.2109 300.96 59.7109 318.84 59.7109C336.72 59.7109 351.22 74.2109 351.22 92.0909Z"
      fill="#FBFF54"
    />
    <path
      d="M318.84 126.971C299.61 126.971 283.96 111.321 283.96 92.0909C283.96 72.8609 299.61 57.2109 318.84 57.2109C338.07 57.2109 353.72 72.8609 353.72 92.0909C353.72 111.321 338.07 126.971 318.84 126.971ZM318.84 62.2209C302.37 62.2209 288.96 75.6209 288.96 92.1009C288.96 108.581 302.36 121.981 318.84 121.981C335.32 121.981 348.72 108.581 348.72 92.1009C348.72 75.6209 335.32 62.2209 318.84 62.2209Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M318.84 59.7109C317.63 59.7109 316.45 59.7809 315.27 59.9109C331.47 61.6909 344.08 75.4109 344.08 92.0909C344.08 108.771 331.47 122.491 315.27 124.271C316.44 124.401 317.63 124.471 318.84 124.471C336.72 124.471 351.22 109.971 351.22 92.0909C351.22 74.2109 336.72 59.7109 318.84 59.7109Z"
        fill="black"
      />
    </g>
    <path
      d="M322.98 423.009C322.98 440.889 308.48 455.389 290.6 455.389C272.72 455.389 258.22 440.889 258.22 423.009C258.22 405.129 272.72 390.629 290.6 390.629C308.48 390.629 322.98 405.129 322.98 423.009Z"
      fill="#FBFF54"
    />
    <path
      d="M290.6 457.889C271.37 457.889 255.72 442.239 255.72 423.009C255.72 403.779 271.37 388.129 290.6 388.129C309.83 388.129 325.48 403.779 325.48 423.009C325.48 442.239 309.83 457.889 290.6 457.889ZM290.6 393.139C274.13 393.139 260.72 406.539 260.72 423.019C260.72 439.499 274.12 452.899 290.6 452.899C307.08 452.899 320.48 439.499 320.48 423.019C320.48 406.539 307.08 393.139 290.6 393.139Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M290.6 390.629C289.39 390.629 288.21 390.699 287.03 390.829C303.23 392.609 315.84 406.329 315.84 423.009C315.84 439.689 303.23 453.409 287.03 455.189C288.2 455.319 289.39 455.389 290.6 455.389C308.48 455.389 322.98 440.889 322.98 423.009C322.98 405.129 308.48 390.629 290.6 390.629Z"
        fill="black"
      />
    </g>
    <path
      d="M194.69 308.939C194.69 326.819 180.19 341.319 162.31 341.319C144.43 341.319 129.93 326.819 129.93 308.939C129.93 291.059 144.43 276.559 162.31 276.559C180.19 276.559 194.69 291.059 194.69 308.939Z"
      fill="#FBFF54"
    />
    <path
      d="M162.31 343.819C143.08 343.819 127.43 328.169 127.43 308.939C127.43 289.709 143.08 274.059 162.31 274.059C181.54 274.059 197.19 289.709 197.19 308.939C197.19 328.169 181.54 343.819 162.31 343.819ZM162.31 279.069C145.84 279.069 132.43 292.469 132.43 308.949C132.43 325.429 145.83 338.829 162.31 338.829C178.79 338.829 192.19 325.429 192.19 308.949C192.19 292.469 178.79 279.069 162.31 279.069Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M162.31 276.559C161.1 276.559 159.92 276.629 158.74 276.759C174.94 278.539 187.55 292.259 187.55 308.939C187.55 325.619 174.94 339.339 158.74 341.119C159.91 341.249 161.1 341.319 162.31 341.319C180.19 341.319 194.69 326.819 194.69 308.939C194.69 291.059 180.19 276.559 162.31 276.559Z"
        fill="black"
      />
    </g>
    <path
      d="M435.6 141.579C435.6 159.459 421.1 173.959 403.22 173.959C385.34 173.959 370.84 159.459 370.84 141.579C370.84 123.699 385.34 109.199 403.22 109.199C421.1 109.199 435.6 123.699 435.6 141.579Z"
      fill="#E9D647"
    />
    <path
      d="M403.23 176.459C384 176.459 368.35 160.809 368.35 141.579C368.35 122.349 384 106.699 403.23 106.699C422.46 106.699 438.11 122.349 438.11 141.579C438.11 160.809 422.46 176.459 403.23 176.459ZM403.23 111.699C386.76 111.699 373.35 125.099 373.35 141.579C373.35 158.059 386.75 171.459 403.23 171.459C419.71 171.459 433.11 158.059 433.11 141.579C433.11 125.099 419.71 111.699 403.23 111.699Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M403.23 109.199C402.02 109.199 400.84 109.269 399.66 109.399C415.86 111.179 428.47 124.899 428.47 141.579C428.47 158.259 415.86 171.979 399.66 173.759C400.83 173.889 402.02 173.959 403.23 173.959C421.11 173.959 435.61 159.459 435.61 141.579C435.61 123.699 421.11 109.199 403.23 109.199Z"
        fill="black"
      />
    </g>
    <path
      d="M411.86 231.368C411.86 249.248 397.36 263.748 379.48 263.748C361.6 263.748 347.1 249.248 347.1 231.368C347.1 213.488 361.6 198.988 379.48 198.988C397.36 198.988 411.86 213.488 411.86 231.368Z"
      fill="#986EAB"
    />
    <path
      d="M379.49 266.248C360.26 266.248 344.61 250.598 344.61 231.368C344.61 212.138 360.26 196.488 379.49 196.488C398.72 196.488 414.37 212.138 414.37 231.368C414.37 250.598 398.72 266.248 379.49 266.248ZM379.49 201.488C363.02 201.488 349.61 214.888 349.61 231.368C349.61 247.848 363.01 261.248 379.49 261.248C395.97 261.248 409.37 247.848 409.37 231.368C409.37 214.888 395.97 201.488 379.49 201.488Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M379.49 198.988C378.28 198.988 377.1 199.058 375.92 199.188C392.12 200.968 404.73 214.688 404.73 231.368C404.73 248.048 392.12 261.768 375.92 263.548C377.09 263.678 378.28 263.748 379.49 263.748C397.37 263.748 411.87 249.248 411.87 231.368C411.87 213.488 397.37 198.988 379.49 198.988Z"
        fill="black"
      />
    </g>
    <path
      d="M268.43 163.61C268.43 181.49 253.93 195.99 236.05 195.99C218.17 195.99 203.67 181.49 203.67 163.61C203.67 145.73 218.17 131.23 236.05 131.23C253.93 131.23 268.43 145.73 268.43 163.61Z"
      fill="#986EAB"
    />
    <path
      d="M236.05 198.479C216.82 198.479 201.17 182.829 201.17 163.599C201.17 144.369 216.82 128.719 236.05 128.719C255.28 128.719 270.93 144.369 270.93 163.599C270.93 182.829 255.28 198.479 236.05 198.479ZM236.05 133.719C219.57 133.719 206.17 147.119 206.17 163.599C206.17 180.079 219.57 193.479 236.05 193.479C252.53 193.479 265.93 180.079 265.93 163.599C265.93 147.119 252.53 133.719 236.05 133.719Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M236.05 131.23C234.84 131.23 233.66 131.3 232.48 131.43C248.68 133.21 261.29 146.93 261.29 163.61C261.29 180.29 248.68 194.01 232.48 195.79C233.65 195.92 234.84 195.99 236.05 195.99C253.93 195.99 268.43 181.49 268.43 163.61C268.43 145.73 253.93 131.23 236.05 131.23Z"
        fill="black"
      />
    </g>
    <path
      d="M173.86 399.841C173.86 417.721 159.36 432.221 141.48 432.221C123.6 432.221 109.1 417.721 109.1 399.841C109.1 381.961 123.6 367.461 141.48 367.461C159.36 367.461 173.86 381.961 173.86 399.841Z"
      fill="#986EAB"
    />
    <path
      d="M141.48 434.721C122.25 434.721 106.6 419.071 106.6 399.841C106.6 380.611 122.25 364.961 141.48 364.961C160.71 364.961 176.36 380.611 176.36 399.841C176.36 419.071 160.71 434.721 141.48 434.721ZM141.48 369.961C125 369.961 111.6 383.361 111.6 399.841C111.6 416.321 125 429.721 141.48 429.721C157.96 429.721 171.36 416.321 171.36 399.841C171.36 383.361 157.96 369.961 141.48 369.961Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M141.48 367.461C140.27 367.461 139.09 367.531 137.91 367.661C154.11 369.441 166.72 383.161 166.72 399.841C166.72 416.521 154.11 430.241 137.91 432.021C139.08 432.151 140.27 432.221 141.48 432.221C159.36 432.221 173.86 417.721 173.86 399.841C173.86 381.961 159.36 367.461 141.48 367.461Z"
        fill="black"
      />
    </g>
    <path
      d="M493.18 284.732C493.18 302.612 478.68 317.112 460.8 317.112C442.92 317.112 428.42 302.612 428.42 284.732C428.42 266.852 442.92 252.352 460.8 252.352C478.68 252.352 493.18 266.852 493.18 284.732Z"
      fill="#AD8EBC"
    />
    <path
      d="M460.8 319.612C441.57 319.612 425.92 303.962 425.92 284.732C425.92 265.502 441.57 249.852 460.8 249.852C480.03 249.852 495.68 265.502 495.68 284.732C495.68 303.962 480.03 319.612 460.8 319.612ZM460.8 254.862C444.33 254.862 430.92 268.262 430.92 284.742C430.92 301.222 444.32 314.622 460.8 314.622C477.28 314.622 490.68 301.222 490.68 284.742C490.68 268.262 477.28 254.862 460.8 254.862Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M460.8 252.363C459.59 252.363 458.41 252.433 457.23 252.563C473.43 254.343 486.04 268.063 486.04 284.743C486.04 301.423 473.43 315.143 457.23 316.923C458.4 317.053 459.59 317.123 460.8 317.123C478.68 317.123 493.18 302.623 493.18 284.743C493.18 266.863 478.68 252.363 460.8 252.363Z"
        fill="black"
      />
    </g>
    <path
      d="M126.64 305.532C126.64 323.412 112.14 337.912 94.26 337.912C76.38 337.912 61.8799 323.412 61.8799 305.532C61.8799 287.652 76.38 273.152 94.26 273.152C112.14 273.152 126.64 287.652 126.64 305.532Z"
      fill="#AD8EBC"
    />
    <path
      d="M94.27 340.401C75.04 340.401 59.3899 324.751 59.3899 305.521C59.3899 286.291 75.04 270.641 94.27 270.641C113.5 270.641 129.15 286.291 129.15 305.521C129.15 324.751 113.5 340.401 94.27 340.401ZM94.27 275.651C77.8 275.651 64.3899 289.051 64.3899 305.531C64.3899 322.011 77.79 335.411 94.27 335.411C110.75 335.411 124.15 322.011 124.15 305.531C124.15 289.051 110.75 275.651 94.27 275.651Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M94.27 273.152C93.06 273.152 91.88 273.222 90.7 273.352C106.9 275.132 119.51 288.852 119.51 305.532C119.51 322.212 106.9 335.932 90.7 337.712C91.87 337.842 93.06 337.912 94.27 337.912C112.15 337.912 126.65 323.412 126.65 305.532C126.65 287.652 112.15 273.152 94.27 273.152Z"
        fill="black"
      />
    </g>
    <path d="M300.449 297.552L279.768 283.434L80.4089 575.464L101.09 589.582L300.449 297.552Z" fill="#FBFF54" />
    <path
      d="M100.97 592.183C100.47 592.183 99.9801 592.033 99.5601 591.743L78.8799 577.623C77.7399 576.843 77.45 575.293 78.23 574.153L277.61 282.133C278.39 280.993 279.95 280.703 281.08 281.473L301.76 295.593C302.31 295.963 302.68 296.543 302.81 297.193C302.93 297.843 302.79 298.523 302.42 299.063L103.04 591.083C102.67 591.633 102.09 592.003 101.44 592.133C101.29 592.163 101.13 592.173 100.98 592.173L100.97 592.183ZM83.7701 574.913L100.32 586.213L296.88 298.323L280.33 287.023L83.7701 574.913Z"
      fill="black"
    />
    <path d="M478.32 589.548L499 575.43L299.641 283.399L278.96 297.517L478.32 589.548Z" fill="#FBFF54" />
    <path
      d="M478.4 592.18C478.25 592.18 478.09 592.17 477.94 592.14C477.29 592.02 476.71 591.64 476.34 591.09L276.96 299.07C276.59 298.52 276.45 297.85 276.57 297.2C276.69 296.55 277.07 295.97 277.62 295.6L298.3 281.48C299.44 280.7 300.99 280.99 301.77 282.14L501.15 574.16C501.93 575.3 501.64 576.86 500.5 577.63L479.82 591.75C479.4 592.04 478.91 592.19 478.41 592.19L478.4 592.18ZM282.5 298.31L479.06 586.2L495.61 574.9L299.05 287.01L282.5 298.31Z"
      fill="black"
    />
    <path
      d="M290 307.55C299.742 307.55 307.64 299.652 307.64 289.91C307.64 280.167 299.742 272.27 290 272.27C280.258 272.27 272.36 280.167 272.36 289.91C272.36 299.652 280.258 307.55 290 307.55Z"
      fill="#AD8EBC"
    />
    <path
      d="M290 310.061C278.89 310.061 269.86 301.021 269.86 289.921C269.86 278.821 278.9 269.781 290 269.781C301.1 269.781 310.14 278.821 310.14 289.921C310.14 301.021 301.1 310.061 290 310.061ZM290 274.771C281.65 274.771 274.86 281.561 274.86 289.911C274.86 298.261 281.65 305.051 290 305.051C298.35 305.051 305.14 298.261 305.14 289.911C305.14 281.561 298.35 274.771 290 274.771Z"
      fill="black"
    />
    <path
      d="M101.79 575.84H32.6001C27.5356 575.84 23.4299 579.945 23.4299 585.01V601.12C23.4299 606.184 27.5356 610.29 32.6001 610.29H101.79C106.854 610.29 110.96 606.184 110.96 601.12V585.01C110.96 579.945 106.854 575.84 101.79 575.84Z"
      fill="#E9D647"
    />
    <path
      d="M101.78 612.79H32.5901C26.1601 612.79 20.9199 607.55 20.9199 601.12V585.01C20.9199 578.58 26.1601 573.34 32.5901 573.34H101.78C108.21 573.34 113.45 578.58 113.45 585.01V601.12C113.45 607.55 108.21 612.79 101.78 612.79ZM32.5901 578.34C28.9101 578.34 25.9199 581.33 25.9199 585.01V601.12C25.9199 604.8 28.9101 607.79 32.5901 607.79H101.78C105.46 607.79 108.45 604.8 108.45 601.12V585.01C108.45 581.33 105.46 578.34 101.78 578.34H32.5901Z"
      fill="black"
    />
    <path
      d="M548.98 575.84H479.79C474.726 575.84 470.62 579.945 470.62 585.01V601.12C470.62 606.184 474.726 610.29 479.79 610.29H548.98C554.044 610.29 558.15 606.184 558.15 601.12V585.01C558.15 579.945 554.044 575.84 548.98 575.84Z"
      fill="#E9D647"
    />
    <path
      d="M548.98 612.79H479.79C473.36 612.79 468.12 607.55 468.12 601.12V585.01C468.12 578.58 473.36 573.34 479.79 573.34H548.98C555.41 573.34 560.65 578.58 560.65 585.01V601.12C560.65 607.55 555.41 612.79 548.98 612.79ZM479.79 578.34C476.11 578.34 473.12 581.33 473.12 585.01V601.12C473.12 604.8 476.11 607.79 479.79 607.79H548.98C552.66 607.79 555.65 604.8 555.65 601.12V585.01C555.65 581.33 552.66 578.34 548.98 578.34H479.79Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M278.52 305.91L96.1201 574.71L80.3 575.56L271.18 295.18L278.52 305.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M300.54 305.91L484.07 574.15L473.34 579.23L287.84 309.58L300.54 305.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M23.27 593.059H110.52V603.509L101.78 610.289H30.8999L22.71 603.229L23.27 593.059Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M469.95 593.059H557.2V603.509L548.47 610.289H477.58L469.39 603.229L469.95 593.059Z" fill="#E9D647" />
    </g>
  </svg>
))`
  left: calc((621px - 578px) / 2);
`;

export default Frame3;
