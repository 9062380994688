import styled from 'styled-components';
import { motion } from 'framer-motion';

import MeerkatWalk from './MeerkatWalk';
import MeerkatCloud from './MeerkatCloud';

import PageContainer from '../../Wrappers';
import { useApp } from '../../../contexts/AppContext';
import { Points } from '../../../components/Farming/Points';
import { IconCloud } from '../../../components/Icons/IconCloud';
import { getTurboAvailable } from '../../../lib/farming/util';
import { Progress } from '../../../components/Farming/Progress';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../../../lib/utils';
import { NewsLine } from '../../../components/NewsLine';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @keyframes upDownAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100px);
    }
  }
  @keyframes opacityAnimation {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  .turbo {
    animation: upDownAnimation 2s infinite alternate;

    #boost {
      margin-left: -100px;
      left: 305px;
      top: -90px;
    }
  }
  .turbo,
  .started {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-left: -50px;
  }

  #boost {
    top: 0;
    position: absolute;
    display: flex;
    margin-left: 30px;

    .active {
      svg {
        animation: opacityAnimation 0.5s infinite alternate;
        filter: grayscale(0) !important;
      }
    }
    .used {
      svg {
        opacity: 0.5;
        filter: grayscale(100);
      }
    }
  }

  @media screen and (min-height: 401px) and (max-height: 500px) {
    transform: translate(0, 5%);
  }
  @media screen and (min-height: 501px) and (max-height: 600px) {
    transform: translate(0, 7%);
  }
  @media screen and (min-height: 601px) and (max-height: 700px) {
    transform: translate(0, 8%);
  }
  @media screen and (min-height: 701px) and (max-height: 800px) {
    transform: translate(0, 20%);
  }
  @media screen and (min-height: 801px) and (max-height: 900px) {
    transform: translate(0, 23%);
  }
  @media screen and (min-height: 901px) and (max-height: 1000px) {
    transform: translate(0, 25%);
  }
`;

const FarmingClaimed = () => {
  const { app, farmingState } = useApp();

  const turboTotal = 3;
  const turboAvailable = getTurboAvailable(app);

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer spread light>
      <NewsLine />
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Points />
      </motion.div>
      <Container>
        <div className={farmingState}>
          <div id="boost">
            {[...Array(turboTotal)].map((e, i) => {
              const isActive = i === turboAvailable && farmingState === 'turbo' ? 'active' : '';
              const isUsed = i + 1 > turboAvailable ? 'used' : '';

              return (
                <span className={`${isActive} ${isUsed}`} key={i}>
                  <IconCloud />{' '}
                </span>
              );
            })}
          </div>
          {farmingState === 'started' ? <MeerkatWalk /> : <MeerkatCloud />}
        </div>
      </Container>
      <Progress />
    </PageContainer>
  );
};

export default FarmingClaimed;
