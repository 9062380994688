import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import styled from 'styled-components';

import { MainButton } from '../components/MainButton';
import PageContainer from '../components/Wrappers';
import { IconCoin } from '../components/Icons/IconCoin';
import {
  addressToShort,
  DEPOSIT_NEEDED_TROPHY,
  PAYMENT_PREFIX_TROPHY,
  queryAmountFromNetwork,
  TON_NANO,
  txPrepare,
} from '../lib/tx';
import { notificationEmit } from '../controllers/NotificationsController';
import { IconCheckMark } from '../components/Icons/IconCheckMark';
import { useApp } from '../contexts/AppContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { doHapticFeedback } from '../lib/utils';
import { useEffectOnce } from 'react-use';

const Container = styled.div.attrs({
  className: 'nft-container',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: 0;

  h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 76px;
    text-align: center;
    width: 800px;
  }
  > p {
    color: #fff;
    font-size: 52px;
    width: 800px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
    font-family: mr_ApexMk3, sans-serif;

    span {
      font-family: mr_ApexMk3, sans-serif;
      color: #faff00;
    }
  }
  .info {
    font-size: 37px;
    width: 800px;
    margin-top: 30px;
    color: rgb(232 232 232);
    font-style: italic;
    margin-top: 30px;

    span {
      font-size: 38px;
      text-align: left;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 20px 0px 30px;
      color: #eee;
    }
    b {
      text-decoration: underline;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 10px;
      min-height: 60px;
      svg {
        width: 50px;
      }
    }
    p {
      margin-bottom: 30px;
    }
  }
  button {
    margin-top: -10px !important;
    font-size: 46px;
    margin-top: 30px;
    padding: 60px 80px;
    border-radius: 150px;
    z-index: 100;
    background: #000000d9;
    border-width: 3px;
    position: relative;
    width: 800px;

    svg {
      width: 110px;
      height: auto;
      margin-top: -11px;
    }
    .meerkatHand {
      position: absolute;
      right: 0;
      top: 50px;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0 50px;

    button {
      gap: 20px;

      .iconCheckMark {
        width: 60px;
        height: auto;
        margin-top: 0;
      }
    }

    .secondary {
      display: flex;
      gap: 10px;
      width: 800px;

      button {
        flex: 1;
        padding: 20px 50px;
        width: auto;
        font-size: 32px;
        display: flex;
        gap: 20px;
      }
    }
  }
`;

const ImgContainer = styled.div.attrs({
  className: 'nft-trophy-img-container',
})`
  @keyframes pulseHeart {
    0% {
      transform: scale(0.95);
    }

    4% {
      transform: scale(1.1);
    }

    8% {
      transform: scale(0.95);
    }

    12% {
      transform: scale(1);
    }

    16% {
      transform: scale(0.95);
    }

    24% {
      transform: scale(0.95);
    }

    28% {
      transform: scale(1.1);
    }

    32% {
      transform: scale(0.95);
    }

    36% {
      transform: scale(1);
    }

    40% {
      transform: scale(0.95);
    }

    100% {
      transform: scale(0.95);
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  background: none;
  border: 0;

  animation: pulseHeart 4s infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  img {
    margin: auto;
    width: 35%;
  }
`;

const PAYMENT_PREFIX = PAYMENT_PREFIX_TROPHY;
const DEPOSIT_NEEDED = DEPOSIT_NEEDED_TROPHY;

export const BookNFTTrophy = () => {
  const { app } = useApp();
  const [tonConnectUI] = useTonConnectUI();
  const tonAddress = useTonAddress();
  const [deposit, setDeposit] = useState(0);
  const { t } = useTranslation();

  useEffectOnce(() => {
    const userId = localStorage.getItem('airdrop-user-id');

    if (userId && Number(userId) !== app.user.id) {
      tonConnectUI?.disconnect();
      localStorage.removeItem('airdrop-user-id');
    }

    doHapticFeedback();
  });

  useEffect(() => {
    (async () => {
      const amountTon = await queryAmountFromNetwork(tonAddress, app.user.id, PAYMENT_PREFIX);

      setDeposit(amountTon);
    })();
  }, [tonAddress, app.user.id]);

  const handleCheck = async () => {
    const amountTon = await queryAmountFromNetwork(tonAddress, app.user.id, PAYMENT_PREFIX);

    setDeposit(amountTon);

    if (amountTon >= DEPOSIT_NEEDED_TROPHY) {
      notificationEmit({
        title: t('notifications.nft.deposit_done.title'),
        subtitle: t('notifications.nft.deposit_done.subtitle', { amount: amountTon }),
      });
    } else {
      notificationEmit({
        title: t('notifications.nft.deposit.title'),
        subtitle: t('notifications.nft.deposit.subtitle', { amount: amountTon }),
      });
    }
  };

  const handlePay = () => {
    const tx = txPrepare(app.user.id, PAYMENT_PREFIX, DEPOSIT_NEEDED);

    console.log('tx:', { tx });

    tonConnectUI?.sendTransaction(tx);

    notificationEmit({
      title: t('notifications.nft.payment.title'),
      subtitle: t('notifications.nft.payment.subtitle'),
    });
  };

  const handleConnect = () => {
    localStorage.setItem('airdrop-user-id', app.user.id.toString());
    tonConnectUI.openModal();
  };

  const handleDisconnect = () => {
    tonConnectUI?.disconnect();

    notificationEmit({
      title: t('notifications.nft.disconnect.title'),
      subtitle: t('notifications.nft.disconnect.subtitle'),
    });
  };

  console.log('NFT: ', { tonAddress });

  const depositMade = deposit >= DEPOSIT_NEEDED / TON_NANO;

  return (
    <PageContainer vcenter>
      <Container>
        <h1>{t('nftTrophy.heading.title')}</h1>
        <p>{t('nftTrophy.heading.sub_title')}</p>

        <ImgContainer>
          <img src={`/img/nft/nfts-trophy-thumb.webp`} />
        </ImgContainer>

        <div className="info">
          <p>
            <b>{t('nftTrophy.text.line1')}</b>
          </p>

          {tonAddress ? (
            <div className="actions">
              <MainButton disabled>
                1. {addressToShort(tonAddress)} <IconCheckMark />
              </MainButton>
              <MainButton onClick={handlePay} disabled={true}>
                2. {t('system.button.deposit')} {depositMade && <IconCheckMark />}
              </MainButton>

              <div className="secondary">
                <MainButton onClick={handleCheck}>{t('system.button.check')}</MainButton>
                <MainButton onClick={handleDisconnect}>{t('system.button.disconnect')}</MainButton>
              </div>
            </div>
          ) : (
            <div className="actions">
              <MainButton onClick={handleConnect}>
                1. {t('system.button.connect')}
                <IconCoin />
              </MainButton>
              <MainButton disabled onClick={handlePay}>
                2. {t('system.button.deposit')}
              </MainButton>
            </div>
          )}
          <p style={{ color: '#FAFF00' }}>{t('nftTrophy.text.line2')}</p>
          <p>{t('nftTrophy.text.line3')}</p>
          <p>{t('nftTrophy.text.line4')}</p>
        </div>
      </Container>
    </PageContainer>
  );
};
