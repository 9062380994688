import ButtonContainer from './ButtonContainer';
import IconContainer from './IconContainer';
import styled from 'styled-components';

const Icon = styled((props) => (
  <svg width={254} height={209} viewBox="0 0 254 209" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1818_448)">
      <path
        d="M2.60999 205.298H251.39L238.424 125.937L212.491 104.785L201.279 59.5255L183.594 50.6984L158.831 11.3093L135.843 3.08594L107.551 27.777L76.8995 37.77L50.3616 83.6128L23.8236 101.246L2.60999 205.298Z"
        fill="#AD8EBC"
      />
      <path
        d="M251.39 207.9H2.61014C1.81672 207.9 1.08593 207.546 0.584822 206.942C0.0837122 206.339 -0.104204 205.548 0.041953 204.777L21.2765 100.725C21.4226 100.059 21.8193 99.4554 22.3831 99.0806L48.399 81.7802L74.6238 36.4579C74.937 35.8958 75.459 35.4794 76.0645 35.292L106.215 25.4656L134.131 1.12846C134.84 0.503902 135.843 0.316534 136.741 0.628815L159.729 8.85222C160.272 9.03958 160.752 9.41432 161.065 9.91397L185.432 48.6785L202.469 57.1725C203.158 57.5056 203.66 58.1302 203.847 58.8796L214.851 103.307L240.115 123.917C240.616 124.313 240.93 124.896 241.034 125.52L254 204.881C254.125 205.631 253.917 206.401 253.416 206.984C252.914 207.567 252.184 207.9 251.432 207.9H251.39ZM5.80471 202.695H248.321L236.002 127.332L210.821 106.784C210.383 106.43 210.07 105.93 209.944 105.389L199.024 61.2946L182.425 53.0088C181.987 52.8006 181.632 52.4675 181.381 52.0719L157.098 13.4532L136.406 6.04169L109.263 29.7334C108.992 29.9624 108.699 30.129 108.365 30.2539L78.6535 39.9346L52.6167 84.9239C52.4079 85.2778 52.1365 85.5693 51.8024 85.7983L26.1623 102.828L5.80471 202.695Z"
        fill="#303030"
      />
      <path
        d="M59.2145 205.298L69.2367 142.405L97.5285 104.785L132.314 117.131L139.392 134.764L150.583 148.858L155.302 205.881L59.2145 205.298Z"
        fill="#CBABCD"
      />
      <path
        d="M155.302 208.485L59.2144 207.902C58.4628 207.902 57.732 207.569 57.2309 206.986C56.7298 206.403 56.521 205.632 56.6462 204.904L66.6684 142.01C66.7311 141.594 66.8981 141.198 67.1487 140.865L95.4405 103.246C96.1295 102.33 97.3405 101.976 98.4054 102.351L133.191 114.696C133.901 114.946 134.464 115.487 134.736 116.174L141.668 133.454L152.63 147.257C152.943 147.652 153.152 148.152 153.193 148.651L157.912 205.674C157.975 206.403 157.724 207.111 157.223 207.652C156.722 208.193 156.033 208.485 155.302 208.485ZM62.2628 202.718L152.463 203.28L148.036 149.88L137.325 136.389C137.179 136.202 137.033 135.973 136.949 135.744L130.309 119.193L98.4471 107.888L71.6795 143.468L62.242 202.739L62.2628 202.718Z"
        fill="#303030"
      />
      <path
        d="M115.214 112.03L90.847 141.822C88.7382 162.974 86.6294 184.146 84.4996 205.298H59.1936L69.2158 142.405L97.5076 104.785L115.193 112.03H115.214Z"
        fill="#AD8EBC"
      />
      <path
        d="M84.4996 207.899H59.1935C58.421 207.899 57.7111 207.566 57.21 206.983C56.7089 206.4 56.5001 205.63 56.6254 204.881L66.6476 141.987C66.7102 141.571 66.8772 141.175 67.1278 140.842L95.4196 103.223C96.1295 102.265 97.4032 101.932 98.5098 102.369L116.195 109.614C116.967 109.926 117.531 110.593 117.74 111.384C117.949 112.175 117.74 113.028 117.239 113.674L93.3734 142.841L87.1095 205.547C86.9843 206.879 85.8568 207.899 84.5205 207.899H84.4996ZM62.2628 202.695H82.1402L88.237 141.55C88.2788 141.05 88.4876 140.551 88.8217 140.155L110.975 113.091L98.4054 107.949L71.7004 143.445L62.2628 202.695Z"
        fill="#303030"
      />
      <path
        d="M76.6907 37.7718L88.5711 55.8008C91.9119 60.8806 99.1362 61.4635 103.27 56.9875L108.386 51.4497C111.225 48.3894 115.61 47.286 119.556 48.6392L123.482 49.9924C126.238 50.9501 128.493 52.9695 129.746 55.6135L134.652 66.0437C137.951 73.0596 147.326 74.5377 152.63 68.875L156.659 64.5655C157.766 63.3789 159.416 62.8584 161.002 63.1707C163.821 63.712 166.16 65.6481 167.204 68.3129L177.748 94.7735C179.94 100.249 187.707 100.29 189.921 94.8151L201.258 66.9805L201.07 59.34L185.348 51.304L158.434 12.3105L135.237 3.87891L109.889 27.7788L76.6698 37.7718H76.6907Z"
        fill="#FAFF54"
      />
      <path
        d="M183.866 101.476H183.824C180.003 101.476 176.746 99.2485 175.326 95.7093L164.782 69.2487C164.051 67.4166 162.443 66.0842 160.501 65.7095C159.791 65.5638 159.061 65.8136 158.56 66.3341L154.53 70.6435C151.44 73.9329 146.93 75.4735 142.482 74.7865C138.014 74.0786 134.214 71.2265 132.293 67.146L127.386 56.7158C126.447 54.7172 124.714 53.1766 122.647 52.448L118.721 51.0947C115.756 50.0746 112.457 50.8866 110.328 53.1974L105.212 58.7352C102.707 61.4625 99.178 62.8573 95.4405 62.545C91.7449 62.2536 88.4459 60.2966 86.4206 57.2154L74.5401 39.1864C74.0807 38.4994 73.9972 37.6458 74.2687 36.8755C74.5401 36.1052 75.1874 35.5015 75.9599 35.2725L108.574 25.4669L133.462 1.98334C134.172 1.29632 135.216 1.08813 136.156 1.42123L159.353 9.85282C159.854 10.0402 160.293 10.3733 160.606 10.8313L187.165 49.2835L202.302 57.0281C203.158 57.4653 203.701 58.3188 203.722 59.2765L203.91 66.917C203.91 67.2709 203.847 67.6248 203.722 67.9579L192.384 95.7926C190.944 99.3109 187.687 101.497 183.886 101.497L183.866 101.476ZM160.084 60.4632C160.543 60.4632 161.023 60.5048 161.504 60.6089C165.199 61.3167 168.248 63.8358 169.647 67.3334L180.191 93.794C181.109 96.084 183.218 96.2714 183.845 96.2714C184.492 96.2714 186.58 96.084 187.499 93.8148L198.627 66.4798L198.481 60.9212L184.137 53.593C183.761 53.4056 183.427 53.1142 183.177 52.7602L156.722 14.4538L135.843 6.85492L111.664 29.6514C111.372 29.9221 111.017 30.1303 110.62 30.2552L80.7622 39.2489L90.7218 54.3633C91.8701 56.1121 93.7284 57.1946 95.7955 57.3612C97.9252 57.5277 99.8879 56.7366 101.308 55.2169L106.423 49.6791C109.952 45.8484 115.422 44.4744 120.371 46.1815L124.296 47.5347C127.699 48.7006 130.518 51.2405 132.063 54.509L136.97 64.9392C138.181 67.4999 140.457 69.207 143.255 69.6442C146.053 70.0814 148.746 69.1446 150.688 67.1043L154.718 62.7949C156.117 61.2959 158.038 60.484 160.042 60.484L160.084 60.4632Z"
        fill="#303030"
      />
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.32}
      >
        <path
          d="M201.279 59.5264C195.976 56.7783 190.672 54.0302 185.369 51.303C176.516 37.979 167.684 24.655 158.831 11.331C151.982 8.97847 145.134 6.62595 138.285 4.27344L120.308 17.7848L150.082 31.0047C159.708 44.3287 169.333 57.6527 178.98 70.9767C182.905 77.6387 186.83 84.3007 190.777 90.9627C191.591 99.1028 192.405 107.222 193.24 115.362C202.803 123.273 212.366 131.164 221.929 139.075L231.158 204.904L251.39 205.299L238.424 125.938L212.491 104.786C208.754 89.6927 205.017 74.6199 201.3 59.5264H201.279Z"
          fill="#9D7BAD"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1818_448">
        <rect width={254} height={208} fill="white" transform="translate(0 0.484375)" />
      </clipPath>
    </defs>
  </svg>
))``;

export const Cave = ({ active = false }) => {
  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
    </ButtonContainer>
  );
};
