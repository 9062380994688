import styled from 'styled-components';

import { IconCoin } from '../Icons/IconCoin';
import { useApp } from '../../contexts/AppContext';

export const PointsContainer = styled.div.attrs({
  'data-tip': 'points-container'
})`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  span {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 90px;
  }
  svg {
    height: auto;
    width: 130px;
    margin-top: -20px;
  }
`;

export const Points = () => {
  const { app } = useApp();

  return (
    <PointsContainer>
      <span>{app.state.balance.toLocaleString('us')}</span>
      <IconCoin />
    </PointsContainer>
  );
};
