export const IconWorld = () => (
  <svg width={105} height={96} viewBox="0 0 105 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M103.31 89.4691L73.0901 10.3491C71.9101 7.25913 67.5501 7.20913 66.3001 10.2791C62.2401 20.2791 58.1801 30.2691 54.1201 40.2691C53.1901 42.5491 50.3201 43.2791 48.4201 41.7091C48.4001 41.6891 48.3701 41.6691 48.3501 41.6491C46.6501 40.2491 44.1101 40.6591 42.9401 42.5191C33.3101 57.8491 23.6801 73.1891 14.0501 88.5191C12.5301 90.9391 14.2601 94.0991 17.1301 94.1091C44.7101 94.2091 72.3001 94.3191 99.8801 94.4191C102.44 94.4191 104.22 91.8591 103.3 89.4691H103.31Z"
      fill="#B48EC0"
    />
    <path
      d="M99.9001 95.4203H99.8801L17.1301 95.1103C15.4201 95.1103 13.9001 94.2003 13.0801 92.7003C12.2601 91.2003 12.3001 89.4403 13.2201 87.9903L42.1001 41.9903C42.8101 40.8603 43.9801 40.0903 45.3001 39.8703C46.6201 39.6603 47.9701 40.0203 49.0001 40.8703C49.7301 41.4703 50.5801 41.6603 51.4101 41.4503C52.2401 41.2403 52.8901 40.6703 53.2101 39.8803L65.3801 9.90027C66.1101 8.09027 67.7801 6.98027 69.7301 7.00027C71.6801 7.02027 73.3301 8.17027 74.0201 9.99027L104.23 89.1103C104.78 90.5403 104.58 92.1503 103.71 93.4103C102.84 94.6703 101.41 95.4203 99.8901 95.4203H99.9001ZM46.0201 41.8103C45.8801 41.8103 45.7401 41.8203 45.6001 41.8403C44.8501 41.9603 44.1801 42.4003 43.7801 43.0503L14.9001 89.0503C14.3801 89.8803 14.3501 90.8803 14.8201 91.7403C15.2901 92.6003 16.1501 93.1103 17.1301 93.1103L99.8801 93.4203C100.76 93.4203 101.56 93.0003 102.06 92.2803C102.56 91.5503 102.67 90.6603 102.35 89.8303L72.1601 10.7003C71.6901 9.46027 70.5901 9.01027 69.7101 9.00027H69.6801C68.8101 9.00027 67.7201 9.43027 67.2301 10.6503L55.0501 40.6403C54.4901 42.0303 53.3301 43.0303 51.8801 43.4003C50.4301 43.7703 48.9401 43.4303 47.7801 42.4803C47.2401 42.0403 46.6301 41.8203 46.0201 41.8203V41.8103Z"
      fill="#303030"
    />
    <path
      d="M29.2501 94.1219L21.3301 80.1119H26.9001L18.8101 65.8119H23.3901L15.3901 51.6719L6.95009 65.8119H11.8701L3.33009 80.1119H9.36009L1.00009 94.1219H29.2501Z"
      fill="#FAFF00"
    />
    <path
      d="M29.2501 95.1206H1.0001C0.640104 95.1206 0.310104 94.9306 0.130104 94.6106C-0.0498959 94.3006 -0.0398959 93.9106 0.140104 93.6006L7.6001 81.1006H3.3301C2.9701 81.1006 2.6401 80.9106 2.4601 80.5906C2.2801 80.2806 2.2901 79.8906 2.4701 79.5806L10.1001 66.7906H6.9401C6.5801 66.7906 6.2501 66.6006 6.0701 66.2806C5.8901 65.9706 5.9001 65.5806 6.0801 65.2706L14.5201 51.1306C14.7001 50.8206 15.0301 50.6406 15.3901 50.6406C15.7501 50.6406 16.0701 50.8406 16.2501 51.1506L24.2501 65.2906C24.4301 65.6006 24.4201 65.9806 24.2501 66.2906C24.0701 66.6006 23.7401 66.7906 23.3901 66.7906H20.5201L27.7701 79.6006C27.9501 79.9106 27.9401 80.2906 27.7701 80.6006C27.5901 80.9106 27.2601 81.1006 26.9101 81.1006H23.0601L30.1401 93.6206C30.3201 93.9306 30.3101 94.3106 30.1401 94.6206C29.9601 94.9306 29.6301 95.1206 29.2801 95.1206H29.2501ZM2.7601 93.1206H27.5401L20.4601 80.6006C20.2801 80.2906 20.2901 79.9106 20.4601 79.6006C20.6401 79.2906 20.9701 79.1006 21.3201 79.1006H25.1701L17.9201 66.2906C17.7401 65.9806 17.7501 65.6006 17.9201 65.2906C18.1001 64.9806 18.4301 64.7906 18.7801 64.7906H21.6501L15.3401 53.6406L8.6801 64.7906H11.8401C12.2001 64.7906 12.5301 64.9806 12.7101 65.3006C12.8901 65.6106 12.8801 66.0006 12.7001 66.3106L5.0701 79.1006H9.3301C9.6901 79.1006 10.0201 79.2906 10.2001 79.6106C10.3801 79.9206 10.3701 80.3106 10.1901 80.6206L2.7301 93.1206H2.7601Z"
      fill="#303030"
    />
    <path
      d="M48.1101 94.0875L37.4001 75.1475H44.9301L33.9901 55.8075H40.1901L29.3701 36.6875L17.9601 55.8075H24.6101L13.0601 75.1475H21.2101L9.9101 94.0875H48.1101Z"
      fill="#FAFF00"
    />
    <path
      d="M48.1101 95.0884H9.91011C9.55011 95.0884 9.22011 94.8984 9.04011 94.5784C8.86011 94.2684 8.87011 93.8784 9.05011 93.5684L19.4501 76.1384H13.0601C12.7001 76.1384 12.3701 75.9484 12.1901 75.6284C12.0101 75.3184 12.0201 74.9284 12.2001 74.6184L22.8401 56.7884H17.9501C17.5901 56.7884 17.2601 56.5984 17.0801 56.2784C16.9001 55.9684 16.9101 55.5784 17.0901 55.2684L28.5001 36.1384C28.6801 35.8284 28.9901 35.6484 29.3701 35.6484C29.7301 35.6484 30.0501 35.8484 30.2301 36.1584L41.0501 55.2884C41.2301 55.5984 41.2201 55.9784 41.0501 56.2884C40.8701 56.5984 40.5401 56.7884 40.1901 56.7884H35.7101L45.8001 74.6384C45.9801 74.9484 45.9701 75.3284 45.8001 75.6384C45.6201 75.9484 45.2901 76.1384 44.9401 76.1384H39.1201L48.9901 93.5884C49.1701 93.8984 49.1601 94.2784 48.9901 94.5884C48.8101 94.8984 48.4801 95.0884 48.1301 95.0884H48.1101ZM11.6701 93.0884H46.4001L36.5301 75.6384C36.3501 75.3284 36.3601 74.9484 36.5301 74.6384C36.7101 74.3284 37.0401 74.1384 37.3901 74.1384H43.2101L33.1201 56.2884C32.9401 55.9784 32.9501 55.5984 33.1201 55.2884C33.3001 54.9784 33.6301 54.7884 33.9801 54.7884H38.4601L29.3301 38.6484L19.7001 54.7884H24.5901C24.9501 54.7884 25.2801 54.9784 25.4601 55.2984C25.6401 55.6084 25.6301 55.9984 25.4501 56.3084L14.8101 74.1384H21.2001C21.5601 74.1384 21.8901 74.3284 22.0701 74.6484C22.2501 74.9584 22.2401 75.3484 22.0601 75.6584L11.6601 93.0884H11.6701Z"
      fill="#303030"
    />
    <path
      d="M59.5301 27.1103C60.7301 28.1103 61.9301 29.1103 63.1301 30.1103C64.5201 31.2703 66.6401 30.7603 67.3501 29.0903L68.3401 26.7603C68.7901 25.7003 70.3301 25.7903 70.6501 26.9003L73.6301 37.2303C74.1801 39.1503 76.8601 39.2803 77.5901 37.4103L80.5201 29.9403C78.0401 23.4103 75.5701 16.8803 73.0901 10.3503C72.5701 9.10034 71.4501 8.22034 70.1701 8.05034C68.5901 7.85034 66.9901 8.78034 66.3001 10.3503C64.0401 15.9403 61.7901 21.5303 59.5301 27.1103Z"
      fill="#FAFF00"
    />
    <path
      d="M75.6501 39.7384C75.6501 39.7384 75.5501 39.7384 75.5001 39.7384C74.1501 39.6784 73.0401 38.7984 72.6701 37.5084L69.6901 27.1784C69.6901 27.1784 69.6501 27.0284 69.4901 27.0184C69.3301 27.0184 69.2801 27.1184 69.2701 27.1584L68.2801 29.4884C67.8201 30.5784 66.8901 31.3584 65.7401 31.6384C64.5901 31.9184 63.4101 31.6384 62.5001 30.8884L58.8901 27.8884C58.5601 27.6084 58.4401 27.1484 58.6001 26.7484L65.3701 9.98843C66.2401 8.00842 68.2601 6.81843 70.2901 7.07843C71.9201 7.28843 73.3501 8.39842 74.0101 9.97843L81.4501 29.5984C81.5401 29.8284 81.5401 30.0884 81.4501 30.3184L78.5201 37.7884C78.0501 38.9984 76.9301 39.7584 75.6501 39.7584V39.7384ZM69.4701 25.0084C69.4701 25.0084 69.5601 25.0084 69.6101 25.0084C70.5601 25.0684 71.3501 25.6984 71.6101 26.6184L74.5901 36.9484C74.7901 37.6484 75.4101 37.7284 75.5901 37.7384C75.7801 37.7484 76.4001 37.7184 76.6601 37.0484L79.4501 29.9384L72.1601 10.7084C71.7801 9.81843 70.9701 9.16842 70.0501 9.04842C68.9001 8.89842 67.7301 9.60843 67.2201 10.7584L60.7401 26.8184L63.7801 29.3484C64.2001 29.6984 64.7401 29.8184 65.2701 29.6984C65.8001 29.5684 66.2301 29.2084 66.4401 28.7084L67.4301 26.3784C67.7801 25.5384 68.5801 25.0184 69.4701 25.0184V25.0084Z"
      fill="#303030"
    />
    <path
      d="M17.2301 33.1803H50.2601C51.7301 32.3203 52.2301 30.5203 51.5301 29.2303C50.9701 28.2003 49.7201 27.6103 48.4201 27.8203H41.2201C41.1701 27.5403 40.4601 24.0303 37.0301 22.5503C33.7201 21.1203 30.7601 22.8503 30.4901 23.0203C27.0601 18.7103 20.8401 18.5003 17.5501 21.8003C14.7701 24.5903 14.4301 29.5603 17.2201 33.1903L17.2301 33.1803Z"
      fill="#B48EC0"
    />
    <path
      d="M50.5301 34.181H16.7401L16.4401 33.791C13.4501 29.901 13.6301 24.321 16.8601 21.091C18.6201 19.321 21.1701 18.401 23.8701 18.551C26.4901 18.701 28.9601 19.861 30.7601 21.781C32.0201 21.221 34.6201 20.421 37.4501 21.641C40.4101 22.921 41.5901 25.491 42.0101 26.831H48.3601C50.0401 26.591 51.6601 27.361 52.4201 28.771C53.4001 30.591 52.6601 32.961 50.7701 34.061L50.5401 34.201L50.5301 34.181ZM17.7401 32.181H49.9701C50.8401 31.531 51.0401 30.421 50.6601 29.701C50.3001 29.031 49.4501 28.661 48.6001 28.801H48.4401L40.4101 28.811L40.2601 27.991C40.2401 27.861 39.6401 24.741 36.6601 23.461C33.8401 22.241 31.3101 23.701 31.0301 23.871L30.2801 24.331L29.7301 23.641C28.2601 21.801 26.0901 20.671 23.7601 20.541C21.6501 20.421 19.6401 21.141 18.2801 22.501C15.8501 24.931 15.6601 29.101 17.7501 32.181H17.7401Z"
      fill="#303030"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.45}
    >
      <path
        d="M68.2901 9.4707C78.8301 37.4707 89.3701 65.4707 99.9101 93.4707C100 93.4707 101.44 93.4207 102.19 92.1807C102.87 91.0607 102.43 89.9107 102.38 89.7807C92.3301 63.4907 82.2801 37.2007 72.2301 10.9107C72.0901 10.0307 71.4801 9.2907 70.6401 9.0107C69.8301 8.7307 68.9301 8.9207 68.2901 9.4807V9.4707Z"
        fill="#9A7BAA"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.86}
    >
      <path
        d="M26.9301 42.6684C28.4301 45.2484 29.9401 47.8384 31.4401 50.4184C31.8801 51.1784 31.8101 52.1384 31.2701 52.8284L29.3501 55.2484C28.7501 55.9984 28.6701 57.0484 29.1501 57.8784L36.1501 70.1084C36.6501 70.9784 36.5001 72.0584 35.8001 72.7684L31.9501 76.6184C31.2501 77.3184 31.1101 78.3984 31.6101 79.2584L39.8401 93.4284H46.4001C43.2301 87.7084 40.0601 81.9884 36.8901 76.2684C36.8301 76.1684 36.7501 76.0084 36.6801 75.8084C36.5301 75.4084 36.4101 75.0684 36.5401 74.7384C36.6801 74.3684 37.0701 74.2084 37.2201 74.1484C39.2301 74.1484 41.2501 74.1684 43.2601 74.1684C39.9101 68.2584 36.5701 62.3584 33.2201 56.4484C33.1401 56.3484 32.9901 56.1284 32.9801 55.8384C32.9801 55.3884 33.3301 54.9584 33.8701 54.7784C35.4301 54.7784 36.9901 54.7984 38.5501 54.7984C35.5001 49.4184 32.4501 44.0384 29.4001 38.6484C28.5801 39.9884 27.7601 41.3284 26.9401 42.6684H26.9301Z"
        fill="#E5D247"
      />
    </g>
    <path
      d="M27.5301 93.9313C27.6501 88.3013 31.7001 83.7313 36.5501 83.0613C42.3301 82.2513 48.3601 87.0813 48.6601 93.9313H27.5301Z"
      fill="#C8A8CA"
    />
    <path
      d="M49.7101 94.9294H26.5101L26.5301 93.9094C26.6601 87.9294 30.9101 82.8394 36.4101 82.0694C39.4201 81.6494 42.6101 82.6094 45.1301 84.7094C47.8501 86.9794 49.5001 90.3194 49.6501 93.8894L49.6901 94.9294H49.7101ZM28.6101 92.9294H47.5701C47.2001 90.3194 45.8801 87.9294 43.8601 86.2494C41.7701 84.5094 39.1601 83.7094 36.7001 84.0494C32.4701 84.6394 29.1301 88.3794 28.6101 92.9294Z"
      fill="#303030"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.45}
    >
      <path
        d="M68.9501 69.84L60.9001 82.78C59.9301 82.89 57.1901 83.35 54.8101 85.6C51.6301 88.61 51.5001 92.54 51.4901 93.29C59.0401 93.31 66.6001 93.34 74.1501 93.36L73.4501 68.75L68.9501 69.83V69.84Z"
        fill="#9A7BAA"
      />
    </g>
    <path
      d="M89.0901 94.3508L81.9401 81.7108H86.9701L79.6701 68.8108H83.8001L76.5901 56.0508L68.9701 68.8108H73.4101L65.7101 81.7108H71.1401L63.6001 94.3508H89.0901Z"
      fill="#FAFF00"
    />
    <path
      d="M89.0901 95.3495H63.6001C63.2401 95.3495 62.9101 95.1595 62.7301 94.8395C62.5501 94.5295 62.5601 94.1395 62.7401 93.8295L69.3801 82.6995H65.7001C65.3401 82.6995 65.0101 82.5095 64.8301 82.1895C64.6501 81.8795 64.6601 81.4895 64.8401 81.1795L71.6401 69.7895H68.9701C68.6101 69.7895 68.2801 69.5995 68.1001 69.2795C67.9201 68.9695 67.9301 68.5795 68.1101 68.2695L75.7301 55.5095C75.9101 55.1995 76.2801 55.0295 76.6001 55.0195C76.9601 55.0195 77.2801 55.2195 77.4601 55.5295L84.6801 68.2895C84.8601 68.5995 84.8501 68.9795 84.6801 69.2895C84.5001 69.5995 84.1701 69.7895 83.8201 69.7895H81.4001L87.8501 81.1995C88.0301 81.5095 88.0201 81.8895 87.8501 82.1995C87.6701 82.5095 87.3401 82.6995 86.9901 82.6995H83.6801L89.9801 93.8495C90.1601 94.1595 90.1501 94.5395 89.9801 94.8495C89.8001 95.1595 89.4701 95.3495 89.1201 95.3495H89.0901ZM65.3601 93.3495H87.3801L81.0801 82.1995C80.9001 81.8895 80.9101 81.5095 81.0801 81.1995C81.2601 80.8895 81.5901 80.6995 81.9401 80.6995H85.2501L78.8001 69.2895C78.6201 68.9795 78.6301 68.5995 78.8001 68.2895C78.9801 67.9795 79.3101 67.7895 79.6601 67.7895H82.0801L76.5501 58.0195L70.7201 67.7895H73.3901C73.7501 67.7895 74.0801 67.9795 74.2601 68.2995C74.4401 68.6095 74.4301 68.9995 74.2501 69.3095L67.4501 80.6995H71.1301C71.4901 80.6995 71.8201 80.8895 72.0001 81.2095C72.1801 81.5195 72.1701 81.9095 71.9901 82.2195L65.3501 93.3495H65.3601Z"
      fill="#303030"
    />
    <path
      d="M55.6303 94.2231C55.7203 89.9331 58.8103 86.4531 62.5003 85.9431C66.9003 85.3231 71.5003 89.0031 71.7203 94.2231H55.6303Z"
      fill="#C8A8CA"
    />
    <path
      d="M72.7703 95.2213H54.6104L54.6304 94.2013C54.7304 89.5313 58.0504 85.5513 62.3604 84.9513C64.7304 84.6213 67.2104 85.3713 69.1904 87.0213C71.3104 88.7913 72.6004 91.4013 72.7204 94.1813L72.7604 95.2213H72.7703ZM56.7204 93.2213H70.6104C70.2904 91.4013 69.3304 89.7413 67.9104 88.5513C66.3704 87.2613 64.4404 86.6813 62.6404 86.9313C59.6104 87.3613 57.2004 89.9813 56.7204 93.2213Z"
      fill="#303030"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.86}
    >
      <path
        d="M74.9601 60.6291C75.9301 62.2991 76.9001 63.9691 77.8701 65.6391C78.1601 66.1291 78.1101 66.7491 77.7601 67.1991L76.5201 68.7591C76.1301 69.2491 76.0801 69.9191 76.3901 70.4591L80.9101 78.3591C81.2301 78.9191 81.1401 79.6191 80.6801 80.0791L78.1901 82.5691C77.7401 83.0191 77.6501 83.7191 77.9701 84.2691L83.2901 93.4291H87.5301C85.4201 89.7591 83.3101 86.0891 81.2001 82.4191C81.1601 82.3691 80.8801 81.9991 80.9901 81.5191C81.0901 81.1091 81.4401 80.7891 81.8901 80.7191H85.2401L78.9401 69.5091C78.9401 69.5091 78.5601 69.0491 78.7401 68.5091C78.8601 68.1391 79.1901 67.8691 79.5801 67.8191H82.1201L76.5601 58.0391C76.0301 58.8991 75.5001 59.7691 74.9701 60.6291H74.9601Z"
        fill="#E5D247"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.45}
    >
      <path
        d="M30.9901 86.2788C31.6801 86.2788 37.4501 86.3088 40.0401 90.4388C40.4301 91.0588 40.8401 91.9088 41.0601 93.0388C43.1501 93.0188 45.2501 93.0088 47.3401 92.9888C47.2701 92.2788 46.8301 88.5888 43.5901 86.0188C43.3301 85.8088 39.5001 82.8688 34.8101 84.1788C32.9601 84.6888 31.6801 85.6688 30.9901 86.2888V86.2788Z"
        fill="#9A7BAA"
      />
    </g>
    <path
      d="M43.1098 93.9341C43.1998 89.6441 46.2898 86.1641 49.9798 85.6541C54.3798 85.0341 58.9798 88.7141 59.1998 93.9341H43.1098Z"
      fill="#C8A8CA"
    />
    <path
      d="M60.2498 94.9322H42.0898L42.1098 93.9122C42.2098 89.2422 45.5298 85.2622 49.8398 84.6622C52.1998 84.3322 54.6898 85.0822 56.6698 86.7322C58.7898 88.5022 60.0798 91.1122 60.1998 93.8922L60.2398 94.9322H60.2498ZM44.1998 92.9322H58.0898C57.7698 91.1122 56.8098 89.4522 55.3898 88.2622C53.8498 86.9822 51.9298 86.3922 50.1198 86.6422C47.0898 87.0722 44.6798 89.6922 44.1998 92.9322Z"
      fill="#303030"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.45}
    >
      <path
        d="M46.6401 88.2422C47.1301 88.2422 51.1701 88.2622 52.9901 91.1622C53.2601 91.5922 53.5501 92.1922 53.7101 92.9822C55.1801 92.9722 56.6501 92.9622 58.1201 92.9422C58.0701 92.4422 57.7601 89.8522 55.4801 88.0522C55.3001 87.9122 52.6101 85.8422 49.3101 86.7622C48.0101 87.1222 47.1101 87.8122 46.6301 88.2422H46.6401Z"
        fill="#9A7BAA"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.45}
    >
      <path
        d="M58.9701 88.6921C59.4601 88.6821 63.6601 88.6221 65.5101 91.5321C65.7701 91.9421 66.0401 92.5021 66.2001 93.2221C67.6801 93.2221 69.1701 93.2221 70.6501 93.2221C70.5501 92.6121 70.0801 90.1921 67.8701 88.4721C67.4501 88.1421 65.0101 86.3221 61.9601 87.0621C60.4801 87.4221 59.4701 88.2321 58.9801 88.7021L58.9701 88.6921Z"
        fill="#9A7BAA"
      />
    </g>
    <path
      d="M39.8401 48.3294C41.4201 50.2594 42.9901 52.1894 44.5701 54.1194L46.9301 50.1894C47.2801 49.6094 48.0001 49.3894 48.6101 49.6794L49.2301 49.9694C49.8801 50.2794 50.6401 49.8894 50.7801 49.1894L52.0601 42.5294C51.5701 42.5594 50.8501 42.5594 50.0101 42.3894C47.9301 41.9694 47.6501 41.0394 46.4801 40.9794C44.5001 40.8794 42.7801 43.4194 41.4701 45.3594C40.6901 46.5194 40.1701 47.5694 39.8501 48.3194L39.8401 48.3294Z"
      fill="#FAFF00"
    />
    <path
      d="M44.5701 55.1193C44.2701 55.1193 43.9901 54.9893 43.8001 54.7493L39.0701 48.9593C38.8301 48.6693 38.7801 48.2693 38.9301 47.9293C39.4001 46.8393 39.9801 45.7893 40.6401 44.7993C42.0601 42.7093 43.9601 39.8493 46.5301 39.9893C47.3301 40.0293 47.8601 40.3593 48.3301 40.6493C48.7901 40.9393 49.2701 41.2293 50.2001 41.4193C50.7801 41.5393 51.3801 41.5793 51.9901 41.5393C52.3001 41.5293 52.6001 41.6493 52.8001 41.8793C53.0001 42.1093 53.0901 42.4293 53.0301 42.7293L51.7501 49.3893C51.6301 50.0293 51.2201 50.5693 50.6401 50.8593C50.0601 51.1593 49.3801 51.1593 48.7901 50.8793L48.1701 50.5893C48.0301 50.5193 47.8601 50.5693 47.7801 50.7093L45.4201 54.6393C45.2501 54.9193 44.9601 55.0993 44.6301 55.1193C44.6101 55.1193 44.5801 55.1193 44.5601 55.1193H44.5701ZM41.0101 48.1793L44.4501 52.3893L46.0701 49.6793C46.6801 48.6593 47.9601 48.2693 49.0401 48.7893L49.6601 49.0793L49.8001 49.0093L50.8601 43.5293C50.5101 43.4993 50.1601 43.4493 49.8201 43.3793C48.5301 43.1193 47.8101 42.6793 47.2901 42.3493C46.8901 42.0993 46.7101 41.9993 46.4401 41.9893C45.0501 41.9393 43.3901 44.3293 42.3101 45.9293C41.8201 46.6493 41.3901 47.3993 41.0201 48.1793H41.0101Z"
      fill="#303030"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.45}
    >
      <path
        d="M16.6901 30.2188C28.1001 30.2587 39.5101 30.2888 50.9101 30.3288C50.9101 30.5388 50.8701 30.8888 50.7001 31.2688C50.4801 31.7688 50.1401 32.0687 49.9601 32.2087C39.2101 32.2087 28.4701 32.2087 17.7201 32.2087C17.5701 31.9787 17.4201 31.7287 17.2701 31.4587C17.0401 31.0287 16.8401 30.6188 16.6801 30.2388L16.6901 30.2188Z"
        fill="#9A7BAA"
      />
    </g>
    <path
      d="M5.79499 35.8349C4.36003 33.7016 4.92095 30.8054 7.04785 29.3661L49.2641 0.797405C51.391 -0.641914 54.2785 -0.0792898 55.7134 2.05406L65.205 16.1651C66.64 18.2984 66.0791 21.1946 63.9522 22.6339L21.7359 51.2026C19.609 52.6419 16.7215 52.0793 15.2866 49.9459L5.79499 35.8349Z"
      fill="#C12525"
    />
    <path
      d="M5.79499 35.8349C4.36003 33.7016 4.92095 30.8054 7.04785 29.3661L49.2641 0.797405C51.391 -0.641914 54.2785 -0.0792898 55.7134 2.05406L65.205 16.1651C66.64 18.2984 66.0791 21.1946 63.9522 22.6339L21.7359 51.2026C19.609 52.6419 16.7215 52.0793 15.2866 49.9459L5.79499 35.8349Z"
      stroke="#303030"
    />
    <path
      d="M22.5796 45.1281L12.2002 29.513L15.6648 27.2101L20.2815 34.1556L23.7623 31.8418L19.1457 24.8964L22.6103 22.5935L32.9896 38.2086L29.525 40.5115L24.9084 33.566L21.4275 35.8797L26.0442 42.8252L22.5796 45.1281ZM29.5314 17.993L33.0122 15.6793C33.9611 15.0486 35.0014 14.8496 36.1332 15.0823C37.2705 15.3113 38.1527 15.8976 38.7798 16.841L44.5533 25.5269C45.1767 26.4649 45.3758 27.5052 45.1503 28.6479C44.9266 29.7816 44.3404 30.6637 43.3916 31.2944L39.9107 33.6082C38.9619 34.2388 37.9206 34.4424 36.787 34.2187C35.6551 33.986 34.7739 33.3953 34.1432 32.4465L28.3697 23.7606C27.739 22.8117 27.5373 21.7732 27.7645 20.645C27.9936 19.5077 28.5825 18.6237 29.5314 17.993ZM31.8343 21.4576L37.6078 30.1435C37.9177 30.6098 38.3543 30.902 38.9175 31.0202C39.4861 31.1347 40.0117 31.0316 40.4943 30.7109C40.9768 30.3901 41.2727 29.9472 41.3818 29.3822C41.4963 28.8136 41.3986 28.2961 41.0887 27.8298L35.3152 19.1439C35.0016 18.6722 34.5596 18.3836 33.9892 18.2781C33.4205 18.1636 32.9004 18.2631 32.4287 18.5766C31.9515 18.8938 31.6557 19.3366 31.5412 19.9053C31.423 20.4685 31.5207 20.9859 31.8343 21.4576ZM52.0368 25.5479L42.8143 11.6733L39.3497 13.9762L38.1929 12.2357L48.603 5.31619L49.7598 7.05662L46.2952 9.35954L55.5177 23.2342L52.0368 25.5479Z"
      fill="white"
    />
  </svg>
);
