export const IconSurpriseNotNew = () => (
  <svg width={161} height={158} viewBox="0 0 161 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M125.612 14.975L88.059 36.66a3.243 3.243 0 00-1.187 4.43l.543.94a3.242 3.242 0 004.429 1.187l37.553-21.686a3.243 3.243 0 001.186-4.43l-.543-.94a3.24 3.24 0 00-4.428-1.187z"
      fill="#B48EC0"
    />
    <path
      d="M90.236 45.519a5.35 5.35 0 01-1.33-.178 5.118 5.118 0 01-3.096-2.368l-.55-.956a5.015 5.015 0 01-.503-3.86 5.12 5.12 0 012.367-3.096l37.559-21.694a5.012 5.012 0 013.858-.503 5.118 5.118 0 013.096 2.367l.551.957a5.012 5.012 0 01.502 3.859 5.116 5.116 0 01-2.366 3.097l-37.56 21.694a5.017 5.017 0 01-2.528.681zm36.975-29.12c-.243 0-.47.064-.68.178l-37.56 21.694a1.36 1.36 0 00-.648.843c-.097.357-.048.73.13 1.054l.55.957c.18.324.487.551.844.649.357.097.73.048 1.053-.13l37.56-21.694a1.36 1.36 0 00.648-.844c.097-.356.049-.73-.13-1.054l-.551-.956a1.359 1.359 0 00-.843-.649 1.38 1.38 0 00-.373-.048z"
      fill="#303030"
    />
    <path
      d="M31.102 21.02l37.553 21.686a3.242 3.242 0 004.428-1.187l.543-.941a3.243 3.243 0 00-1.186-4.43L34.887 14.462a3.242 3.242 0 00-4.429 1.187l-.543.94a3.243 3.243 0 001.187 4.43z"
      fill="#B48EC0"
    />
    <path
      d="M70.265 45c-.875 0-1.75-.228-2.529-.682L30.177 22.624a5.074 5.074 0 01-2.367-3.097 5.015 5.015 0 01.503-3.859l.551-.956a5.073 5.073 0 013.096-2.368 5.012 5.012 0 013.858.503l37.56 21.694a5.074 5.074 0 012.366 3.097 5.014 5.014 0 01-.502 3.86l-.552.956a5.073 5.073 0 01-3.096 2.367 5.35 5.35 0 01-1.329.178zM33.273 15.878c-.13 0-.243.016-.373.049a1.36 1.36 0 00-.843.648l-.55.957a1.398 1.398 0 00-.13 1.054c.097.357.324.665.648.843l37.56 21.694c.323.178.68.227 1.053.13a1.36 1.36 0 00.843-.649l.55-.956c.18-.325.228-.681.13-1.054a1.36 1.36 0 00-.648-.843L33.954 16.057a1.422 1.422 0 00-.68-.178z"
      fill="#303030"
    />
    <path
      d="M106.851 4.189c-1.556-1.346-3.517-2.06-5.69-2.06-5.495 0-12.141 4.557-17.36 11.902-7.49 10.523-8.868 22.148-3.21 27.06 1.556 1.347 3.517 2.06 5.69 2.06 5.495 0 12.141-4.556 17.361-11.901 7.489-10.523 8.867-22.148 3.209-27.061zM98.795 27.05c-4.41 6.194-10.261 9.242-13.066 6.81-2.804-2.432-1.507-9.436 2.902-15.63 4.41-6.194 10.261-9.242 13.065-6.81 2.805 2.432 1.508 9.437-2.901 15.63z"
      fill="#B48EC0"
    />
    <path
      d="M86.28 45.016c-2.61 0-4.992-.875-6.889-2.513-6.484-5.626-5.252-18.046 2.918-29.542C87.87 5.146 95.1.282 101.178.282c2.61 0 4.993.876 6.889 2.513 6.484 5.626 5.252 18.046-2.918 29.526C99.589 40.136 92.359 45 86.281 45v.015zM101.179 3.98c-4.83 0-11.056 4.361-15.854 11.123-6.808 9.582-8.348 20.38-3.501 24.596 1.216 1.054 2.756 1.605 4.474 1.605 4.83 0 11.055-4.361 15.853-11.122 6.809-9.583 8.349-20.381 3.502-24.597-1.216-1.054-2.756-1.605-4.474-1.605zM88.161 36.553c-1.394 0-2.642-.438-3.615-1.297-3.615-3.146-2.496-10.929 2.61-18.095 3.372-4.735 7.7-7.945 11.315-8.367 1.734-.21 3.29.227 4.458 1.233 3.614 3.145 2.496 10.928-2.61 18.094-3.372 4.735-7.7 7.945-11.315 8.367a7.567 7.567 0 01-.843.048v.017zm11.153-24.11c-.13 0-.26 0-.406.032-2.107.243-5.625 2.464-8.737 6.842-4.344 6.097-4.782 11.772-3.193 13.15.13.113.55.486 1.605.356 2.107-.243 5.624-2.464 8.737-6.842 4.344-6.096 4.782-11.771 3.193-13.15-.113-.097-.437-.388-1.183-.388h-.016z"
      fill="#303030"
    />
    <path
      d="M57.508 31.266c5.22 7.345 11.882 11.9 17.36 11.9 2.157 0 4.134-.713 5.69-2.058 5.658-4.913 4.28-16.539-3.21-27.062-5.219-7.345-11.881-11.9-17.36-11.9-2.156 0-4.134.713-5.69 2.058-5.657 4.913-4.28 16.539 3.21 27.062zm1.945-19.83c2.804-2.432 8.656.616 13.065 6.81 4.41 6.194 5.706 13.182 2.902 15.63-2.805 2.432-8.657-.616-13.066-6.81-4.409-6.194-5.706-13.182-2.901-15.63z"
      fill="#B48EC0"
    />
    <path
      d="M74.869 45.016c-6.08 0-13.309-4.864-18.87-12.68-8.17-11.48-9.401-23.899-2.917-29.525 1.897-1.654 4.28-2.513 6.89-2.513 6.078 0 13.308 4.864 18.868 12.679 8.17 11.48 9.402 23.9 2.918 29.542-1.897 1.637-4.28 2.513-6.89 2.513v-.016zm-15.854-14.82c4.814 6.761 11.04 11.123 15.854 11.123 1.718 0 3.258-.551 4.474-1.605 4.846-4.216 3.306-15.014-3.502-24.597C71.027 8.356 64.802 3.994 59.988 3.994c-1.719 0-3.259.552-4.474 1.606-4.847 4.215-3.307 15.014 3.501 24.596zm13.99 6.372c-.276 0-.552-.016-.843-.048-3.615-.438-7.944-3.649-11.315-8.367-5.107-7.183-6.225-14.95-2.61-18.095 1.167-1.021 2.723-1.443 4.458-1.232 3.615.438 7.943 3.648 11.314 8.367 5.107 7.182 6.225 14.949 2.61 18.094-.989.86-2.22 1.297-3.615 1.297v-.016zm-12.337-23.72c-1.588 1.377-1.15 7.052 3.194 13.149 3.112 4.378 6.63 6.599 8.737 6.842 1.038.114 1.46-.243 1.605-.357 1.589-1.378 1.135-7.053-3.194-13.15-3.112-4.377-6.63-6.598-8.737-6.841-1.037-.13-1.459.243-1.605.356z"
      fill="#303030"
    />
    <path
      d="M135.722 55.442H27.097c-7.583 0-13.73 6.149-13.73 13.733v72.753c0 7.584 6.147 13.733 13.73 13.733h108.625c7.583 0 13.73-6.149 13.73-13.733V69.175c0-7.584-6.147-13.733-13.73-13.733z"
      fill="#B48EC0"
    />
    <path
      d="M135.722 157.282H27.097c-8.462 0-15.35-6.89-15.35-15.354V69.176c0-8.464 6.888-15.355 15.35-15.355h108.625c8.462 0 15.351 6.891 15.351 15.355v72.752c0 8.464-6.889 15.354-15.351 15.354zM27.097 57.064c-6.678 0-12.109 5.431-12.109 12.112v72.752c0 6.68 5.43 12.112 12.11 12.112h108.624c6.679 0 12.109-5.432 12.109-12.112V69.176c0-6.68-5.43-12.112-12.109-12.112H27.097z"
      fill="#303030"
    />
    <path
      d="M149.938 39.6H11.081c-5.219 0-9.45 4.233-9.45 9.453v22.019c0 5.22 4.231 9.452 9.45 9.452h138.857c5.22 0 9.451-4.232 9.451-9.452V49.053c0-5.22-4.231-9.453-9.451-9.453z"
      fill="#B48EC0"
    />
    <path
      d="M149.938 82.146H11.081C4.986 82.146.01 77.185.01 71.072V49.053c0-6.096 4.96-11.074 11.071-11.074h138.857c6.095 0 11.072 4.962 11.072 11.074v22.019c0 6.096-4.961 11.074-11.072 11.074zM11.081 41.222c-4.312 0-7.83 3.518-7.83 7.831v22.019c0 4.313 3.502 7.831 7.83 7.831h138.857c4.312 0 7.83-3.518 7.83-7.831V49.053c0-4.313-3.518-7.831-7.83-7.831H11.081z"
      fill="#303030"
    />
    <path
      d="M138.786 82.113v60.284l-126.18-.341c2.804 3.94 5.592 7.88 8.396 11.804l113.699.486c1.394-.016 4.701-.259 7.862-2.513 5.349-3.81 5.706-10.133 5.754-10.993V80.524l-9.531 1.59zM147.166 40.996l.308 29.477c-48.776-.34-97.537-.68-146.313-1.037 1.442 3.323 2.885 6.631 4.312 9.955h142.909c.924 0 3.339-.114 5.755-1.703 2.755-1.8 3.728-4.345 4.02-5.156v-23.12c-.098-.957-.535-4.152-3.259-6.648a10.655 10.655 0 00-4.02-2.287c-1.232.179-2.48.34-3.712.52z"
      fill="#A579B2"
      opacity={0.33}
    />
    <path d="M94.061 39.618H67.98V155.37h26.082V39.618z" fill="#FAFF00" />
    <path d="M95.699 156.991H66.374V38.012H95.7v118.979zm-26.082-3.227h22.84V41.239h-22.84v112.525z" fill="#303030" />
    <path
      d="M74.22 62.77h-.016a1.33 1.33 0 00-1.33 1.33v68.763c0 .735.596 1.33 1.33 1.33h.016a1.33 1.33 0 001.33-1.33V64.1a1.33 1.33 0 00-1.33-1.33z"
      fill="#fff"
    />
    <path d="M86.864 41.093v114.405h5.495V39.633s-5.057 1.46-5.495 1.46z" fill="#EDD500" />
  </svg>
);
