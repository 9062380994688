import React, { useState } from 'react';
import { useApp } from '../contexts/AppContext';
import Row from '../components/Row';
import styled from 'styled-components';
import { doHapticFeedback } from '../lib/utils';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/Wrappers';
import { useEffectOnce } from 'react-use';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button, CreateButton, LeaderboardTypeButton, TabButton } from '../components/pages/Flocks/Buttons';
import FlockCard from '../components/pages/Flocks/FlockCard';

export const Header = styled.div`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 63.65px;
  text-align: center;

  text-transform: uppercase;
  margin: 40px 0;
`;

export const Text = styled.span`
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;

  width: 936px;
  margin: 0 auto 60px;
`;

export const GrayText = styled.span`
  color: #a4a4a4;
  font-family: Roboto, sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;

  width: 936px;
  margin: 0 auto 60px;
`;

const Flocks: React.FC = () => {
  const { app } = useApp();
  const [createPressed, setCreatePressed] = useState(false);
  const [tab, setTab] = useState<'new' | 'all-time'>('new');
  const { t } = useTranslation();

  useEffectOnce(() => {
    doHapticFeedback();
  });

  const showCreate = !createPressed && !app.flocks.flockOwn;
  const flocks = tab === 'new' ? app.flocks.flocksRecent : app.flocks.flocksTop;

  return (
    <PageContainer hcenter={false}>
      <Row margin={'-20px 0 20px'} gap="20px">
        <NavLink to="/leaderboard">
          <LeaderboardTypeButton>{t('flocks.single')}</LeaderboardTypeButton>
        </NavLink>
        <LeaderboardTypeButton disabled>{t('flocks.flocks')}</LeaderboardTypeButton>
      </Row>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Header>{t('flocks.joinOurTopCommunities')}</Header>
      </motion.div>
      <Text>{t('flocks.becomePartOfOurMostActiveCommunities')}</Text>
      <motion.div initial={{ x: -64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        {showCreate && (
          <Button disabled={!app.flocks.canOwnFlock} width={862} onClick={() => setCreatePressed(true)}>
            {t('flocks.createYourOwnCommunity')}
          </Button>
        )}
        {!showCreate && <CreateButton onClick={() => setCreatePressed(true)} />}
      </motion.div>
      {!app.flocks.flockOwn && (
        <GrayText>
          {t('flocks.ifYouAreMajorInfluencer')} <Text>{t('flocks.fiveHundredsInvitedFriends')}</Text> (L1).
        </GrayText>
      )}
      <Row margin={'0 0 20px'} gap="400px">
        <TabButton onClick={() => setTab('new')} active={tab === 'new'}>
          {t('flocks.new')}
        </TabButton>
        <TabButton onClick={() => setTab('all-time')} active={tab === 'all-time'}>
          {t('flocks.top')}
        </TabButton>
      </Row>
      <div style={{ maxHeight: '584px', overflow: 'scroll' }}>
        {flocks.map((flock, index) => (
          <FlockCard key={flock.id} tab={tab} flock={flock} place={index + 1} length={flocks.length} />
        ))}
      </div>
    </PageContainer>
  );
};

export default Flocks;
