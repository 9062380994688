import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApp } from '../../contexts/AppContext';
import CloseIcon from './CloseIcon';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import Element = React.JSX.Element;

export const PopupUpgradeHeader = styled.div`
  text-align: center;
  font-size: 80px;
  font-weight: 500;
  font-family: var(--font), sans-serif;
  color: #535353;
  text-transform: uppercase;
  /* margin-bottom: 20px; */
  margin-top: 20px;
`;

const FixedContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
`;

const PopupOverlay = styled.div`
  width: 1080px;
  bottom: 0;
  left: 50%;
  margin-left: -540px;
  position: absolute;

  transform-origin: center 100%;
  transform: scale(var(--scale));
  min-height: calc(100vh / var(--scale));

  background: #00000088;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const PopupContainer = styled.div`
  border-radius: 60px;
  background: #e6d8ff;
  font-family: var(--font), sans-serif;
  color: #535353;
  padding: 60px;
  position: relative;
`;

export const CloseButton = styled.button<{ disabled?: boolean }>`
  min-width: 11ch;
  height: 100px;
  margin: 0 auto 0;

  background: ${({ disabled }) => (disabled ? 'gray' : '#00000094')};
  color: ${({ disabled }) => (disabled ? 'darkkhaki' : '#efff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? 'gray' : '#efff00')};

  font-family: var(--font), sans-serif;
  text-transform: uppercase;
  border-radius: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 42px;
  font-weight: 500;
  padding: 0 100px;
`;

const Popup = ({
  children,
  hideClose,
  close,
  buttonText,
  onConfirm,
  confirmDisabled,
}: {
  children: React.ReactNode;
  hideClose?: boolean;
  close?: string | Element;
  buttonText?: string | Element;
  onConfirm?: () => Promise<void | boolean>;
  confirmDisabled?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const { t } = useTranslation();

  const { clearEvent } = useApp();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleConfirm = async () => {
    let abortClose: void | boolean = false;
    if (onConfirm) {
      setConfirming(true);
      abortClose = await onConfirm();
      setConfirming(false);
    }
    if (!abortClose) handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    clearEvent();
  };

  return (
    <FixedContainer>
      <PopupOverlay onClick={handleClose}>
        <PopupContainer>
          {close && <CloseIcon onClick={handleClose} />}
          {children}
          {!hideClose && (
            <CloseButton disabled={confirmDisabled} onClick={handleConfirm}>
              {confirming ? <Loading /> : close || t('system.close')}
            </CloseButton>
          )}
        </PopupContainer>
      </PopupOverlay>
    </FixedContainer>
  );
};

export default Popup;
