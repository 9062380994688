export const IconCheckMark = () => (
  <svg
    width="70"
    height="68"
    viewBox="0 0 70 68"
    fill="none"
    className="iconCheckMark"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="69.336" height="67.392" rx="33.696" fill="#FAFF00" fillOpacity="0.6" />
    <rect
      x="0.486"
      y="0.486"
      width="68.364"
      height="66.42"
      rx="33.21"
      stroke="#EBEBEB"
      strokeOpacity="0.61"
      strokeWidth="0.972"
    />
    <path
      d="M54.8595 24.4305L32.5996 46.6903C31.5138 47.7762 29.7523 47.7762 28.6664 46.6903L20.9226 38.9465C19.7082 37.7322 19.6788 35.7137 20.9167 34.5229C22.117 33.3686 24.0246 33.3828 25.2072 34.5653L30.633 39.9912L50.5266 20.0976C51.7233 18.9009 53.6628 18.9009 54.8595 20.0976C56.0562 21.2943 56.0562 23.2338 54.8595 24.4305Z"
      fill="#575757"
    />
  </svg>
);
