import styled from 'styled-components';

const Frame4 = styled((props) => (
  <svg width={579} height={612} viewBox="0 0 579 612" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.12}
      d="M106.04 449.76L50.35 243.72C47.15 231.89 50.5098 219.25 59.1598 210.57L209.75 59.3202C218.4 50.6402 231.02 47.2202 242.86 50.3702L449.14 105.16C460.98 108.31 470.25 117.53 473.45 129.36L529.14 335.4C532.34 347.23 528.98 359.87 520.33 368.55L369.74 519.8C361.09 528.48 348.47 531.9 336.63 528.75L130.35 473.96C118.51 470.81 109.24 461.59 106.04 449.76Z"
      fill="#AD8EBC"
    />
    <path
      d="M97.3502 506.113L348.88 572.923C360.72 576.073 373.35 572.653 381.99 563.973L565.61 379.553C574.26 370.873 577.61 358.233 574.42 346.403L506.51 95.1731C503.31 83.3431 494.05 74.1132 482.2 70.9732L230.67 4.16315C218.83 1.01315 206.2 4.43315 197.56 13.1132L13.94 197.533C5.29001 206.213 1.93995 218.853 5.12995 230.683L73.0301 481.913C76.2301 493.743 85.4902 502.973 97.3402 506.113H97.3502ZM106.08 448.733L50.39 242.693C47.19 230.863 50.55 218.223 59.2 209.543L209.79 58.2932C218.44 49.6132 231.06 46.1931 242.9 49.3432L449.18 104.133C461.02 107.283 470.29 116.503 473.49 128.333L529.18 334.373C532.38 346.203 529.02 358.843 520.37 367.523L369.78 518.773C361.13 527.453 348.51 530.873 336.67 527.723L130.39 472.933C118.55 469.783 109.28 460.563 106.08 448.733Z"
      fill="#986EAB"
    />
    <path
      d="M357.63 577.062C354.45 577.062 351.25 576.652 348.1 575.822L96.57 509.012C83.73 505.602 73.5999 495.512 70.1399 482.692L2.22994 231.462C-1.24006 218.632 2.43002 204.822 11.81 195.412L195.43 10.9916C204.8 1.58162 218.6 -2.14838 231.44 1.26162L482.97 68.0716C495.81 71.4816 505.94 81.5716 509.4 94.3916L577.31 345.622C580.78 358.452 577.11 372.262 567.73 381.672L384.11 566.092C377.03 573.202 367.43 577.072 357.63 577.072V577.062ZM98.1101 503.212L349.64 570.022C360.41 572.882 371.99 569.752 379.86 561.852L563.48 377.432C571.35 369.532 574.42 357.942 571.52 347.182L503.61 95.9516C500.7 85.1916 492.2 76.7316 481.43 73.8616L229.9 7.05162C219.12 4.19162 207.55 7.32162 199.68 15.2216L16.06 199.642C8.19002 207.542 5.10998 219.132 8.01998 229.892L75.9299 481.122C78.8399 491.882 87.3401 500.342 98.1101 503.212ZM345.41 531.862C342.23 531.862 339.03 531.452 335.88 530.622L129.6 475.832C116.76 472.422 106.63 462.332 103.17 449.512L47.4799 243.472C44.0099 230.652 47.68 216.832 57.06 207.422L207.65 56.1716C217.02 46.7616 230.82 43.0316 243.66 46.4416L449.94 101.232C462.78 104.642 472.91 114.722 476.37 127.552L532.06 333.592C535.53 346.412 531.86 360.232 522.48 369.642L371.89 520.892C364.81 528.002 355.21 531.862 345.41 531.862ZM234.12 51.2016C225.89 51.2016 217.84 54.4516 211.9 60.4116L61.31 211.662C53.44 219.562 50.36 231.152 53.27 241.912L108.96 447.952C111.87 458.712 120.37 467.172 131.14 470.042L337.42 524.832C348.19 527.692 359.77 524.562 367.64 516.662L518.23 365.412C526.1 357.512 529.18 345.922 526.27 335.162L470.58 129.122C467.67 118.362 459.17 109.902 448.4 107.032L242.12 52.2416C239.48 51.5416 236.79 51.2016 234.12 51.2016Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.35}
    >
      <path
        d="M573.28 352.421C546.56 274.001 519.85 195.581 493.13 117.171C489.31 105.951 480.23 97.3209 468.84 94.0809C390.93 71.8709 313.03 49.6609 235.12 27.4509C223.64 24.1809 211.28 26.9509 202.29 34.8109C136.95 91.9709 71.61 149.121 6.27002 206.281L197.56 13.1009C200.82 10.8609 205.85 7.95092 212.51 6.03092C220.01 3.86092 226.5 3.84092 230.68 4.15092C314.52 26.4209 398.37 48.6909 482.21 70.9609C485.2 71.7809 491.7 73.9809 497.62 79.9009C503.48 85.7609 505.69 92.1709 506.52 95.1609C528.78 180.911 551.03 266.671 573.29 352.421H573.28Z"
        fill="#986EAB"
      />
    </g>
    <path
      d="M334.566 556.882C338.482 557.931 342.507 555.607 343.556 551.692C344.605 547.776 342.281 543.751 338.366 542.702L183.451 501.192C179.535 500.143 175.51 502.467 174.461 506.383C173.412 510.298 175.735 514.323 179.651 515.372L334.566 556.882Z"
      fill="white"
    />
    <path
      d="M156.22 509.499C160.135 510.548 164.16 508.224 165.209 504.309C166.258 500.393 163.935 496.368 160.019 495.319L106.429 480.96C102.514 479.911 98.489 482.234 97.4398 486.15C96.3906 490.066 98.7143 494.09 102.63 495.139L156.22 509.499Z"
      fill="white"
    />
    <path
      d="M280.25 169.95C280.25 187.83 265.75 202.33 247.87 202.33C229.99 202.33 215.49 187.83 215.49 169.95C215.49 152.07 229.99 137.57 247.87 137.57C265.75 137.57 280.25 152.07 280.25 169.95Z"
      fill="#FBFF54"
    />
    <path
      d="M247.87 204.819C228.64 204.819 212.99 189.169 212.99 169.939C212.99 150.709 228.64 135.059 247.87 135.059C267.1 135.059 282.75 150.709 282.75 169.939C282.75 189.169 267.1 204.819 247.87 204.819ZM247.87 140.069C231.4 140.069 217.99 153.469 217.99 169.949C217.99 186.429 231.39 199.829 247.87 199.829C264.35 199.829 277.75 186.429 277.75 169.949C277.75 153.469 264.35 140.069 247.87 140.069Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M247.87 137.57C246.66 137.57 245.48 137.64 244.3 137.77C260.5 139.55 273.11 153.27 273.11 169.95C273.11 186.63 260.5 200.35 244.3 202.13C245.47 202.26 246.66 202.33 247.87 202.33C265.75 202.33 280.25 187.83 280.25 169.95C280.25 152.07 265.75 137.57 247.87 137.57Z"
        fill="black"
      />
    </g>
    <path
      d="M440.34 267.083C440.34 284.963 425.84 299.463 407.96 299.463C390.08 299.463 375.58 284.963 375.58 267.083C375.58 249.203 390.08 234.703 407.96 234.703C425.84 234.703 440.34 249.203 440.34 267.083Z"
      fill="#FBFF54"
    />
    <path
      d="M407.97 301.951C388.74 301.951 373.09 286.301 373.09 267.071C373.09 247.841 388.74 232.191 407.97 232.191C427.2 232.191 442.85 247.841 442.85 267.071C442.85 286.301 427.2 301.951 407.97 301.951ZM407.97 237.201C391.5 237.201 378.09 250.601 378.09 267.081C378.09 283.561 391.49 296.961 407.97 296.961C424.45 296.961 437.85 283.561 437.85 267.081C437.85 250.601 424.45 237.201 407.97 237.201Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M407.97 234.703C406.76 234.703 405.58 234.773 404.4 234.903C420.6 236.683 433.21 250.403 433.21 267.083C433.21 283.763 420.6 297.483 404.4 299.263C405.57 299.393 406.76 299.463 407.97 299.463C425.85 299.463 440.35 284.963 440.35 267.083C440.35 249.203 425.85 234.703 407.97 234.703Z"
        fill="black"
      />
    </g>
    <path
      d="M167.09 187.091C167.09 204.971 152.59 219.471 134.71 219.471C116.83 219.471 102.33 204.971 102.33 187.091C102.33 169.211 116.83 154.711 134.71 154.711C152.59 154.711 167.09 169.211 167.09 187.091Z"
      fill="#FBFF54"
    />
    <path
      d="M134.71 221.971C115.48 221.971 99.8301 206.321 99.8301 187.091C99.8301 167.861 115.48 152.211 134.71 152.211C153.94 152.211 169.59 167.861 169.59 187.091C169.59 206.321 153.94 221.971 134.71 221.971ZM134.71 157.211C118.23 157.211 104.83 170.611 104.83 187.091C104.83 203.571 118.23 216.971 134.71 216.971C151.19 216.971 164.59 203.571 164.59 187.091C164.59 170.611 151.19 157.211 134.71 157.211Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M134.71 154.711C133.5 154.711 132.32 154.781 131.14 154.911C147.34 156.691 159.95 170.411 159.95 187.091C159.95 203.771 147.34 217.491 131.14 219.271C132.31 219.401 133.5 219.471 134.71 219.471C152.59 219.471 167.09 204.971 167.09 187.091C167.09 169.211 152.59 154.711 134.71 154.711Z"
        fill="black"
      />
    </g>
    <path
      d="M264.67 409.591C264.67 427.471 250.17 441.971 232.29 441.971C214.41 441.971 199.91 427.471 199.91 409.591C199.91 391.711 214.41 377.211 232.29 377.211C250.17 377.211 264.67 391.711 264.67 409.591Z"
      fill="#FBFF54"
    />
    <path
      d="M232.29 444.471C213.06 444.471 197.41 428.821 197.41 409.591C197.41 390.361 213.06 374.711 232.29 374.711C251.52 374.711 267.17 390.361 267.17 409.591C267.17 428.821 251.52 444.471 232.29 444.471ZM232.29 379.721C215.82 379.721 202.41 393.121 202.41 409.601C202.41 426.081 215.81 439.481 232.29 439.481C248.77 439.481 262.17 426.081 262.17 409.601C262.17 393.121 248.77 379.721 232.29 379.721Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M232.29 377.211C231.08 377.211 229.9 377.281 228.72 377.411C244.92 379.191 257.53 392.911 257.53 409.591C257.53 426.271 244.92 439.991 228.72 441.771C229.89 441.901 231.08 441.971 232.29 441.971C250.17 441.971 264.67 427.471 264.67 409.591C264.67 391.711 250.17 377.211 232.29 377.211Z"
        fill="black"
      />
    </g>
    <path
      d="M234.53 117.989C234.53 135.869 220.03 150.369 202.15 150.369C184.27 150.369 169.77 135.869 169.77 117.989C169.77 100.109 184.27 85.6094 202.15 85.6094C220.03 85.6094 234.53 100.109 234.53 117.989Z"
      fill="#E9D647"
    />
    <path
      d="M202.15 152.869C182.92 152.869 167.27 137.219 167.27 117.989C167.27 98.7594 182.92 83.1094 202.15 83.1094C221.38 83.1094 237.03 98.7594 237.03 117.989C237.03 137.219 221.38 152.869 202.15 152.869ZM202.15 88.1194C185.68 88.1194 172.27 101.519 172.27 117.999C172.27 134.479 185.67 147.879 202.15 147.879C218.63 147.879 232.03 134.479 232.03 117.999C232.03 101.519 218.63 88.1194 202.15 88.1194Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M202.15 85.6211C200.94 85.6211 199.76 85.6911 198.58 85.8211C214.78 87.6011 227.39 101.321 227.39 118.001C227.39 134.681 214.78 148.401 198.58 150.181C199.75 150.311 200.94 150.381 202.15 150.381C220.03 150.381 234.53 135.881 234.53 118.001C234.53 100.121 220.03 85.6211 202.15 85.6211Z"
        fill="black"
      />
    </g>
    <path
      d="M225.47 257.482C225.47 275.362 210.97 289.862 193.09 289.862C175.21 289.862 160.71 275.362 160.71 257.482C160.71 239.602 175.21 225.102 193.09 225.102C210.97 225.102 225.47 239.602 225.47 257.482Z"
      fill="#986EAB"
    />
    <path
      d="M193.1 292.35C173.87 292.35 158.22 276.7 158.22 257.47C158.22 238.24 173.87 222.59 193.1 222.59C212.33 222.59 227.98 238.24 227.98 257.47C227.98 276.7 212.33 292.35 193.1 292.35ZM193.1 227.6C176.63 227.6 163.22 241 163.22 257.48C163.22 273.96 176.62 287.36 193.1 287.36C209.58 287.36 222.98 273.96 222.98 257.48C222.98 241 209.58 227.6 193.1 227.6Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M193.1 225.102C191.89 225.102 190.71 225.172 189.53 225.302C205.73 227.082 218.34 240.802 218.34 257.482C218.34 274.162 205.73 287.882 189.53 289.662C190.7 289.792 191.89 289.862 193.1 289.862C210.98 289.862 225.48 275.362 225.48 257.482C225.48 239.602 210.98 225.102 193.1 225.102Z"
        fill="black"
      />
    </g>
    <path
      d="M295.8 469.989C295.8 487.869 281.3 502.369 263.42 502.369C245.54 502.369 231.04 487.869 231.04 469.989C231.04 452.109 245.54 437.609 263.42 437.609C281.3 437.609 295.8 452.109 295.8 469.989Z"
      fill="#986EAB"
    />
    <path
      d="M263.42 504.869C244.19 504.869 228.54 489.219 228.54 469.989C228.54 450.759 244.19 435.109 263.42 435.109C282.65 435.109 298.3 450.759 298.3 469.989C298.3 489.219 282.65 504.869 263.42 504.869ZM263.42 440.109C246.95 440.109 233.54 453.509 233.54 469.989C233.54 486.469 246.94 499.869 263.42 499.869C279.9 499.869 293.3 486.469 293.3 469.989C293.3 453.509 279.9 440.109 263.42 440.109Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M263.42 437.621C262.21 437.621 261.03 437.691 259.85 437.821C276.05 439.601 288.66 453.321 288.66 470.001C288.66 486.681 276.05 500.401 259.85 502.181C261.02 502.311 262.21 502.381 263.42 502.381C281.3 502.381 295.8 487.881 295.8 470.001C295.8 452.121 281.3 437.621 263.42 437.621Z"
        fill="black"
      />
    </g>
    <path
      d="M347.75 255.411C347.75 273.291 333.25 287.791 315.37 287.791C297.49 287.791 282.99 273.291 282.99 255.411C282.99 237.531 297.49 223.031 315.37 223.031C333.25 223.031 347.75 237.531 347.75 255.411Z"
      fill="#986EAB"
    />
    <path
      d="M315.38 290.28C296.15 290.28 280.5 274.63 280.5 255.4C280.5 236.17 296.15 220.52 315.38 220.52C334.61 220.52 350.26 236.17 350.26 255.4C350.26 274.63 334.61 290.28 315.38 290.28ZM315.38 225.53C298.9 225.53 285.5 238.93 285.5 255.41C285.5 271.89 298.9 285.29 315.38 285.29C331.86 285.29 345.26 271.89 345.26 255.41C345.26 238.93 331.86 225.53 315.38 225.53Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M315.38 223.031C314.17 223.031 312.99 223.101 311.81 223.231C328.01 225.011 340.62 238.731 340.62 255.411C340.62 272.091 328.01 285.811 311.81 287.591C312.98 287.721 314.17 287.791 315.38 287.791C333.26 287.791 347.76 273.291 347.76 255.411C347.76 237.531 333.26 223.031 315.38 223.031Z"
        fill="black"
      />
    </g>
    <path
      d="M298.89 92.8605C298.89 110.74 284.39 125.24 266.51 125.24C248.63 125.24 234.13 110.74 234.13 92.8605C234.13 74.9805 248.63 60.4805 266.51 60.4805C284.39 60.4805 298.89 74.9805 298.89 92.8605Z"
      fill="#AD8EBC"
    />
    <path
      d="M266.51 127.74C247.28 127.74 231.63 112.09 231.63 92.8605C231.63 73.6305 247.28 57.9805 266.51 57.9805C285.74 57.9805 301.39 73.6305 301.39 92.8605C301.39 112.09 285.74 127.74 266.51 127.74ZM266.51 62.9805C250.04 62.9805 236.63 76.3805 236.63 92.8605C236.63 109.34 250.03 122.74 266.51 122.74C282.99 122.74 296.39 109.34 296.39 92.8605C296.39 76.3805 282.99 62.9805 266.51 62.9805Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M266.51 60.4922C265.3 60.4922 264.12 60.5622 262.94 60.6922C279.14 62.4722 291.75 76.1922 291.75 92.8722C291.75 109.552 279.14 123.272 262.94 125.052C264.11 125.182 265.3 125.252 266.51 125.252C284.39 125.252 298.89 110.752 298.89 92.8722C298.89 74.9922 284.39 60.4922 266.51 60.4922Z"
        fill="black"
      />
    </g>
    <path
      d="M169.52 427.63C169.52 445.51 155.02 460.01 137.14 460.01C119.26 460.01 104.76 445.51 104.76 427.63C104.76 409.75 119.26 395.25 137.14 395.25C155.02 395.25 169.52 409.75 169.52 427.63Z"
      fill="#AD8EBC"
    />
    <path
      d="M137.15 462.51C117.92 462.51 102.27 446.86 102.27 427.63C102.27 408.4 117.92 392.75 137.15 392.75C156.38 392.75 172.03 408.4 172.03 427.63C172.03 446.86 156.38 462.51 137.15 462.51ZM137.15 397.75C120.68 397.75 107.27 411.15 107.27 427.63C107.27 444.11 120.67 457.51 137.15 457.51C153.63 457.51 167.03 444.11 167.03 427.63C167.03 411.15 153.63 397.75 137.15 397.75Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M137.15 395.25C135.94 395.25 134.76 395.32 133.58 395.45C149.78 397.23 162.39 410.95 162.39 427.63C162.39 444.31 149.78 458.03 133.58 459.81C134.75 459.94 135.94 460.01 137.15 460.01C155.03 460.01 169.53 445.51 169.53 427.63C169.53 409.75 155.03 395.25 137.15 395.25Z"
        fill="black"
      />
    </g>
    <path d="M301.578 296.501L280.897 282.383L81.5381 574.413L102.219 588.531L301.578 296.501Z" fill="#FBFF54" />
    <path
      d="M102.07 591.183C101.57 591.183 101.08 591.033 100.66 590.743L79.98 576.623C78.84 575.843 78.5501 574.293 79.3301 573.153L278.71 281.133C279.49 279.993 281.05 279.703 282.18 280.473L302.86 294.593C303.41 294.963 303.78 295.543 303.91 296.193C304.03 296.843 303.89 297.523 303.52 298.063L104.14 590.083C103.77 590.633 103.19 591.003 102.54 591.133C102.39 591.163 102.23 591.173 102.08 591.173L102.07 591.183ZM84.8702 573.913L101.42 585.213L297.98 297.323L281.43 286.023L84.8702 573.913Z"
      fill="black"
    />
    <path d="M479.381 588.497L500.061 574.379L300.702 282.348L280.022 296.466L479.381 588.497Z" fill="#FBFF54" />
    <path
      d="M479.5 591.18C479.35 591.18 479.19 591.17 479.04 591.14C478.39 591.02 477.81 590.64 477.44 590.09L278.06 298.07C277.69 297.52 277.55 296.85 277.67 296.2C277.79 295.55 278.17 294.97 278.72 294.6L299.4 280.48C300.54 279.7 302.09 279.99 302.87 281.14L502.25 573.16C503.03 574.3 502.74 575.86 501.6 576.63L480.92 590.75C480.5 591.04 480.01 591.19 479.51 591.19L479.5 591.18ZM283.6 297.31L480.16 585.2L496.71 573.9L300.15 286.01L283.6 297.31Z"
      fill="black"
    />
    <path
      d="M291.09 306.55C300.832 306.55 308.73 298.652 308.73 288.91C308.73 279.167 300.832 271.27 291.09 271.27C281.348 271.27 273.45 279.167 273.45 288.91C273.45 298.652 281.348 306.55 291.09 306.55Z"
      fill="#AD8EBC"
    />
    <path
      d="M291.09 309.061C279.98 309.061 270.95 300.021 270.95 288.921C270.95 277.821 279.99 268.781 291.09 268.781C302.19 268.781 311.23 277.821 311.23 288.921C311.23 300.021 302.19 309.061 291.09 309.061ZM291.09 273.771C282.74 273.771 275.95 280.561 275.95 288.911C275.95 297.261 282.74 304.051 291.09 304.051C299.44 304.051 306.23 297.261 306.23 288.911C306.23 280.561 299.44 273.771 291.09 273.771Z"
      fill="black"
    />
    <path
      d="M102.88 574.84H33.6899C28.6255 574.84 24.52 578.945 24.52 584.01V600.12C24.52 605.184 28.6255 609.29 33.6899 609.29H102.88C107.944 609.29 112.05 605.184 112.05 600.12V584.01C112.05 578.945 107.944 574.84 102.88 574.84Z"
      fill="#E9D647"
    />
    <path
      d="M102.88 611.79H33.6899C27.2599 611.79 22.02 606.55 22.02 600.12V584.01C22.02 577.58 27.2599 572.34 33.6899 572.34H102.88C109.31 572.34 114.55 577.58 114.55 584.01V600.12C114.55 606.55 109.31 611.79 102.88 611.79ZM33.6899 577.34C30.0099 577.34 27.02 580.33 27.02 584.01V600.12C27.02 603.8 30.0099 606.79 33.6899 606.79H102.88C106.56 606.79 109.55 603.8 109.55 600.12V584.01C109.55 580.33 106.56 577.34 102.88 577.34H33.6899Z"
      fill="black"
    />
    <path
      d="M550.08 574.84H480.89C475.825 574.84 471.72 578.945 471.72 584.01V600.12C471.72 605.184 475.825 609.29 480.89 609.29H550.08C555.145 609.29 559.25 605.184 559.25 600.12V584.01C559.25 578.945 555.145 574.84 550.08 574.84Z"
      fill="#E9D647"
    />
    <path
      d="M550.08 611.79H480.89C474.46 611.79 469.22 606.55 469.22 600.12V584.01C469.22 577.58 474.46 572.34 480.89 572.34H550.08C556.51 572.34 561.75 577.58 561.75 584.01V600.12C561.75 606.55 556.52 611.79 550.08 611.79ZM480.89 577.34C477.21 577.34 474.22 580.33 474.22 584.01V600.12C474.22 603.8 477.21 606.79 480.89 606.79H550.08C553.76 606.79 556.75 603.8 556.75 600.12V584.01C556.75 580.33 553.76 577.34 550.08 577.34H480.89Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M279.61 304.91L97.21 573.71L81.3899 574.56L272.27 294.18L279.61 304.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M301.63 304.91L485.16 573.15L474.43 578.23L288.93 308.58L301.63 304.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M24.3601 592.059H111.61V602.509L102.88 609.289H31.99L23.8 602.229L24.3601 592.059Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M471.05 592.059H558.29V602.509L549.56 609.289H478.67L470.48 602.229L471.05 592.059Z" fill="#E9D647" />
    </g>
  </svg>
))`
  left: calc((621px - 579px) / 2);
`;

export default Frame4;
