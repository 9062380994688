import moment from 'moment';

import { IApp, IFuelLevel, ILuckLevel, INFT, ISpeedLevel, ITaskLevel, ITurboLevel } from '../types/app';
import { FarmingState } from 'src/contexts/AppContext';

export const getRoundTimeFormatted = (app: IApp) => {
  const dateMin = Math.min(moment().unix(), getRoundFinishAt(app));
  const roundTime = (getRoundFinishAt(app) - moment.unix(app.state.lastClaimAt).unix()) * 1000;

  const diff = moment.unix(dateMin).valueOf() - moment.unix(app.state.lastClaimAt).valueOf();
  const diffInverted = roundTime - diff;

  return moment(0).utc().add(diffInverted, 'milliseconds').format('HH:mm:ss');
};

export const isRoundFinished = (app: IApp): boolean => {
  const now = moment().unix();
  const finishAt = getRoundFinishAt(app);

  return now > finishAt;
};

export const getRoundPercentage = (app: IApp): number => {
  const now = moment().unix();
  const lastClaimAt = app.state.lastClaimAt;
  const finishAt = getRoundFinishAt(app);

  if (now > finishAt) return 100;

  const total = finishAt - lastClaimAt;
  const progress = now - lastClaimAt;

  return (progress / total) * 100;
};

export const getRoundFinishAt = (app: IApp): number => {
  const levelFuel = app.config.fuelLevels.find((fl) => fl.level === app.state.fuelLevel) as IFuelLevel;
  const date = moment.unix(app.state.lastClaimAt).add(levelFuel?.duration, 'seconds');

  return date.unix();
};

export const speedByNFTs = (app: IApp): number => app.nfts.reduce((acc: number, n: INFT) => {
  return acc + n.extra.speed
}, 0);

export const getFarmingSpeed = (app: IApp, farmingState: FarmingState): number => {
  const speedLevel = app.config.speedLevels.find((level) => level.level === app.state.speedLevel) as ISpeedLevel;
  const turboLevel = getTurboLevel(app);
  const baseSpeed = speedLevel.speed + speedByNFTs(app);
  return farmingState === 'turbo' ? baseSpeed * turboLevel.multiplier : baseSpeed;
};

// Levels
export const getTaskLevel = (app: IApp): ITaskLevel => {
  const fuelLevel = getFuelLevel(app);
  const speedLevel = getSpeedLevel(app);
  const basicRoundReward = fuelLevel.duration * speedLevel.speed;
  const maxTaskLevel = app.config.taskLevels[app.config.taskLevels.length - 1];
  if (basicRoundReward > maxTaskLevel.basicRoundReward) return maxTaskLevel;
  return app.config.taskLevels.find((level, index) => {
    const nextLevel = app.config.taskLevels[index + 1];
    const aboveCurrentLevel = basicRoundReward > level.basicRoundReward;
    const belowNextLevel = basicRoundReward <= nextLevel.basicRoundReward;
    return aboveCurrentLevel && belowNextLevel;
  });
};
export const getSpeedLevel = (app: IApp): ISpeedLevel => {
  return app.config.speedLevels.find((level) => level.level === app.state.speedLevel) as ISpeedLevel;
};
export const getSpeedLevelNext = (app: IApp): ISpeedLevel => {
  const level = getSpeedLevel(app);

  return app.config.speedLevels[level.level];
};
export const getTurboLevel = (app: IApp): ITurboLevel => {
  return app.config.turboLevels.find((level) => level.level === app.state.turboLevel) as ITurboLevel;
};
export const getTurboLevelNext = (app: IApp): ITurboLevel => {
  const level = getTurboLevel(app);

  return app.config.turboLevels[level.level];
};
export const getLuckLevel = (app: IApp): ILuckLevel => {
  return app.config.luckLevels.find((level) => level.level === app.state.luckLevel) as ILuckLevel;
};
export const getLuckLevelNext = (app: IApp): ILuckLevel => {
  const level = getLuckLevel(app);

  return app.config.luckLevels[level.level];
};
export const getFuelLevel = (app: IApp): IFuelLevel => {
  return app.config.fuelLevels.find((level) => level.level === app.state.fuelLevel) as IFuelLevel;
};
export const getFuelLevelNext = (app: IApp): IFuelLevel => {
  const level = getFuelLevel(app);

  return app.config.fuelLevels[level.level];
};

export const getTurboAvailable = (app: IApp): number => {
  const turboTotal = 3;
  const turboActivated =
    app.boostLogs === null ? 0 : app.boostLogs?.filter((l) => l.boost === 't' && l.action === 'a').length;
  const available = turboTotal - turboActivated;

  // console.log('>>>', turboActivated, available, app.boostLogs);

  return available;
};
