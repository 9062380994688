import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { postFlocks, putFlocks } from '../../../lib/api';
import { useApp } from '../../../contexts/AppContext';

export const TabButton = styled.div<{ active?: boolean }>`
  position: relative;
  padding: 0 15px 10px;

  color: ${({ active }) => (active ? '#faff00' : 'white')};
  border-bottom: 2px solid ${({ active }) => (active ? '#faff00' : 'transparent')};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  font-family: var(--font), sans-serif;
  font-size: 40px;
`;

export const LeaderboardTypeButton = styled.button<{ disabled?: boolean }>`
  width: 461.28px;
  height: 95px;
  position: relative;
  background: #32363ca3;

  border-radius: 105px;

  color: ${({ disabled }) => (disabled ? '#A7A7A770' : '#faff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? '#A7A7A7' : '#faff00')};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  text-transform: uppercase;
  font-family: var(--font), sans-serif;
  font-size: 40px;
`;

export const Button = styled.button<{ disabled?: boolean; width?: number }>`
  margin: 0 auto 40px;
  width: ${({ width }) => (width ? `${width}px` : '')};
  height: 95px;
  position: relative;
  background: #32363ca3;
  padding: 0 60px;

  border-radius: 105px;

  color: ${({ disabled }) => (disabled ? '#A7A7A770' : '#faff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? '#A7A7A7' : '#faff00')};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  text-transform: uppercase;
  font-family: var(--font), sans-serif;
  font-size: 40px;
`;

const Container = styled.div`
  margin: 0 auto 40px;
  width: 860px;
  height: 95px;
  position: relative;
  background: #32363ca3;

  border-radius: 105px;

  border: 1.5px solid #aba8a7;
  display: flex;
  justify-content: center;

  text-transform: uppercase;
  font-family: var(--font), sans-serif;
  font-size: 40px;

  input {
    color: white;
    width: 592px;
    background: transparent;
    border: none;
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    outline: none;
  }
`;

const filterChannel = (input: string): string => {
  const trimmed = input.trim();
  const hasAtPrefix = trimmed.startsWith('@');
  const withoutPrefix = trimmed.replace(/^@+/, '');
  const filtered = withoutPrefix.replace(/[^a-z0-9_]/gi, '').toLowerCase();
  const truncated = filtered.slice(0, 32);
  return hasAtPrefix ? '@' + truncated : truncated;
};

const isValidChannel = (ch: string): boolean => {
  const withoutAt = ch.startsWith('@') ? ch.slice(1) : ch;
  return withoutAt.length >= 5 && withoutAt.length <= 32;
};

export const CreateButton = ({ onClick }: { onClick: () => void }) => {
  const { app, updateApp } = useApp();
  const [channel, setChannel] = useState(app.flocks.flockOwn ? app.flocks.flockOwn.tgChatId : '');
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannel(filterChannel(e.target.value));
  };

  const handleCreate = async () => {
    const withoutPrefix = channel.replace(/^@+/, '');
    app.flocks.flockOwn ? await putFlocks(withoutPrefix) : await postFlocks(withoutPrefix);
    await updateApp();
  };

  const createDisabled = !isValidChannel(channel);

  return (
    <Container onClick={onClick}>
      <input value={channel} onChange={handleInputChange} placeholder="@ Telegram channel beginning with @" />
      <Button disabled={createDisabled} onClick={handleCreate}>
        {app.flocks.flockOwn ? t('flocks.update') : t('flocks.create')}
      </Button>
    </Container>
  );
};
