import styled from 'styled-components';

const PageContainer = styled.div.attrs({
  'data-tip': 'page-container'
})<{
  spread?: boolean;
  light?: boolean;
  dark?: boolean;
  vcenter?: boolean;
  hcenter?: boolean;
}>`
  color: white;
  position: relative;
  display: flex;
  align-items: ${({ hcenter }) => (hcenter === false ? 'normal' : 'center')};
  flex-direction: column;
  width: 1000px;
  min-height: calc(100vh / var(--scale) - 560px);
  padding: 280px 40px;
  justify-content: ${({ spread, vcenter }) => (spread ? 'space-between' : vcenter ? 'center' : 'normal')};
  background: ${({ dark, light }) =>
    dark
      ? '#000000cc'
      : light
      ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%)'
      : 'linear-gradient(to bottom, black 0%, #323232b0 25%, #323232b0 75%, black 100%)'};

  h1 {
    color: #fff;
    font-size: 92px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
    text-shadow: -1px 10px 0 rgba(0, 0, 0, 0.9);
    letter-spacing: -5px;
    line-height: 1.1;
  }
`;

export default PageContainer;
