import { useNavigate } from 'react-router-dom';

import { ButtonContainer, Group, IconArrow } from './Wrappers';
import Text from './Text';
import { useApp } from '../../contexts/AppContext';
import { getLuckLevel } from '../../lib/farming/util';
import { IconCup } from '../Icons/IconCup';
import { useTranslation } from 'react-i18next';

const Luck = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { app } = useApp();

  const level = getLuckLevel(app);
  const canUp = app.config.luckLevels.length > app.state.luckLevel;

  return (
    <ButtonContainer onClick={() => canUp && navigate('/boost/luck')}>
      <Group>
        <IconCup />
        <Text
          heading={t('boosts.luck.stressResistance')}
          level={t('system.levelShort') + ` ${app.state.luckLevel}/${app.config.luckLevels.length}`}
          reward={t('boosts.luck.reward_description', { probability: level.probability, multiplier: level.multiplier })}
        />
      </Group>
      {canUp && <IconArrow />}
    </ButtonContainer>
  );
};

export default Luck;
