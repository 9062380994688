import styled from 'styled-components';
import { useApp } from '../contexts/AppContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Row from '../components/Row';
import { IconCoin } from '../components/Icons/IconCoin';
import WebApp from '@twa-dev/sdk';
import { postFlocksMembership } from '../lib/api';
import Loading from '../components/Loading';
import PageContainer from '../components/Wrappers';
import { IFlock } from '../lib/types/flock';
import { notificationEmit } from '../controllers/NotificationsController';
import getFlockIcon from '../components/pages/Flocks/FlockIcons';
import { amountWithSpaces } from '../lib/utils';

const Header = styled.div`
  font-family: var(--font), sans-serif;
  color: #efff00;
  font-size: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Bonus = styled.div`
  color: white;
  font-size: 35px;
  font-weight: 500;
  margin: 70px 0 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  scale: 1.5;
`;

const Button = styled.button<{ disabled?: boolean }>`
  min-width: 12ch;
  height: 100px;
  margin: 0 auto 0;

  background: ${({ disabled }) => (disabled ? 'gray' : '#00000094')};
  color: ${({ disabled }) => (disabled ? 'darkkhaki' : '#efff00')};
  border: 1.5px solid ${({ disabled }) => (disabled ? 'gray' : '#efff00')};

  font-family: var(--font), sans-serif;
  text-transform: uppercase;
  border-radius: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 42px;
  font-weight: 500;
  padding: 0 100px;
`;

const Flock = () => {
  const { app, updateApp, setEvent } = useApp();
  const navigate = useNavigate();
  const { referer, id } = useParams();
  const [confirming, setConfirming] = useState(false);
  const [joinClicked, setJoinClicked] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(3);
  const flocks = [...app.flocks.flocksTop, ...app.flocks.flocksRecent];
  const flock = flocks.find((flock) => flock.id.toString() === id);
  const [checkDisabled, setCheckDisabled] = useState(!!flock?.link);
  if (!flock) throw new Error('Should not happen');
  const Icon = getFlockIcon(flock);
  const { t } = useTranslation();

  const handleJoin = async (flock: IFlock) => {
    setJoinClicked(true);
    const isTelegram = flock.link.startsWith('https://t.me');

    if (!isTelegram) WebApp.openLink(flock.link);
    else WebApp.openTelegramLink(flock.link);
  };

  const handleCheck = async (flock: IFlock) => {
    if ('new' !== referer && 'all-time' !== referer) throw new Error('Should not happen');
    await postFlocksMembership(flock.id, referer);
    const start = await updateApp();
    const flocks = [...start.flocks.flocksTop, ...start.flocks.flocksRecent];
    const updatedIsMember = flocks.find((item) => flock.id === item.id)?.joinedAt;
    if (!updatedIsMember)
      notificationEmit({
        title: t('popups.checkTask.taskIsNotCompleted'),
      });
    return !updatedIsMember;
  };

  const handleConfirm = async () => {
    let abortClose: void | boolean = false;
    setConfirming(true);
    abortClose = await handleCheck(flock);
    setConfirming(false);
    if (!abortClose) handleClose();
  };

  const handleClose = () => {
    navigate(`/flocks`);
    setEvent({ type: 'flockJoined', flock });
  };

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (joinClicked && secondsLeft > 0) {
      timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setCheckDisabled(false);
    }

    return () => clearInterval(timer);
  }, [joinClicked, secondsLeft]);

  return (
    <PageContainer vcenter dark>
      <Header>{flock.title}</Header>
      <Row margin="20px 0 40px">
        <Icon large={true} />
      </Row>
      {!!flock?.link && <Button onClick={() => handleJoin(flock)}>{t('popups.checkTask.join')}</Button>}
      {!flock.joinedAt && (
        <Bonus>
          <IconCoin />+{amountWithSpaces(flock.reward)}
        </Bonus>
      )}
      {!flock.joinedAt && (
        <Button disabled={checkDisabled} onClick={handleConfirm}>
          {confirming ? <Loading /> : t('popups.checkTask.check') || t('system.close')}
        </Button>
      )}
    </PageContainer>
  );
};

export default Flock;
