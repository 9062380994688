import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RunningText from './RunningText';


const Container = styled.div.attrs({
  'data-tip': 'news-line-container'
})`
  position: fixed;
  top: 0;
  width: 100%;

  // patch running text, size suitable for page container
  div {
    margin-top: 5px !important;
    // color: #faff00 !important;
    color: #b48ec0 !important;
    font-size: 30px !important;
  }
`;

export const NewsLine = () => {
  const { t } = useTranslation();
  const text = t('runningNewsLine');
  
  return text && (
    <Container>
      <RunningText text={text} />
    </Container>
  );
};
