const IconLotteries = () => (
  <svg width={54} height={47} viewBox="0 0 54 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.7599 11.7592L36.1989 28.0346C37.5362 30.6138 36.5512 33.8059 34.0007 35.1583L17.9065 43.6922C15.3559 45.0446 12.1994 44.0486 10.862 41.4694L2.4231 25.1939C1.08576 22.6147 2.07073 19.4227 4.62124 18.0703L20.7149 9.53667C23.2654 8.18427 26.4226 9.18 27.7602 11.7598L27.7599 11.7592Z"
      fill="#252528"
    />
    <path
      d="M21.5196 11.0836L5.42473 19.6178C3.72062 20.5214 3.06353 22.6509 3.95707 24.3742L12.3963 40.6502C13.2899 42.3735 15.3957 43.038 17.0998 42.1344L33.1947 33.6002C34.8988 32.6966 35.5559 30.5671 34.6623 28.8438L26.2231 12.5677C25.3295 10.8445 23.2237 10.18 21.5196 11.0836Z"
      fill="#B38DBF"
    />
    <path
      d="M15.4856 42.5828C14.2156 42.5828 12.9873 41.8877 12.3582 40.6743L3.9204 24.3979C3.01802 22.657 3.68457 20.4988 5.40542 19.5862L21.5006 11.0535C22.3343 10.6116 23.2887 10.5247 24.187 10.808C25.0859 11.0914 25.8211 11.7121 26.2581 12.5552L34.6958 28.8316C35.1328 29.6747 35.2195 30.6398 34.9386 31.5482C34.6584 32.4572 34.0445 33.2007 33.2108 33.6426L17.1156 42.1753C16.5947 42.4517 16.0357 42.5821 15.4849 42.5821L15.4856 42.5828ZM23.1334 10.726C22.584 10.726 22.0389 10.8606 21.5381 11.1258L5.44287 19.6585C3.76087 20.55 3.10957 22.6591 3.99184 24.3601L12.4296 40.6364C13.3112 42.3373 15.3975 42.996 17.0795 42.1038L33.1747 33.571C33.9897 33.139 34.589 32.4123 34.863 31.5243C35.137 30.6363 35.053 29.6936 34.6258 28.8695L26.188 12.5931C25.7608 11.769 25.0422 11.1629 24.1641 10.8852C23.8256 10.7786 23.4788 10.7253 23.1341 10.7253L23.1334 10.726Z"
      fill="#303030"
    />
    <path
      d="M25.2831 27.7932C26.3645 27.2707 27.6587 27.7329 28.1748 28.8257C28.6908 29.9185 28.2323 31.2281 27.151 31.7506C26.0697 32.2732 24.7754 31.8109 24.2594 30.7181C23.7433 29.6253 24.2018 28.3158 25.2831 27.7932Z"
      fill="#252528"
    />
    <path
      d="M16.8425 32.262C17.9238 31.7394 19.2181 32.2017 19.7342 33.2945C20.2502 34.3873 19.7917 35.6968 18.7104 36.2194C17.6291 36.7419 16.3348 36.2797 15.8187 35.1869C15.3027 34.0941 15.7612 32.7845 16.8425 32.262Z"
      fill="#252528"
    />
    <path
      d="M19.7301 17.0198C20.8114 16.4972 22.1057 16.9595 22.6217 18.0523C23.1378 19.1451 22.6793 20.4546 21.598 20.9772C20.5166 21.4997 19.2224 21.0375 18.7063 19.9447C18.1903 18.8519 18.6487 17.5423 19.7301 17.0198Z"
      fill="#252528"
    />
    <path
      d="M11.3207 21.5315C12.4021 21.009 13.6963 21.4712 14.2124 22.564C14.7284 23.6568 14.2699 24.9663 13.1886 25.4889C12.1073 26.0114 10.813 25.5492 10.297 24.4564C9.78091 23.3636 10.2394 22.0541 11.3207 21.5315Z"
      fill="#252528"
    />
    <path
      d="M14.0986 26.8948C15.18 26.3722 16.4742 26.8345 16.9903 27.9273C17.5063 29.0201 17.0478 30.3296 15.9665 30.8522C14.8852 31.3747 13.5909 30.9125 13.0748 29.8197C12.5588 28.7269 13.0173 27.4173 14.0986 26.8948Z"
      fill="#252528"
    />
    <path
      d="M22.5419 22.4143C23.6232 21.8918 24.9175 22.354 25.4335 23.4468C25.9496 24.5396 25.4911 25.8491 24.4097 26.3717C23.3284 26.8943 22.0341 26.432 21.5181 25.3392C21.002 24.2464 21.4605 22.9369 22.5419 22.4143Z"
      fill="#252528"
    />
    <path
      d="M29.4877 24.9896C29.2359 24.9896 28.9917 24.8514 28.8669 24.6101L23.2785 13.8301C23.0988 13.4836 23.2313 13.0557 23.5733 12.8741C23.9159 12.6924 24.339 12.8264 24.5187 13.1722L30.1071 23.9522C30.2867 24.2987 30.1542 24.7266 29.8123 24.9082C29.7082 24.9629 29.5973 24.9896 29.4877 24.9896Z"
      fill="#DFD8EC"
    />
    <path
      d="M5.42436 19.6218L15.834 39.7019L34.9783 29.5531C35.0415 29.8849 35.1871 30.8739 34.6794 31.9534C34.2209 32.9276 33.4885 33.427 33.193 33.6066C27.8279 36.4508 22.4629 39.2951 17.0978 42.1393C16.8647 42.2656 15.8555 42.779 14.6001 42.4507C13.1408 42.0692 12.4936 40.8494 12.3944 40.6544C9.58185 35.229 6.76927 29.8035 3.95668 24.3781C3.85403 24.1824 3.24365 22.9711 3.74513 21.5514C4.18835 20.298 5.19408 19.7417 5.42436 19.6211V19.6218Z"
      fill="#A479B1"
    />
    <path
      d="M51.6945 13.2042L46.5819 30.839C45.7717 33.6337 42.8702 35.2374 40.1066 34.4181L22.6681 29.248C19.9045 28.4286 18.3186 25.4945 19.1288 22.6998L24.2415 5.06501C25.0517 2.27037 27.9532 0.666611 30.7167 1.48594L48.1553 6.65608C50.9188 7.47541 52.5047 10.4096 51.6945 13.2042Z"
      fill="#252528"
    />
    <path
      d="M47.6681 8.34124L30.2289 3.1709C28.3825 2.62347 26.4468 3.69337 25.9054 5.56059L20.7926 23.1961C20.2513 25.0633 21.3093 27.0207 23.1557 27.5682L40.595 32.7385C42.4414 33.2859 44.3771 32.216 44.9184 30.3488L50.0312 12.7133C50.5725 10.8461 49.5146 8.88867 47.6681 8.34124Z"
      fill="#C4B8DA"
    />
    <path
      d="M39.1331 22.1917C40.27 22.5754 40.8838 23.8176 40.5037 24.9658C40.1243 26.114 38.8945 26.7341 37.7584 26.3504C36.6216 25.9667 36.0077 24.7252 36.3878 23.5763C36.7672 22.4281 37.997 21.808 39.1331 22.1917Z"
      fill="#252528"
    />
    <path
      d="M29.994 19.4729C31.1309 19.8566 31.7447 21.0988 31.3646 22.247C30.9852 23.3953 29.7561 24.0153 28.6193 23.6316C27.4825 23.248 26.8686 22.0057 27.2487 20.8575C27.6281 19.7093 28.8572 19.0893 29.994 19.4729Z"
      fill="#252528"
    />
    <path
      d="M41.9568 12.555C43.0936 12.9386 43.7075 14.1801 43.3274 15.3291C42.948 16.4773 41.7182 17.0973 40.5821 16.7137C39.4452 16.33 38.8314 15.0878 39.2115 13.9396C39.5909 12.7913 40.8207 12.1713 41.9568 12.555Z"
      fill="#252528"
    />
    <path
      d="M32.822 9.84013C33.9588 10.2238 34.5726 11.4653 34.1925 12.6142C33.8131 13.7624 32.5834 14.3825 31.4472 13.9988C30.3104 13.6151 29.6966 12.3729 30.0767 11.2247C30.4561 10.0765 31.6858 9.45645 32.822 9.84013Z"
      fill="#252528"
    />
    <path
      d="M44.4315 24.1633C44.3663 24.1633 44.2997 24.1542 44.2345 24.1345C43.8634 24.0244 43.6505 23.6309 43.7594 23.2556L47.1449 11.5757C47.2538 11.2004 47.6429 10.9851 48.014 11.0952C48.3851 11.2053 48.598 11.5988 48.4891 11.9741L45.1036 23.654C45.0141 23.9627 44.7346 24.1633 44.4322 24.1633H44.4315Z"
      fill="#DFD8EC"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.32}
    >
      <path
        d="M30.1979 3.16097L23.8916 24.9182L44.634 31.0668C44.4516 31.3501 43.8766 32.1638 42.7696 32.5832C41.7708 32.962 40.9003 32.8063 40.5646 32.7284C34.7515 31.005 28.939 29.2824 23.1259 27.559C22.8713 27.486 21.7928 27.1466 21.1151 26.0285C20.3279 24.7288 20.6997 23.3968 20.7621 23.1871C22.4663 17.3085 24.1698 11.4306 25.874 5.55209C25.9343 5.33956 26.3276 4.03914 27.6635 3.37069C28.8426 2.7808 29.9489 3.08662 30.1972 3.16237L30.1979 3.16097Z"
        fill="#C4B8DA"
      />
    </g>
  </svg>
);

export default IconLotteries;
