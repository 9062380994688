import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPagesData } from './diary';

export const useDiaryPages = () => {
  const { t, i18n } = useTranslation();
  const revealedPagesCount = 8; // 17 total
  const pages = useMemo(
    () => [...Array.from({ length: revealedPagesCount }, (_, i) => `/img/diary/page${i + 1}.webp`), null],
    []
  );
  const pagesData = useMemo(() => createPagesData(t), [t]);
  return { pages, pagesData: [...pagesData.slice(0, revealedPagesCount + 1), pagesData[18]], lang: i18n.language };
};

export const useDiaryNavigation = (totalPages: number) => {
  const [currentPage, setCurrentPage] = useState(0);
  const handlePrev = () => setCurrentPage((prev) => Math.max(0, prev - 1));
  const handleNext = () => setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  return { currentPage, handlePrev, handleNext };
};
