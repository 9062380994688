import PageContainer from '../components/Wrappers';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useApp } from '../contexts/AppContext';
import WebApp from '@twa-dev/sdk';
import { useEffectOnce } from 'react-use';
import { InvoiceStatuses } from '@twa-dev/types';
import { postShopLink } from '../lib/api';
import { IProduct } from '../lib/types';
import Row from '../components/Row';
import Loading from '../components/Loading';
import { doHapticFeedback } from '../lib/utils';

const IntroText = styled.div`
  //width: 776px;
  width: 880px;

  font-family: Roboto, sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;

  margin-bottom: 40px;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Label = styled.div`
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #faff00;
  text-transform: uppercase;
`;

const Description = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 35px;
  font-weight: 500;
`;

const BuyButton = styled.button`
  width: 427px;
  height: 95px;
  border-radius: 50px;
  background: #faff00;

  text-transform: uppercase;
  font-family: var(--font), sans-serif;
  font-size: 35px;
`;

const BuyCard = styled.div`
  width: 850px;
  padding: 40px 45px;
  //height: 411px;
  top: 296px;
  left: 70px;
  gap: 64px;
  border-radius: 50px;

  background: #32363ca3;
  margin-bottom: 10px;

  border: 1.5px solid #ebebeb9c;

  display: flex;
  flex-direction: row;
  img {
    border: 1.5px solid #a4a5a2;
    border-radius: 30px;
    background: #d3baff;
  }
`;

const IconStar = styled((props) => (
  <svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.2646 0.776649L23.7355 9.73653H23.7339C23.9403 10.1482 24.337 10.4328 24.7955 10.4987L34.7928 11.9347V11.9363C35.3245 12.0119 35.7651 12.3801 35.9309 12.885C36.0968 13.3899 35.9586 13.9447 35.5749 14.3145L28.3416 21.2882C28.0083 21.6082 27.8571 22.0697 27.9351 22.5231L29.6423 32.3705C29.7333 32.8931 29.5154 33.4222 29.0813 33.7341C28.6472 34.0461 28.0717 34.0863 27.597 33.8402L18.6568 29.1899C18.2455 28.9776 17.7545 28.9776 17.3432 29.1899L8.40303 33.8402C7.9283 34.0863 7.35277 34.0461 6.91867 33.7341C6.48294 33.4222 6.26672 32.8931 6.35774 32.3689L8.06486 22.5215V22.5231C8.1429 22.0697 7.9917 21.6082 7.65841 21.2882L0.42513 14.3145C0.0414359 13.9447 -0.0967535 13.3899 0.0690763 12.885C0.23491 12.3801 0.675491 12.0119 1.20716 11.9363L11.2045 10.5003V10.4987C11.663 10.4328 12.0597 10.1482 12.2661 9.73653L16.7354 0.776652C16.9727 0.300707 17.4637 0 18.0003 0C18.5368 0 19.0278 0.300695 19.2652 0.776652L19.2646 0.776649Z"
      fill="#363636"
    />
  </svg>
))`
  position: relative;
  top: 1px;
  margin-left: 10px;
`;

const StatsContainer = styled.div`
  position: relative;
  text-align: center;

  width: 590px;
  padding: 20px 80px 20px 80px;
  background: #232323;
  border-radius: 45px;
  border: 2px solid #a7a7a3;
  margin-bottom: 40px;
`;

const StatsLabel = styled.div`
  color: white;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 44.56px;
  text-align: left;
  text-transform: uppercase;
`;

const Value = styled.div`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 44.56px;
  text-align: left;
`;

const FriendsSpirit = () => {
  const { updateApp, setEvent, app } = useApp();
  const { t } = useTranslation();
  const [count, setCount] = useState(1);
  const [processing, setProcessing] = useState(false);

  const handleBuy = async (product: IProduct) => {
    if (processing) return;
    doHapticFeedback();
    setProcessing(true);
    setCount(product.virtualFriendsCount);
    const invoiceUrl = await postShopLink(product.id);
    WebApp.openInvoice(invoiceUrl.link);
  };

  useEffectOnce(() => {
    const handleInvoiceClosed = ({ status }: { status: InvoiceStatuses }) => {
      if (status === 'paid') {
        setEvent({ type: 'purchased', count });
        setTimeout(updateApp, 5000);
      }
      setProcessing(false);
    };

    WebApp.onEvent('invoiceClosed', handleInvoiceClosed);
    return () => {
      WebApp.offEvent('invoiceClosed', handleInvoiceClosed);
    };
  });

  const products = app.config.shop.products.filter((product) => product.type === 'virtual-friends');

  return (
    <PageContainer vcenter>
      {!app.state.friendsVirtualCount && <IntroText>{t('friends.spirit.intro')}</IntroText>}
      {!!app.state.friendsVirtualCount && (
        <StatsContainer>
          <Row spread={true}>
            <StatsLabel>{t('friends.spirit.friendsCount')}</StatsLabel>
            <Value>{app.state.friendsVirtualCount}</Value>
          </Row>
        </StatsContainer>
      )}

      {products.map((product) => (
        <BuyCard key={product.id}>
          <img alt="spirit" src={`/img/friends/spirit_x${product.virtualFriendsCount}.png`} />
          <RightSide>
            <Label>
              {product.virtualFriendsCount} {t('friends.spirit.friend', { count: product.virtualFriendsCount })}
            </Label>
            <Description>{t('friends.spirit.willHelpImprove')}</Description>
            <BuyButton onClick={() => handleBuy(product)}>
              {!processing && (
                <>
                  {t('friends.spirit.buyFor')} {product.totalAmount} <IconStar />
                </>
              )}
              {processing && <Loading />}
            </BuyButton>
          </RightSide>
        </BuyCard>
      ))}
    </PageContainer>
  );
};

export default FriendsSpirit;
