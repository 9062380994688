import { useTranslation } from 'react-i18next';

import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { IconWorld } from '../../Icons/IconWorld';

const World = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer width={100}>
      <IconContainer active={active}>
        <IconWorld />
      </IconContainer>
      <ButtonLabel active={active}>{t('menu.world')}</ButtonLabel>
    </ButtonContainer>
  );
};

export default World;
