import styled from 'styled-components';

const Frame2 = styled((props) => (
  <svg width={579} height={612} viewBox="0 0 579 612" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.12}
      d="M473.46 127.309L529.15 333.349C532.35 345.179 528.99 357.819 520.34 366.499L369.75 517.749C361.1 526.429 348.48 529.849 336.64 526.699L130.36 471.909C118.52 468.759 109.25 459.539 106.05 447.709L50.3599 241.669C47.1599 229.839 50.5199 217.199 59.1699 208.519L209.76 57.2694C218.41 48.5894 231.03 45.1694 242.87 48.3194L449.15 103.109C460.99 106.259 470.26 115.479 473.46 127.309Z"
      fill="#AD8EBC"
    />
    <path
      d="M482.15 70.9614L230.62 4.15143C218.78 1.00143 206.15 4.42143 197.51 13.1014L13.8898 197.521C5.23984 206.201 1.88978 218.841 5.07978 230.671L72.9898 481.901C76.1898 493.731 85.4499 502.961 97.2999 506.101L348.83 572.911C360.67 576.061 373.3 572.641 381.94 563.961L565.56 379.541C574.21 370.861 577.56 358.221 574.37 346.391L506.47 95.1614C503.27 83.3314 494.01 74.1014 482.16 70.9614H482.15ZM473.42 128.341L529.11 334.381C532.31 346.211 528.95 358.851 520.3 367.531L369.71 518.781C361.06 527.461 348.44 530.881 336.6 527.731L130.32 472.941C118.48 469.791 109.21 460.571 106.01 448.741L50.3198 242.701C47.1198 230.871 50.4798 218.231 59.1298 209.551L209.72 58.3014C218.37 49.6214 230.99 46.2014 242.83 49.3514L449.11 104.141C460.95 107.291 470.22 116.511 473.42 128.341Z"
      fill="#986EAB"
    />
    <path
      d="M357.58 577.06C354.4 577.06 351.2 576.65 348.05 575.82L96.5199 509.01C83.6799 505.6 73.5498 495.51 70.0898 482.69L2.17991 231.46C-1.29009 218.64 2.37987 204.82 11.7599 195.41L195.38 10.9799C204.75 1.5699 218.55 -2.1601 231.39 1.2499L482.92 68.0599C495.76 71.4699 505.89 81.5499 509.35 94.3799L577.25 345.61C580.72 358.43 577.05 372.25 567.67 381.66L384.05 566.08C376.97 573.19 367.37 577.06 357.57 577.06H357.58ZM221.86 5.9999C213.63 5.9999 205.58 9.2499 199.64 15.2099L16.0199 199.64C8.14988 207.54 5.06984 219.13 7.97984 229.89L75.8899 481.12C78.7999 491.88 87.2998 500.34 98.0698 503.21L349.6 570.02C360.37 572.88 371.95 569.75 379.82 561.85L563.44 377.43C571.31 369.53 574.38 357.94 571.48 347.18L503.58 95.9499C500.67 85.1899 492.17 76.7299 481.4 73.8599L229.87 7.0499C227.23 6.3499 224.54 6.0099 221.87 6.0099L221.86 5.9999ZM345.36 531.86C342.18 531.86 338.98 531.45 335.83 530.62L129.55 475.83C116.71 472.42 106.58 462.34 103.12 449.51L47.4299 243.47C43.9599 230.64 47.6299 216.83 57.0099 207.42L207.6 56.1699C216.97 46.7599 230.77 43.0299 243.61 46.4399L449.89 101.23C462.73 104.64 472.86 114.73 476.32 127.55L532.01 333.59C535.48 346.41 531.81 360.23 522.43 369.64L371.84 520.89C364.76 528 355.16 531.86 345.36 531.87V531.86ZM234.07 51.1999C225.84 51.1999 217.79 54.4499 211.85 60.4099L61.2599 211.66C53.3899 219.56 50.3198 231.15 53.2198 241.91L108.91 447.95C111.82 458.71 120.32 467.17 131.09 470.04L337.37 524.83C348.14 527.69 359.72 524.56 367.59 516.66L518.18 365.41C526.05 357.51 529.12 345.92 526.22 335.16L470.53 129.12C467.62 118.36 459.12 109.9 448.35 107.03L242.07 52.2399C239.43 51.5399 236.74 51.1999 234.07 51.1999Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.35}
    >
      <path
        d="M6.20984 224.648C32.9298 303.068 59.6399 381.488 86.3599 459.898C90.1799 471.118 99.2599 479.748 110.65 482.988C188.56 505.198 266.46 527.408 344.37 549.618C355.85 552.888 368.21 550.118 377.2 542.258C442.54 485.098 507.88 427.948 573.22 370.788L381.93 563.968C378.67 566.208 373.64 569.118 366.98 571.038C359.48 573.208 352.99 573.228 348.81 572.918C264.97 550.648 181.12 528.378 97.2799 506.108C94.2899 505.288 87.7899 503.088 81.8699 497.168C76.0099 491.308 73.7999 484.898 72.9698 481.908C50.7098 396.158 28.4598 310.398 6.19983 224.648H6.20984Z"
        fill="#986EAB"
      />
    </g>
    <path
      d="M244.917 20.1849C241.002 19.1357 236.977 21.4593 235.928 25.375C234.879 29.2906 237.202 33.3154 241.118 34.3646L396.033 75.874C399.949 76.9232 403.974 74.5995 405.023 70.6838C406.072 66.7682 403.748 62.7434 399.833 61.6942L244.917 20.1849Z"
      fill="white"
    />
    <path
      d="M423.279 67.5715C419.364 66.5223 415.339 68.8459 414.29 72.7616C413.241 76.6772 415.564 80.7021 419.48 81.7513L473.069 96.1106C476.985 97.1598 481.01 94.836 482.059 90.9203C483.108 87.0047 480.785 82.9799 476.869 81.9307L423.279 67.5715Z"
      fill="white"
    />
    <path
      d="M498.45 369.29C498.45 387.17 483.95 401.67 466.07 401.67C448.19 401.67 433.69 387.17 433.69 369.29C433.69 351.41 448.19 336.91 466.07 336.91C483.95 336.91 498.45 351.41 498.45 369.29Z"
      fill="#FBFF54"
    />
    <path
      d="M466.08 404.158C446.85 404.158 431.2 388.508 431.2 369.278C431.2 350.048 446.85 334.398 466.08 334.398C485.31 334.398 500.96 350.048 500.96 369.278C500.96 388.508 485.31 404.158 466.08 404.158ZM466.08 339.408C449.6 339.408 436.2 352.808 436.2 369.288C436.2 385.768 449.6 399.168 466.08 399.168C482.56 399.168 495.96 385.768 495.96 369.288C495.96 352.808 482.56 339.408 466.08 339.408Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M466.08 336.91C464.87 336.91 463.69 336.98 462.51 337.11C478.71 338.89 491.32 352.61 491.32 369.29C491.32 385.97 478.71 399.69 462.51 401.47C463.68 401.6 464.87 401.67 466.08 401.67C483.96 401.67 498.46 387.17 498.46 369.29C498.46 351.41 483.96 336.91 466.08 336.91Z"
        fill="black"
      />
    </g>
    <path
      d="M423.41 265.579C423.41 283.459 408.91 297.959 391.03 297.959C373.15 297.959 358.65 283.459 358.65 265.579C358.65 247.699 373.15 233.199 391.03 233.199C408.91 233.199 423.41 247.699 423.41 265.579Z"
      fill="#FBFF54"
    />
    <path
      d="M391.03 300.459C371.8 300.459 356.15 284.809 356.15 265.579C356.15 246.349 371.8 230.699 391.03 230.699C410.26 230.699 425.91 246.349 425.91 265.579C425.91 284.809 410.26 300.459 391.03 300.459ZM391.03 235.699C374.55 235.699 361.15 249.099 361.15 265.579C361.15 282.059 374.55 295.459 391.03 295.459C407.51 295.459 420.91 282.059 420.91 265.579C420.91 249.099 407.51 235.699 391.03 235.699Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M391.03 233.211C389.82 233.211 388.64 233.281 387.46 233.411C403.66 235.191 416.27 248.911 416.27 265.591C416.27 282.271 403.66 295.991 387.46 297.771C388.63 297.901 389.82 297.971 391.03 297.971C408.91 297.971 423.41 283.471 423.41 265.591C423.41 247.711 408.91 233.211 391.03 233.211Z"
        fill="black"
      />
    </g>
    <path
      d="M194.14 344.642C194.14 362.522 179.64 377.022 161.76 377.022C143.88 377.022 129.38 362.522 129.38 344.642C129.38 326.762 143.88 312.262 161.76 312.262C179.64 312.262 194.14 326.762 194.14 344.642Z"
      fill="#FBFF54"
    />
    <path
      d="M161.76 379.522C142.53 379.522 126.88 363.872 126.88 344.642C126.88 325.412 142.53 309.762 161.76 309.762C180.99 309.762 196.64 325.412 196.64 344.642C196.64 363.872 180.99 379.522 161.76 379.522ZM161.76 314.762C145.29 314.762 131.88 328.162 131.88 344.642C131.88 361.122 145.28 374.522 161.76 374.522C178.24 374.522 191.64 361.122 191.64 344.642C191.64 328.162 178.24 314.762 161.76 314.762Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M161.76 312.273C160.55 312.273 159.37 312.343 158.19 312.473C174.39 314.253 187 327.973 187 344.653C187 361.333 174.39 375.053 158.19 376.833C159.36 376.963 160.55 377.033 161.76 377.033C179.64 377.033 194.14 362.533 194.14 344.653C194.14 326.773 179.64 312.273 161.76 312.273Z"
        fill="black"
      />
    </g>
    <path
      d="M297.37 95.3292C297.37 113.209 282.87 127.709 264.99 127.709C247.11 127.709 232.61 113.209 232.61 95.3292C232.61 77.4492 247.11 62.9492 264.99 62.9492C282.87 62.9492 297.37 77.4492 297.37 95.3292Z"
      fill="#FBFF54"
    />
    <path
      d="M264.99 130.209C245.76 130.209 230.11 114.559 230.11 95.3292C230.11 76.0992 245.76 60.4492 264.99 60.4492C284.22 60.4492 299.87 76.0992 299.87 95.3292C299.87 114.559 284.22 130.209 264.99 130.209ZM264.99 65.4592C248.52 65.4592 235.11 78.8592 235.11 95.3392C235.11 111.819 248.51 125.219 264.99 125.219C281.47 125.219 294.87 111.819 294.87 95.3392C294.87 78.8592 281.47 65.4592 264.99 65.4592Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M264.99 62.9492C263.78 62.9492 262.6 63.0192 261.42 63.1492C277.62 64.9292 290.23 78.6492 290.23 95.3292C290.23 112.009 277.62 125.729 261.42 127.509C262.59 127.639 263.78 127.709 264.99 127.709C282.87 127.709 297.37 113.209 297.37 95.3292C297.37 77.4492 282.87 62.9492 264.99 62.9492Z"
        fill="black"
      />
    </g>
    <path
      d="M513.72 301.521C513.72 319.401 499.22 333.901 481.34 333.901C463.46 333.901 448.96 319.401 448.96 301.521C448.96 283.641 463.46 269.141 481.34 269.141C499.22 269.141 513.72 283.641 513.72 301.521Z"
      fill="#E9D647"
    />
    <path
      d="M481.35 336.401C462.12 336.401 446.47 320.751 446.47 301.521C446.47 282.291 462.12 266.641 481.35 266.641C500.58 266.641 516.23 282.291 516.23 301.521C516.23 320.751 500.58 336.401 481.35 336.401ZM481.35 271.651C464.88 271.651 451.47 285.051 451.47 301.531C451.47 318.011 464.87 331.411 481.35 331.411C497.83 331.411 511.23 318.011 511.23 301.531C511.23 285.051 497.83 271.651 481.35 271.651Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M481.35 269.152C480.14 269.152 478.96 269.222 477.78 269.352C493.98 271.132 506.59 284.852 506.59 301.532C506.59 318.212 493.98 331.932 477.78 333.712C478.95 333.842 480.14 333.912 481.35 333.912C499.23 333.912 513.73 319.412 513.73 301.532C513.73 283.652 499.23 269.152 481.35 269.152Z"
        fill="black"
      />
    </g>
    <path
      d="M451.02 420.11C451.02 437.99 436.52 452.49 418.64 452.49C400.76 452.49 386.26 437.99 386.26 420.11C386.26 402.23 400.76 387.73 418.64 387.73C436.52 387.73 451.02 402.23 451.02 420.11Z"
      fill="#986EAB"
    />
    <path
      d="M418.64 454.99C399.41 454.99 383.76 439.34 383.76 420.11C383.76 400.88 399.41 385.23 418.64 385.23C437.87 385.23 453.52 400.88 453.52 420.11C453.52 439.34 437.87 454.99 418.64 454.99ZM418.64 390.24C402.17 390.24 388.76 403.64 388.76 420.12C388.76 436.6 402.16 450 418.64 450C435.12 450 448.52 436.6 448.52 420.12C448.52 403.64 435.12 390.24 418.64 390.24Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M418.64 387.73C417.43 387.73 416.25 387.8 415.07 387.93C431.27 389.71 443.88 403.43 443.88 420.11C443.88 436.79 431.27 450.51 415.07 452.29C416.24 452.42 417.43 452.49 418.64 452.49C436.52 452.49 451.02 437.99 451.02 420.11C451.02 402.23 436.52 387.73 418.64 387.73Z"
        fill="black"
      />
    </g>
    <path
      d="M188.26 213.431C188.26 231.311 173.76 245.811 155.88 245.811C138 245.811 123.5 231.311 123.5 213.431C123.5 195.551 138 181.051 155.88 181.051C173.76 181.051 188.26 195.551 188.26 213.431Z"
      fill="#986EAB"
    />
    <path
      d="M155.89 248.311C136.66 248.311 121.01 232.661 121.01 213.431C121.01 194.201 136.66 178.551 155.89 178.551C175.12 178.551 190.77 194.201 190.77 213.431C190.77 232.661 175.12 248.311 155.89 248.311ZM155.89 183.561C139.42 183.561 126.01 196.961 126.01 213.441C126.01 229.921 139.41 243.321 155.89 243.321C172.37 243.321 185.77 229.921 185.77 213.441C185.77 196.961 172.37 183.561 155.89 183.561Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M155.89 181.051C154.68 181.051 153.5 181.121 152.32 181.251C168.52 183.031 181.13 196.751 181.13 213.431C181.13 230.111 168.52 243.831 152.32 245.611C153.49 245.741 154.68 245.811 155.89 245.811C173.77 245.811 188.27 231.311 188.27 213.431C188.27 195.551 173.77 181.051 155.89 181.051Z"
        fill="black"
      />
    </g>
    <path
      d="M332.43 387.919C332.43 405.799 317.93 420.299 300.05 420.299C282.17 420.299 267.67 405.799 267.67 387.919C267.67 370.039 282.17 355.539 300.05 355.539C317.93 355.539 332.43 370.039 332.43 387.919Z"
      fill="#AD8EBC"
    />
    <path
      d="M300.05 422.799C280.82 422.799 265.17 407.149 265.17 387.919C265.17 368.689 280.82 353.039 300.05 353.039C319.28 353.039 334.93 368.689 334.93 387.919C334.93 407.149 319.28 422.799 300.05 422.799ZM300.05 358.039C283.57 358.039 270.17 371.439 270.17 387.919C270.17 404.399 283.57 417.799 300.05 417.799C316.53 417.799 329.93 404.399 329.93 387.919C329.93 371.439 316.53 358.039 300.05 358.039Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M300.05 355.539C298.84 355.539 297.66 355.609 296.48 355.739C312.68 357.519 325.29 371.239 325.29 387.919C325.29 404.599 312.68 418.319 296.48 420.099C297.65 420.229 298.84 420.299 300.05 420.299C317.93 420.299 332.43 405.799 332.43 387.919C332.43 370.039 317.93 355.539 300.05 355.539Z"
        fill="black"
      />
    </g>
    <path
      d="M332.43 213.989C332.43 231.869 317.93 246.369 300.05 246.369C282.17 246.369 267.67 231.869 267.67 213.989C267.67 196.109 282.17 181.609 300.05 181.609C317.93 181.609 332.43 196.109 332.43 213.989Z"
      fill="#AD8EBC"
    />
    <path
      d="M300.05 248.869C280.82 248.869 265.17 233.219 265.17 213.989C265.17 194.759 280.82 179.109 300.05 179.109C319.28 179.109 334.93 194.759 334.93 213.989C334.93 233.219 319.28 248.869 300.05 248.869ZM300.05 184.119C283.57 184.119 270.17 197.519 270.17 213.999C270.17 230.479 283.57 243.879 300.05 243.879C316.53 243.879 329.93 230.479 329.93 213.999C329.93 197.519 316.53 184.119 300.05 184.119Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M300.05 181.621C298.84 181.621 297.66 181.691 296.48 181.821C312.68 183.601 325.29 197.321 325.29 214.001C325.29 230.681 312.68 244.401 296.48 246.181C297.65 246.311 298.84 246.381 300.05 246.381C317.93 246.381 332.43 231.881 332.43 214.001C332.43 196.121 317.93 181.621 300.05 181.621Z"
        fill="black"
      />
    </g>
    <path
      d="M228.48 127.489C228.48 145.369 213.98 159.869 196.1 159.869C178.22 159.869 163.72 145.369 163.72 127.489C163.72 109.609 178.22 95.1094 196.1 95.1094C213.98 95.1094 228.48 109.609 228.48 127.489Z"
      fill="#AD8EBC"
    />
    <path
      d="M196.1 162.369C176.87 162.369 161.22 146.719 161.22 127.489C161.22 108.259 176.87 92.6094 196.1 92.6094C215.33 92.6094 230.98 108.259 230.98 127.489C230.98 146.719 215.33 162.369 196.1 162.369ZM196.1 97.6094C179.63 97.6094 166.22 111.009 166.22 127.489C166.22 143.969 179.62 157.369 196.1 157.369C212.58 157.369 225.98 143.969 225.98 127.489C225.98 111.009 212.58 97.6094 196.1 97.6094Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.1}
    >
      <path
        d="M196.1 95.1094C194.89 95.1094 193.71 95.1794 192.53 95.3094C208.73 97.0894 221.34 110.809 221.34 127.489C221.34 144.169 208.73 157.889 192.53 159.669C193.7 159.799 194.89 159.869 196.1 159.869C213.98 159.869 228.48 145.369 228.48 127.489C228.48 109.609 213.98 95.1094 196.1 95.1094Z"
        fill="black"
      />
    </g>
    <path d="M301.444 296.606L280.764 282.488L81.4046 574.519L102.085 588.637L301.444 296.606Z" fill="#FBFF54" />
    <path
      d="M102.01 591.183C101.51 591.183 101.02 591.033 100.6 590.743L79.9199 576.623C78.7799 575.843 78.4899 574.293 79.2699 573.153L278.65 281.133C279.43 279.993 280.99 279.703 282.12 280.473L302.8 294.593C303.35 294.963 303.72 295.543 303.85 296.193C303.97 296.843 303.83 297.523 303.46 298.063L104.08 590.083C103.71 590.633 103.13 591.003 102.48 591.133C102.33 591.163 102.17 591.173 102.02 591.173L102.01 591.183ZM84.8099 573.913L101.36 585.213L297.92 297.323L281.37 286.023L84.8099 573.913Z"
      fill="black"
    />
    <path d="M479.404 588.598L500.084 574.48L300.725 282.45L280.045 296.568L479.404 588.598Z" fill="#FBFF54" />
    <path
      d="M479.44 591.18C479.29 591.18 479.13 591.17 478.98 591.14C478.33 591.02 477.75 590.64 477.38 590.09L278 298.07C277.63 297.52 277.49 296.85 277.61 296.2C277.73 295.55 278.11 294.97 278.66 294.6L299.34 280.48C300.48 279.7 302.03 279.99 302.81 281.14L502.19 573.16C502.97 574.3 502.68 575.86 501.54 576.63L480.86 590.75C480.44 591.04 479.95 591.19 479.45 591.19L479.44 591.18ZM283.54 297.31L480.1 585.2L496.65 573.9L300.09 286.01L283.54 297.31Z"
      fill="black"
    />
    <path
      d="M291.04 306.55C300.782 306.55 308.68 298.652 308.68 288.91C308.68 279.167 300.782 271.27 291.04 271.27C281.298 271.27 273.4 279.167 273.4 288.91C273.4 298.652 281.298 306.55 291.04 306.55Z"
      fill="#AD8EBC"
    />
    <path
      d="M291.04 309.061C279.93 309.061 270.9 300.021 270.9 288.921C270.9 277.821 279.94 268.781 291.04 268.781C302.14 268.781 311.18 277.821 311.18 288.921C311.18 300.021 302.14 309.061 291.04 309.061ZM291.04 273.771C282.69 273.771 275.9 280.561 275.9 288.911C275.9 297.261 282.69 304.051 291.04 304.051C299.39 304.051 306.18 297.261 306.18 288.911C306.18 280.561 299.39 273.771 291.04 273.771Z"
      fill="black"
    />
    <path
      d="M102.82 574.84H33.6299C28.5654 574.84 24.4598 578.945 24.4598 584.01V600.12C24.4598 605.184 28.5654 609.29 33.6299 609.29H102.82C107.884 609.29 111.99 605.184 111.99 600.12V584.01C111.99 578.945 107.884 574.84 102.82 574.84Z"
      fill="#E9D647"
    />
    <path
      d="M102.82 611.79H33.6299C27.1999 611.79 21.9598 606.55 21.9598 600.12V584.01C21.9598 577.58 27.1999 572.34 33.6299 572.34H102.82C109.25 572.34 114.49 577.58 114.49 584.01V600.12C114.49 606.55 109.25 611.79 102.82 611.79ZM33.6299 577.34C29.9499 577.34 26.9598 580.33 26.9598 584.01V600.12C26.9598 603.8 29.9499 606.79 33.6299 606.79H102.82C106.5 606.79 109.49 603.8 109.49 600.12V584.01C109.49 580.33 106.5 577.34 102.82 577.34H33.6299Z"
      fill="black"
    />
    <path
      d="M550.02 574.84H480.83C475.765 574.84 471.66 578.945 471.66 584.01V600.12C471.66 605.184 475.765 609.29 480.83 609.29H550.02C555.084 609.29 559.19 605.184 559.19 600.12V584.01C559.19 578.945 555.084 574.84 550.02 574.84Z"
      fill="#E9D647"
    />
    <path
      d="M550.02 611.79H480.83C474.4 611.79 469.16 606.55 469.16 600.12V584.01C469.16 577.58 474.39 572.34 480.83 572.34H550.02C556.45 572.34 561.69 577.58 561.69 584.01V600.12C561.69 606.55 556.46 611.79 550.02 611.79ZM480.83 577.34C477.15 577.34 474.16 580.33 474.16 584.01V600.12C474.16 603.8 477.15 606.79 480.83 606.79H550.02C553.7 606.79 556.69 603.8 556.69 600.12V584.01C556.69 580.33 553.7 577.34 550.02 577.34H480.83Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M279.56 304.91L97.1599 573.71L81.3298 574.56L272.22 294.18L279.56 304.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M301.58 304.91L485.11 573.15L474.38 578.23L288.87 308.58L301.58 304.91Z" fill="#E9D647" />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path
        d="M24.3099 592.059H111.56V602.509L102.82 609.289H31.9299L23.7399 602.229L24.3099 592.059Z"
        fill="#E9D647"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.69}
    >
      <path d="M470.99 592.059H558.24V602.509L549.5 609.289H478.61L470.43 602.229L470.99 592.059Z" fill="#E9D647" />
    </g>
  </svg>
))`
  left: calc((621px - 579px) / 2);
`;

export default Frame2;
