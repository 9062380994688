import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Cave } from './Buttons/Cave';
import { MenuBookFireplace } from './Buttons/MenuBookFireplace';
import { MenuBookGameMachine } from './Buttons/MenuBookGameMachine';

const MenuContainer = styled.div.attrs({
  className: 'menu-nft-container',
})`
  pointer-events: all;
  display: flex;
  justify-content: space-between;
  border: 1px solid #888;
  background: #00000040;

  position: absolute;
  top: calc(60px * var(--scale));
  left: 50%;
  margin: 0 auto 0 -465px;

  border: 0px;
  border-radius: 20px;
  width: 930px;
  height: 120px;
  padding: 20px 0;
  transform-origin: center 0;
  transform: scale(var(--scale));

  a,
  > div {
    flex: 3;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  > div {
    border-left: 1px solid #888;
    border-right: 1px solid #888;
  }
`;

export const MenuWorld = () => {
  return (
    <MenuContainer>
      <NavLink to="/world/book/game-machine">{({ isActive }) => <MenuBookGameMachine active={isActive} />}</NavLink>
      <NavLink to="/world/cave">{({ isActive }) => <Cave active={isActive} />}</NavLink>
      <NavLink to="/world/book/fireplace">{({ isActive }) => <MenuBookFireplace active={isActive} />}</NavLink>
    </MenuContainer>
  );
};
