import styled from 'styled-components';

export const IconAttention = styled((props) => (
  <svg width={76} height={47} viewBox="0 0 76 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M35.402 4.65039C36.5567 2.65039 39.4434 2.65039 40.5981 4.65039L55.3468 30.1958C56.5015 32.1958 55.0581 34.6958 52.7487 34.6958H23.2514C20.942 34.6958 19.4986 32.1958 20.6533 30.1958L35.402 4.65039Z"
      fill="#C12525"
    />
    <path
      d="M40.5 9.25L39.9844 25.0469H36.2344L35.7031 9.25H40.5ZM35.5781 29.9375C35.5781 29.2708 35.8073 28.7188 36.2656 28.2812C36.7344 27.8333 37.349 27.6094 38.1094 27.6094C38.8802 27.6094 39.4948 27.8333 39.9531 28.2812C40.4115 28.7188 40.6406 29.2708 40.6406 29.9375C40.6406 30.5833 40.4115 31.1302 39.9531 31.5781C39.4948 32.026 38.8802 32.25 38.1094 32.25C37.349 32.25 36.7344 32.026 36.2656 31.5781C35.8073 31.1302 35.5781 30.5833 35.5781 29.9375Z"
      fill="#FFFBFB"
    />
  </svg>
))`
  flex-shrink: 0;
`;
